import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

interface BlogPost {
    P309_blogs_id: number;
    P309_title: string;
    P309_subtitle: string;
    P309_image: string;
}

export default function Blogpage() {
    const [blogs, setBlogs] = useState<BlogPost[]>([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/blogs?page=1&perPage=18&searchTerm=&sortBy=P309_post_date&sortOrder=asc`
                );
                if (response.data.success && response.data.data?.data) {
                    setBlogs(response.data.data.data);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <>
            <div>
                <section className="max-w-6xl mx-auto mb-7 m-5">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-6 ml-3">Recent Posts</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 m-5">
                        {blogs.map((blog, index) => (
                            <Link
                                to={`/singleblog/${blog.P309_blogs_id}`}
                                key={index}
                                className="bg-white rounded-lg shadow-md overflow-hidden"
                            >
                                <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${blog.P309_image}`}
                                    alt={blog.P309_title}
                                    className="w-full h-48 object-cover"
                                />
                                <div className="p-5">
                                    <h4 className="text-xl font-semibold text-gray-800">
                                        {blog.P309_title}
                                    </h4>
                                    <p className="text-gray-600 mt-2">{blog.P309_subtitle}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}
