import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AddProduct } from './add_product/add_product/page'
import { AddProductSKU } from './add_product_sku/page'
import WarehouseRoot from '../warehouse/page'
import { PurchaseList } from './purchase/page'
import { CategoryCRUD } from './product_category/page'
import { PricingPage } from './add_product_sku/pages/pricingpage/pricingPage'
import { StockCRUD } from './stock/page'
import { EcommercStockCRUD } from './ecommStock/page'
import { BrandCRUD } from './brand_crud/page'
import { ExcelUpload } from './stockUpload/stockUpload'
import { useCheckUserType } from '@/src/hooks/check_user'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'

type Props = {
  setSelectedId: (id: string) => any
}

export default function EcommerceRoot(props: Props) {

  const { isSEOs } = useCheckUserType()

  const employee = useSelector((state: RootState) => state.employee)

  return (
    <Routes>
      {isSEOs(employee?.employeeData?.M15_Employee_id!)
        ?
        <Route index element={<Navigate to='add_product_sku' />} />
        : <Route index element={<Navigate to='add_product' />} />
      }
      <Route path='Product_category' element={<CategoryCRUD setSelectedId={props.setSelectedId} />} />
      <Route path='add_product_sku/*'  >
        <Route index element={<AddProductSKU setSelectedId={props.setSelectedId} />} />
        {!isSEOs(employee?.employeeData?.M15_Employee_id!) && <Route path='pricing/:id' element={<PricingPage setSelectedId={props.setSelectedId} />} />}
      </Route>
      {
        !isSEOs(employee?.employeeData?.M15_Employee_id!) &&
        <>
          <Route path='brand' element={<BrandCRUD setSelectedId={props.setSelectedId} />} />
          <Route path='add_product' element={<AddProduct setSelectedId={props.setSelectedId} />} />
          <Route path='warehouse_details/*' element={<WarehouseRoot setSelectedId={props.setSelectedId} />} />
          <Route path='purchase/*' element={<PurchaseList setSelectedId={props.setSelectedId} />} />
          <Route path='stock/*' element={<StockCRUD setSelectedId={props.setSelectedId} />} />
          <Route path='ecommerceStock/*' element={<EcommercStockCRUD setSelectedId={props.setSelectedId} />} />
          <Route path='stockUpload/*' element={<ExcelUpload setSelectedId={props.setSelectedId} />} />
        </>
      }
    </Routes>
  )
}
