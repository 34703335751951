import {
    Button, Checkbox, Dialog, DialogBody,
    DialogFooter, DialogHeader
} from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useConfiguration } from "src/hooks/configuration_hook";
import { useRESTapi } from "src/hooks/rest_API";
import { ProductCategory, ProductItem, ProductPrice, pagination } from 'src/types';
import Footer from '../../../../components/Footer/Footer';
import { ProductCard, ProductCardSkeleton } from "../../../../components/product/productCard";
import { MultiRangeSlider } from '../../../../components/sliders/doublesideSlider';

type ProductItemOverview = ProductItem & {
    price: ProductPrice,customer_stock_quantity:number
}


export  function SpareParts() {
    const [open, setOpen] = React.useState(false);
    const {
        set_category_tab_theme
    } = useConfiguration()

    const handleOpen = () => setOpen(!open);
    const {categoryId} = useParams()
    const [catogery,setCategory] = useState<ProductCategory>()
    const api = useRESTapi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [range, setRange] = useState<number[]>([50, 100]); // Initial range values
    const [product_skus, set_product_skus] = useState<ProductItemOverview[]>([]);
    const [is_loading, set_is_loading] = useState(false)
    //URLParams
    const urlParams = {
        searchParams: searchParams.get('searchString')
    }

    const handleSliderChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRange = [...range];
        newRange[index] = parseInt(e.target.value, 10);
        setRange(newRange);
    };

    const loadProducts = async () => {
        try {
            set_is_loading(true)
            const res = await api.get<{
                success: boolean, data:{
                    products: ({
                        data: ProductItemOverview[]
                    }) & pagination,
                    category:ProductCategory
                }
                , message: string,
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/productitem_category`,
                mode: 'customer',
                params: {
                    categoryId:'2'
                }
            })

            if (res.data.success === true) {
                set_product_skus(res.data.data.products.data)
                setCategory(res.data.data.category)
                set_is_loading(false)
            }
        } catch (error) {
            alert('something went wrong')
        }

    }



    const sortOptions = [
        'Popularity',
        'Price-Low to High',
        'Price-High to Low',
        'Newest First'
    ];
    const [activeTab, setActiveTab] = useState('Popularity');


    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(
        () => {

            window.scrollTo({ top: 0 })
            set_category_tab_theme({ theme: 'colorful' })

            loadProducts()
        }, [categoryId]
    )

    return (
        <div className='bg-blue-gray-100 relative pt-1'>

            {/* <CarouselProducts/> */}

            <div className="circleblur bg-[#263238] w-28 h-28 top-28 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>
            <div className="circleblur bg-[#263238] w-28 h-28 bottom-28 right-0 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>

            {/* <div className='flex items-center gap-3  overflow-x-scroll w-[80%] ' style={{ scrollbarWidth: 'none' }}>
                <div className='text-[#263238] p-3 font-bold whitespace-nowrap'>Sort by    |</div>
                {sortOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`hover:bg-blue-gray-200 p-1 rounded-[8px] cursor-pointer px-2 whitespace-nowrap   ${option === activeTab ? 'bg-blue-gray-200' : ''}`}
                        onClick={() => handleTabClick(option)}
                    >
                        {option}
                    </div>
                ))}
                <div className='absolute right-0 mr-3 cursor-pointer flex' onClick={handleOpen}><div>Filter</div> <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter-filled" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M20 3h-16a1 1 0 0 0 -1 1v2.227l.008 .223a3 3 0 0 0 .772 1.795l4.22 4.641v8.114a1 1 0 0 0 1.316 .949l6 -2l.108 -.043a1 1 0 0 0 .576 -.906v-6.586l4.121 -4.12a3 3 0 0 0 .879 -2.123v-2.171a1 1 0 0 0 -1 -1z" stroke-width="0" fill="currentColor" />
                </svg></div>
            </div>
            <Dialog
                className='mt-24'
                open={open}
                handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <DialogHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Filter</DialogHeader>
                <DialogBody placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                    className='max-h-80 overflow-y-scroll' >
                    <div>
                        Price
                    </div>
                    <MultiRangeSlider max={100} min={0} />
                    <div>
                        Brand
                    </div>
                    <Checkbox id="ripple-on" label="Brand01" ripple={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /> <br />
                    <Checkbox id="ripple-off" label="Brand02" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                    <div>
                        Discount
                    </div>
                    <Checkbox id="ripple-on" label="50% or more" ripple={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /> <br />
                    <Checkbox id="ripple-off" label="30% or more" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /><br />
                    <Checkbox id="ripple-off" label="10% or more" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />
                    <div>
                        Customer Ratings
                    </div>
                    <Checkbox id="ripple-on" label="4★ & above" ripple={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /> <br />
                    <Checkbox id="ripple-off" label="3★ & above" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /><br />
                    <Checkbox id="ripple-off" label="2★ & above" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} /><br />
                    <Checkbox id="ripple-off" label="1★ & above" ripple={false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined} />

                </DialogBody>
                <DialogFooter placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <Button
                        variant="text"
                        color="gray"
                        onClick={handleOpen}
                        className="mr-1 outline-none" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="gray" onClick={handleOpen} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <span>Confirm</span>
                    </Button>
                </DialogFooter>
            </Dialog>

            <hr style={{ color: 'black' }} /> */}
            {/* <div className=" px-2 pt-8 py-2 font-bold text-center text-2xl">{catogery?catogery.M04_ProductCategoryName:'Spare & Parts'}</div> */}
            <div style={{ scrollbarWidth: 'none' }} className="mainProducts flex flex-wrap gap-4 px-6 pb-6  justify-items-center  overflow-x-scroll justify-center" >



                {
                    is_loading === false
                        ?
                        product_skus.length === 0 ?
                            <div className="flex flex-col items-center justify-center h-full text-center text-gray-700 py-28">
                                <div className="mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-16 h-16 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 3h18M9 3v18m6-18v18m6-6H3"
                                        />
                                    </svg>
                                </div>
                                <h1 className="text-2xl font-semibold mb-2">No Spare Parts Found</h1>
                                <p className="text-gray-500">We couldn't find any products matching your search.</p>
                            </div>
                            :
                            product_skus.map((product, index) => (

                                <ProductCard
                                    key={product.M08_ProductItemID}
                                    MRPPrice={Number(product.price?.M10_MRP_price)}
                                    listPrice={Number(product.price?.M10_List_price)}
                                    description={product.M08_Description}
                                    imagePath={`${process.env.REACT_APP_BACKEND_URL}/${product.M08_ProductImage}`}
                                    productId={String(product.M08_ProductItemID)}
                                    title={product.M08_ProductItemName}
                                    productsku={product.M08_SKU as string}
                                    onclick={
                                        () => {
                                            const name = product.M08_ProductItemName.replace(/ /g, '-').toLowerCase();

                                            window.open(`/${name}/product/?productItemId=${product.M08_ProductItemID}`,'_blank')
                                        }
                                    }
                                    isCustomizationAvailable={product.M08_is_customization_available}
                                    quantity={product.customer_stock_quantity}
                                />

                            ))
                        :
                        Array(10).fill(5).map(
                            any => <ProductCardSkeleton />
                        )
                }

            </div>
            <Footer />

        </div>
    )
}
