import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminRoot from './pages/admin/page';
import LoginPage from './pages/customer/Pages/loginPage/loginPage';
import SignUpPage from './pages/customer/Pages/signUp/signUp';
import { CustomerRoutes } from './pages/customer/page';
import Userprofile from './pages/customer/Pages/userprofile/Userprofile';
import { ProtectedRouteCustomer } from './pages/customer/components/protectRoute';
import { CustomServicePage } from './pages/customer/Pages/testing_page/testingPage';
import { ProfilePage } from './pages/customer/Pages/profilePage/profilePage';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/admin/*' element={<AdminRoot />} />
        <Route path='/login/*' element={<LoginPage />}></Route>
        <Route path='/signUp/*' element={<SignUpPage />}></Route>
        {/* <Route path='/userprofile/*' element={<Userprofile/>}></Route> */}
        <Route path='/testing_page' element={<CustomServicePage/>} />
        <Route path='/profile/*' element={<ProtectedRouteCustomer><ProfilePage/></ProtectedRouteCustomer>} />
        <Route path='/custom-service' element={<CustomServicePage/>} />
        <Route path='/*' element={<ProtectedRouteCustomer><CustomerRoutes/></ProtectedRouteCustomer>} />
      </Routes> 
    </BrowserRouter>
  );
}

export default App;
