import { IconLoader, IconPlus, IconTrash, IconX } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../redux/store'
import { Brand, CustomFile, Product, ProductCustomizationEnum, ProductCustomizationOption, ProductCustomizationOptionKeys, ProductImage, ProductItem, ProductItemHighlights, ProductItemImage, ProductItemKeys, ProductKeys, ProductPrice, ProductPriceKeys, ProductVariation, ProductVariationKeys, ProductVariationOption, ProductVariationOptionKeys, Supplier, customizationOptionType, employee, pagination } from '../../../../../../types'
import { CustomSelectCustomOptionOption, CustomSelectLoadingSearchingOnSelectProps, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../components/input_loading_searching'
import { Button } from '@/src/components/ui/button'
import { CustomizationPage, customizationVariation, customizationVariationLevels, step } from './customization/customizationMaster'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Textarea } from '@/src/components/ui/textarea'
import { Switch } from '@/src/components/ui/switch'
import { Label } from '@/src/components/ui/label'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Trash, View } from 'lucide-react'
import { HightlightsComponent } from './hightlights/highlights'
// import { productOverview } from '../../add_product/component/addmodal'
import { VariationComponentNew } from './variationComponent/variationComponent new'
import { productOverview } from '../../add_product/add_product/component/addmodal'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { useCheckUserType } from '@/src/hooks/check_user'




export type productItemOverview = ProductItem & ProductPrice & {
    images?: ProductItemImage[],
    metatags?: string,
    metadescription?: string
}

type imageIds = string[]
export type customizationIds = string[]

type ProductPagination = {
    perPage: number,     //per page datacount
    total: number,       //total number of page
    page: number         //current page
}

export type Variation_object = {
    variation: ProductVariation | undefined;
    vatiationOption: ProductVariationOption | undefined,
    // mode: 'edit' | 'create' | 'completed',
    source: 'local' | 'cloud',
    error?: string

}

type Customization_object = ProductCustomizationOption & {
    mode: 'edit' | 'create' | 'completed',
    source: 'local' | 'cloud',
    error: string
}

export type ProductCustomizationOptionClient = {
    uniqueId: string;
    customization_name: string;
    value: string | null;  //value is only acceptable if it is dropDown, else it is null
    type: customizationOptionType;
    varientID: string;
    isSlab: 1 | 0; // Assuming this can be of any type as it's null in the example
    rate: number | null; //rate of price hike percentage
    hikePerc: number | null; //break point of parameter percentage, take percentage of minVal
    hikePercInRate: number | null;  //hike percentage of rate
    minVal: number | null; // Assuming this can be of any type as it's null in the example
    maxVal: number | null; // Assuming this can be of any type as it's null in the example
    order: number | null;  //order of arrangement
    baseRate: number | null; //base rate. this rate is addition every time
    isDefault: 1 | 0; // only one varient is default true, other variants are null
}

export enum ProductCustomizationOptionEnumClient {
    customization_name = "customization_name",
    value = "value",
    type = "type",
    varientID = "varientID",
    isSlab = "isSlab",
    rate = "rate",
    hikePerc = "hikePerc",
    hikePercInRate = "hikePercInRate",
    minVal = "minVal",
    maxVal = "maxVal",
    order = "order",
    baseRate = "baseRate",
    isDefault = "isDefault",
}

export type productHighlightOverview = ProductItemHighlights & {
    mode: 'cloude' | 'local'
}

export type updateHighlightsType = {
    id: string,
    text: string,
}


export function AddProductSKUModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow

    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [ProductPriceKeys.M10_End_date]: {
            message: '',
            validation: [
                (value) => {

                    if (value) {

                        if (formData.M10_Start_date == value) {
                            return 'End date must be after the start date';

                        }

                        if (formData.M10_Start_date) {
                            const start = new Date(formData.M10_Start_date);
                            const end = new Date(value);

                            if (start > end) {

                                return 'End date must be after the start date';
                            }

                        }

                        if (mode === 'add' && !formData.M10_MRP_price && !formData.M10_List_price && value) {
                            return 'please clear date'
                        }

                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_ProductItemName]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_M11_ProductID]: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_SKU]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'please select SKU'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_ProductImages]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && mode === 'add') {
                        return 'required'
                    }
                    if (value?.length === 0) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_ProductImage]: {
            message: '',
            validation: [
                (value: any) => {
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductPriceKeys.M10_List_price]: {
            message: '',
            validation: [
                (value: string) => {
                    if (mode === 'edit') {
                        return true
                    }
                    if (!value && mode === 'add' && formData.M10_MRP_price) {
                        return 'required'
                    }
                    return true
                },
                (value: string) => {
                    if (mode === 'edit') {
                        return true
                    }
                    if (/^[\-\+]?[0-9]*(\.[0-9]+)?$/.test(value)) {
                        return true
                    }
                    return 'enter valid amount'
                }
            ]
        },
        [ProductPriceKeys.M10_MRP_price]: {
            message: '',
            validation: [
                (value: string) => {
                    if (mode === 'edit') {
                        return true
                    }
                    // if (!value && mode === 'add') {
                    //     return 'required'
                    // }

                    return true
                },
                (value: string) => {
                    if (mode === 'edit') {
                        return true
                    }
                    if (/^[\-\+]?[0-9]*(\.[0-9]+)?$/.test(value)) {
                        return true
                    }
                    return 'enter valid amount'
                },
                (value: string) => {
                    if (mode === 'edit') {
                        return true
                    }
                    if (Number(formData.M10_List_price) <= Number(value)) {
                        return true
                    }
                    return 'MRP should greater than list price'
                },
            ]
        },
        [ProductPriceKeys.M10_Start_date]: {
            message: '',
            validation: [
                (value: string) => {
                    if (!value && mode === 'add' && formData.M10_List_price) {
                        return 'required'
                    }
                    if (mode === 'add' && !formData.M10_MRP_price && !formData.M10_List_price && value) {
                        return 'please clear date'
                    }
                    // if (value?.length === 0) {
                    //     return 'required'
                    // }
                    return true
                }
            ]
        },

        [ProductItemKeys.M08_M12_Supplier_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_Description]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductItemKeys.M08_15_is_created_by]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        console.log(value)
                        alert('created by employee not submitted')
                        return 'required'
                    }
                    return true
                }
            ]
        },
    } satisfies validationObject
    const alertModal = useAlertModal()

    const { isSEOs } = useCheckUserType()

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<productItemOverview>({
        M08_is_active: 1,
        M08_is_Promotion_applicable: 0,
        M08_M11_ProductID: '',
        M08_M12_Supplier_id: '',
        M08_ProductImages: [],
        M08_ProductItemName: '',
        M08_SKU: '',
        M08_Vendor_Sku: '',
        images: [],
        M08_15_is_created_by: '',
        M10_End_date: '',
        M10_Is_Active: 1,
        M10_List_price: '',
        M10_M01_Created_by: '',
        M10_M08_Product_Item_id: '',
        M10_MRP_price: '',
        M10_Start_date: '',
        M08_Description: '',
        M10_T02_Tax_Rate_id: '1',
        M08_is_customization_available: 0
    })
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<productItemOverview>({
        validationObject: validationObject
    })
    const [add_progress, set_add_progress] = useState(0)
    const [loading, set_loading] = useState(true)
    const [images_url, set_image_url] = useState<Partial<ProductItem>>({
        M08_ProductImages: [],
        images: []
    })
    const [thumbnail_image, set_thumbnail_image] = useState<{
        imageID: string,
        imagePath: string,
        mode: 'local' | 'cloud'
    }>()
    const [images_url_deleted, set_image_url_deleted] = useState<imageIds>([])
    const [variation_option_deleted, set_variation_option_deleted] = useState<customizationIds>([])
    const [update_sku_details, set_update_sku_details] = useState(false)
    // customization
    const [customization_deleted, set_customization_deleted] = useState<customizationIds>([])
    const [customizationObjs, setCustomizationObjs] = useState<ProductCustomizationOptionClient[]>([])
    const [showCustomization, setShowCustomization] = useState<boolean>(false)
    const [customizationName, setCustomizationName] = useState<string>('')
    const [customizationId, setCustomizationId] = useState<string | number>()
    const [step, setStep] = useState<step>('variationStep')
    const [isSpinLoading, setSpinLoading] = useState(false)



    // variations and options selected
    const [variation_object, set_variation_object] = useState<Variation_object[]>([])
    // all customization data stored here
    const [customization_object, set_customization_object] = useState<Customization_object[]>([])
    // is variation add button pressed flag
    const [is_variation_add_pressed, set_is_variation_add_pressed] = useState<boolean>(false)
    // is customization add button pressed flag
    const [is_customization_add_pressed, set_is_customization_add_pressed] = useState<boolean>(false)
    // customization component updation
    const [update_customization, set_update_customization] = useState(false)

    //HIGHLIGHTS
    const [highlights, setHighlights] = useState<productHighlightOverview[]>([

    ])
    // ids of deleted highlights
    const [deleted_highlights_ids, set_deleted_hightlights_ids] = useState<string[]>([])
    // updated highlights
    const [updated_highlights, set_updated_highlights] = useState<updateHighlightsType[]>([])
    const image_input_ref = useRef<HTMLInputElement>(null)
    const thumbnail_image_input_ref = useRef<HTMLInputElement>(null)

    //load all product options
    const load_product_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Product & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: Product[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M11_ProductID!,
                            name: item.M11_Name
                        } satisfies CustomSelectCustomOptionOption & Product
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all suppliers
    const load_supplier_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Supplier & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: Supplier[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/suppliers`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M12_Supplier_id,
                            name: item.M12_Name
                        } satisfies CustomSelectCustomOptionOption & Supplier
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    // on customization change
    // const handleChangeCustomization = ({ name, value, index }:
    //     {
    //         name: string,
    //         value: string,
    //         index: number
    //     }
    // ) => {

    //     const customization: any = customization_object[index]
    //     customization[name] = value
    //     customization_object[index] = customization as Customization_object
    //     set_customization_object([...customization_object])

    // }


    // Create the validation function
    const validateVariationObject = (variationObjects: Variation_object[]): boolean => {
        const validatedObjects = variationObjects.map(variationObject => {
            const { variation, vatiationOption } = variationObject;

            const isVariationValid =
                variation &&
                variation.M06_VariationID !== undefined &&
                variation.M06_M04_CategoryID !== undefined &&
                variation.M06_Name !== undefined &&
                variation.M06_M11_ProductID !== undefined;

            const isVariationOptionValid =
                vatiationOption &&
                vatiationOption.M07_VariationOptionID !== undefined &&
                vatiationOption.M07_Value !== undefined &&
                vatiationOption.M07_M06_VariationID !== undefined &&
                vatiationOption.M07_M011_ProductId !== undefined;

            if (isVariationValid && isVariationOptionValid) {
                return { ...variationObject, error: undefined };
            } else {
                return { ...variationObject, error: 'please select option' };
            }
        });

        const isValid = validatedObjects.every(obj => !obj.error);
        set_variation_object(validatedObjects)
        return isValid
    }


    const fetch_selected_product: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Product & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Product, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M11_Name,
                        value: res.data.data.M11_ProductID,

                    } as (Product & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_supplier: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Supplier & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Supplier, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/suppliers/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M12_Name,
                        value: res.data.data.M12_Supplier_id,

                    } as (Supplier & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }


    async function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: productItemOverview;
        let image_urls: ProductItemImage[] = [];//for image urls

        switch (e.target.name) {
            case ProductItemKeys.M08_ProductImages: {

                let total_image: CustomFile[] = []
                setSpinLoading(true)

                if (e.target.files) {
                    //adding image if there is image
                    // if (formData.M08_ProductImages) {
                    //     const new_images: CustomFile[] = [...e.target.files].map(
                    //         item => {
                    //             const uniqueId = uuidv4();
                    //             const url = URL.createObjectURL(item)
                    //             image_urls.push(
                    //                 {
                    //                     M18_ImageID: uniqueId,
                    //                     M18_ImagePath: url,
                    //                     mode: 'local',

                    //                 }
                    //             )
                    //             return ({
                    //                 file: item,
                    //                 id: uniqueId

                    //             })
                    //         }
                    //     )
                    //     total_image = [...formData.M08_ProductImages as CustomFile[], ...new_images]
                    // } else {
                    const TotalCount: number = e.target.files.length
                    let checkedImageSizeCount: number = 0;
                    let checkedImageSizeCountTrue: number = 0;
                    const imagesFiles = [...e.target.files];

                    imagesFiles.forEach(
                        imageFile => {
                            const image = new Image();

                            // checking ratio of image
                            image.onload = () => {
                                const aspectRatio = image.width / image.height;
                                checkedImageSizeCount++;
                                console.log(aspectRatio)
                                if (aspectRatio >= 0.9 && aspectRatio <= 1.1) {
                                    checkedImageSizeCountTrue++;
                                    // if all image is in ratio
                                    if (checkedImageSizeCountTrue === TotalCount) {
                                        //adding image if there is image
                                        if (formData.M08_ProductImages?.length) {
                                            const new_images: CustomFile[] = imagesFiles.map(
                                                item => {
                                                    const uniqueId = uuidv4();
                                                    const url = URL.createObjectURL(item)
                                                    image_urls.push(
                                                        {
                                                            M18_ImageID: uniqueId,
                                                            M18_ImagePath: url,
                                                            mode: 'local',

                                                        }
                                                    )
                                                    return ({
                                                        file: item,
                                                        id: uniqueId

                                                    })
                                                }
                                            )
                                            total_image = [...formData.M08_ProductImages as CustomFile[], ...new_images]
                                            formdata = {
                                                ...formData,
                                                M08_ProductImages: total_image
                                            }
                                        } else {
                                            const new_images: CustomFile[] = imagesFiles.map(
                                                item => {
                                                    const uniqueId = uuidv4();
                                                    const url = URL.createObjectURL(item)
                                                    image_urls.push(
                                                        {
                                                            M18_ImageID: uniqueId,
                                                            M18_ImagePath: url,
                                                            mode: 'local'
                                                        }
                                                    )
                                                    return ({
                                                        file: item,
                                                        id: uniqueId

                                                    })
                                                }
                                            )
                                            total_image = [...new_images]
                                            formdata = {
                                                ...formData,
                                                M08_ProductImages: total_image
                                            }
                                        }

                                        //CREATING IMAGE DISPLAY URLS
                                        set_image_url({
                                            ...images_url,
                                            images: [...images_url.images!, ...image_urls]
                                        });
                                        performValidationOf(e.target.name, formdata)
                                        setFormData(formdata)
                                        setSpinLoading(false)

                                    }

                                }
                                // if all checking completed and aspect ration is not correct
                                if (checkedImageSizeCount === TotalCount && checkedImageSizeCountTrue !== TotalCount) {
                                    alert("Image aspect ratio must be 1");
                                    setSpinLoading(false)
                                }



                            };
                            image.src = URL.createObjectURL(imageFile)

                        }
                    )


                }
                // }
                formdata = {
                    ...formData,
                    M08_ProductImages: total_image
                }
                // const images = formdata.M11_ProductImages as CustomFile[]


                break;
            }
            case ProductItemKeys.M08_ProductImage: {

                let total_image: CustomFile


                if (e.target.files && e.target.files.length > 0) {
                    setSpinLoading(true)
                    const image = new Image();
                    const url = URL.createObjectURL(e.target.files[0])

                    image.onload = () => {
                        const aspectRatio = image.width / image.height;
                        console.log(aspectRatio)
                        if (aspectRatio >= 1.89 && aspectRatio <= 2) {
                            const uniqueId = uuidv4();
                            set_thumbnail_image(
                                {
                                    imageID: uniqueId,
                                    imagePath: url,
                                    mode: 'local'
                                }
                            )
                            const new_images: CustomFile = {
                                file: e.target.files![0],
                                id: uniqueId

                            }

                            total_image = new_images
                            formdata = {
                                ...formData,
                                M08_ProductImage: total_image
                            }
                        } else {


                            alert("Image aspect ratio must be 1.9");
                            formdata = {
                                ...formData,
                                M08_ProductImage: ''
                            }
                        }
                        setFormData(formdata)
                        performValidationOf(e.target.name, formdata);


                        setSpinLoading(false)
                    };
                    image.src = url


                }
                formdata = {
                    ...formData,
                    M08_ProductImage: ''
                }
                // const images = formdata.M11_ProductImages as CustomFile[]


                break;
            }

            case ProductItemKeys.M08_is_active:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
                break
            case ProductItemKeys.M08_is_Promotion_applicable:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
                break

            case ProductItemKeys.M08_M11_ProductID:
                const event = e as CustomSelectLoadingSearchingOnSelectProps<Product>;
                console.log(event)
                if (event.target.object) {
                    setIsLoading(true)
                    try {
                        const res = await api.get<{ success: boolean, data: productOverview, message?: string }>({
                            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${event.target.object.M11_ProductID}`,
                            mode: 'admin',

                        })
                        if (res.data.success === true) {

                            set_variation_object(
                                res.data.data.variations?.map(
                                    item => ({
                                        mode: 'create',
                                        source: 'local',
                                        variation: {
                                            M06_Name: item.Name,
                                            M06_M04_CategoryID: item.CategoryID,
                                            M06_M11_ProductID: item.ProductID,
                                            M06_VariationID: item.VariationID
                                        },
                                        vatiationOption: {
                                            M07_Value: '',
                                            M07_M11_ProductID: item.ProductID
                                        }
                                    })
                                )


                                //     ||
                                //  [{
                                //     // mode: 'create',
                                //     source: 'local',
                                //     variation: {
                                //         M06_M04_CategoryID: 1234432,
                                //         M06_Name: 'size'
                                //     },
                                //     vatiationOption: {
                                //         M07_Value: ''
                                //     },
                                //     error:'please select object'
                                // }]
                            )

                            formdata = {
                                ...formData,
                                M08_is_active: 1,
                                M08_is_Promotion_applicable: 0,
                                M08_M11_ProductID: e.target.value,
                                M08_M12_Supplier_id: '',
                                M08_ProductImages: [],
                                M08_ProductItemName: event.target.object.M11_Name,
                                M08_SKU: '',
                                M08_Vendor_Sku: '',
                                images: [],
                                M08_15_is_created_by: '',
                                M10_End_date: '',
                                M10_Is_Active: 1,
                                M10_List_price: '',
                                M10_M01_Created_by: '',
                                M10_M08_Product_Item_id: '',
                                M10_MRP_price: '',
                                M10_Start_date: '',
                                M08_Description: event.target.object.M11_Description,
                                M10_T02_Tax_Rate_id: '1',
                                M08_is_customization_available: 0,

                            }
                            setIsLoading(false)


                            break;

                        } else {
                            alert(res.data.message)
                            setIsLoading(false)
                            formdata = {
                                ...formData,
                                [e.target.name]: ''
                            }
                            break;
                        }
                    } catch (error) {
                        console.log('error')
                        alert('Server error')
                        setIsLoading(false)
                        formdata = {
                            ...formData,
                            [e.target.name]: ''
                        }
                        break;
                    }
                }

                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }





    async function add_product() {

        setIsLoading(true)
        formData.M08_15_is_created_by = employee.employeeData.M15_Employee_id!
        if (is_variation_add_pressed) {
            alert('please complete variation first')
            return;
        }
        if (is_customization_add_pressed === true) {
            alert('please complete customization first')
            return;
        }
        console.log(variation_option_deleted)
        if (performAllValidation(formData) === true && validateVariationObject(variation_object) === true) {


            try {

                const formDataReal = new FormData();

                // checking customization
                if (customizationObjs.length > 0) {
                    if (!customizationName) {
                        alert('enter cutomizationName first')
                        return;
                    }
                    formDataReal.append(`customizationName`, customizationName)
                    // SETTING DEFUALT OPTION
                    const newCustomizationObj = [...customizationObjs]
                    const defaultOption = newCustomizationObj.filter(item => item.isDefault === 1 && item.type === 'dropDown')
                    if (defaultOption.length) {
                        const defualtVarientId = defaultOption[0].varientID
                        newCustomizationObj.forEach(
                            item => {
                                if (item.varientID === defualtVarientId) {
                                    item.isDefault = 1
                                } else {
                                    item.isDefault = 0
                                }
                            }
                        )
                    }
                    newCustomizationObj.forEach(
                        (option, index) => {

                            Object.entries(option).map(
                                data => {
                                    switch (data[0]) {

                                        case ProductCustomizationOptionEnumClient.customization_name:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_customization_name}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.value:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_value}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.type:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_type}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.varientID:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_varientID}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.isSlab:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_isSlab}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.rate:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_rate}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.hikePerc:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_hikePerc}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.hikePercInRate:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_hikePercInRate}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.minVal:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_minVal}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.maxVal:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_maxVal}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.order:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_order}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.baseRate:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_baseRate}]`, String(data[1] ? data[1] : ''))
                                            break;
                                        case ProductCustomizationOptionEnumClient.isDefault:
                                            formDataReal.append(`customizationOption[${index}][${ProductCustomizationOptionKeys.M22_isDefault}]`, String(data[1] ? data[1] : ''))
                                            break;

                                        default:
                                            break;
                                    }
                                }
                            )

                        }
                    )
                    formDataReal.append(ProductItemKeys.M08_is_customization_available, '1')
                }

                // checking highlights
                if (highlights.length > 0) {

                    highlights.forEach(
                        highlights => {
                            formDataReal.append('highlights[]', highlights.M24_highlight_text)
                        }
                    )

                }

                // console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductItemKeys.M08_ProductImages:
                                const images = data[1] as CustomFile[]
                                images.map(
                                    image => formDataReal.append(`${data[0]}[]`, image.file)
                                )
                                break;

                            case ProductItemKeys.M08_ProductImage:
                                const image = data[1] as CustomFile
                                formDataReal.append(`${data[0]}`, image.file)
                                break;

                            case ProductItemKeys.M08_15_is_created_by:
                                formDataReal.append(data[0], String(employee.employeeData.M15_Employee_id))
                                break;
                            // case ProductPriceKeys.M10_Is_Active:
                            //     formDataReal.append(data[0], '1')
                            //     break;
                            case ProductPriceKeys.M10_T02_Tax_Rate_id:
                                formDataReal.append(data[0], '1')
                                break;
                            case ProductItemKeys.M08_meta_description:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                formDataReal.append('metadescription', data[1] ? String(data[1]) : '')
                                break;
                            case ProductItemKeys.M08_meta_tags:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                formDataReal.append('metatag', data[1] ? String(data[1]) : '')
                                break;
                            case ProductItemKeys.M08_ProductItemName:
                            case ProductItemKeys.M08_M11_ProductID:
                            case ProductItemKeys.M08_Vendor_Sku:
                            case ProductItemKeys.M08_SKU:
                            case ProductItemKeys.M08_M12_Supplier_id:
                            case ProductItemKeys.M08_is_active:
                            case ProductItemKeys.M08_is_Promotion_applicable:
                            case ProductItemKeys.M08_Description:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                            case ProductPriceKeys.M10_MRP_price:
                            case ProductPriceKeys.M10_List_price:
                            case ProductPriceKeys.M10_Start_date:
                            case ProductPriceKeys.M10_Is_Active:
                            case ProductPriceKeys.M10_End_date:
                                if(formData.M10_MRP_price){
                                    formDataReal.append(data[0], data[1]?String(data[1]):'')
                                }
                                break;
                            default:

                                break;
                        }

                    }
                )

                // adding variation options
                variation_object.forEach(
                    obj => {
                        if (obj.vatiationOption) {
                            if (obj.source === 'local') {
                                formDataReal.append(`M09_T07_VariationOptionID[]`, String(obj.vatiationOption.M07_VariationOptionID))
                            }
                        }
                    }
                )


                // customization option

                customization_object.forEach(
                    (item, index) => {
                        if (item.source === 'local') {
                            formDataReal.append(`M17_customization_option[]`, String(item.M22_value))
                            formDataReal.append(`M17_price[]`, String(item.M22_rate))
                            formDataReal.append(`M17_unit[]`, String(item.M22_rate))
                        }
                    }
                )



                const res = await api.post<{ success: boolean, data: ProductItem, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_item`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }

                })

                if (res.data.success === true) {
                    updateUi();
                    alert(res.data.message)
                    // set_thumbnail_image(undefined)

                } else {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            alertModal.open_alert_modal({
                content: 'Please check the given input. Validation failed or something to fill !!',
                footer: <Button onClick={() => {
                    alertModal.close_alert_modal()
                }}>Ok</Button>,
                title: 'Validation error'
            })
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.M08_15_is_created_by = employee.employeeData.M15_Employee_id!
        if (is_variation_add_pressed) {
            alert('please complete variation first')
            return;
        }
        if (is_customization_add_pressed === true) {
            alert('please complete customization first')
            return;
        }
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductItemKeys.M08_ProductImages:
                                const images = data[1] as CustomFile[]
                                images.map(
                                    image => formDataReal.append(`${data[0]}[]`, image.file)
                                )
                                break;
                            case ProductItemKeys.M08_ProductImage:
                                if (thumbnail_image?.mode === 'local') {
                                    const image = data[1] as CustomFile
                                    formDataReal.append(`Updated_thumbnail_image`, image.file)
                                }
                                break;
                            case ProductItemKeys.M08_15_is_created_by:
                                formDataReal.append(data[0], String(employee.employeeData.M15_Employee_id))
                                break;
                            case ProductPriceKeys.M10_T02_Tax_Rate_id:
                                formDataReal.append(data[0], '1')
                                break;
                            case ProductItemKeys.M08_meta_description:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                formDataReal.append('metadescription', data[1] ? String(data[1]) : '')
                                break;
                            case ProductItemKeys.M08_meta_tags:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                formDataReal.append('metatag', data[1] ? String(data[1]) : '')
                                break;
                            case ProductItemKeys.M08_ProductItemName:
                            case ProductItemKeys.M08_M11_ProductID:
                            case ProductItemKeys.M08_Vendor_Sku:
                            case ProductItemKeys.M08_SKU:
                            case ProductItemKeys.M08_M12_Supplier_id:
                            case ProductItemKeys.M08_is_active:
                            case ProductItemKeys.M08_is_Promotion_applicable:
                            case ProductPriceKeys.M10_MRP_price:
                            case ProductPriceKeys.M10_List_price:
                            case ProductPriceKeys.M10_Start_date:
                            case ProductPriceKeys.M10_End_date:
                            case ProductItemKeys.M08_Description:
                            case ProductPriceKeys.M10_Is_Active:
                                formDataReal.append(data[0], data[1] || data[1] == 0 ? String(data[1]) : '')
                                break;
                            default:

                                break;

                        }

                    }
                )



                // adding variation options
                variation_object.forEach(
                    obj => {
                        if (obj.vatiationOption) {
                            if (obj.source === 'local') {
                                formDataReal.append(`M09_T07_VariationOptionID[]`, String(obj.vatiationOption.M07_VariationOptionID))
                            }
                        }
                    }
                )

                // customization option

                customization_object.forEach(
                    (item, index) => {
                        if (item.source === 'local') {
                            formDataReal.append(`M17_customization_option[]`, String(item.M22_value))
                            formDataReal.append(`M17_price[]`, String(item.M22_rate))
                            formDataReal.append(`M17_unit[]`, String(item.M22_rate))
                        }
                    }
                )


                images_url_deleted.forEach(
                    item => {
                        formDataReal.append('delete_image_ids[]', item)
                    }
                )
                variation_option_deleted.forEach(
                    item => {
                        formDataReal.append('delete_variation_option_ids[]', item)
                    }
                )
                customization_deleted.forEach(
                    item => {
                        formDataReal.append('delete_customization_ids[]', item)
                    }
                )
                deleted_highlights_ids.forEach(
                    item => {
                        formDataReal.append('deleted_highlights_id[]', item)
                    }
                )

                // checking highlights
                if (highlights.length > 0) {

                    highlights.forEach(
                        highlights => {
                            if (highlights.mode === 'local')
                                formDataReal.append('highlights[]', highlights.M24_highlight_text)
                        }
                    )

                }

                updated_highlights.forEach(
                    (item, index) => {
                        if (item.id && item.text) {
                            formDataReal.append(`updated_highlights[${index}][id]`, String(item.id))
                            formDataReal.append(`updated_highlights[${index}][text]`, String(item.text))
                        }
                    }
                )



                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }

                })

                if (res.data.success === true) {

                    updateUi();
                    alert(res.data?.message || 'updated product')
                    closeModal()

                } else {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            alert('validation error')
        }
        setIsLoading(false)

    }


    const load_product = async () => {

        function generateId() {
            return uuidv4().substring(0, 32); // Truncate to 32 characters
        }

        const res = await api.get<{
            success: boolean, data: productItemOverview & {
                images: ProductItemImage[],
                variations: (ProductVariationOption & ProductVariation)[],
                customization: {
                    M21_customization_id: string | number,
                    M21_customization_name: string,
                    customizationOptions: ProductCustomizationOption[]
                },
                highlights: ProductItemHighlights[],
            }, message: string
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            images_url.images = res.data.data.images ? res.data.data.images : []
            images_url.images = images_url.images.map(
                item => ({
                    ...item,
                    mode: 'cloude'
                })
            )

            set_thumbnail_image({
                imageID: res.data.data.M08_ProductImage! as string,
                imagePath: res.data.data.M08_ProductImage! as string,
                mode: 'cloud'
            })


            const variations: Variation_object[] = res.data.data.variations.map(
                item => ({
                    mode: 'completed',
                    variation: {
                        M06_M04_CategoryID: item.M06_M04_CategoryID,
                        M06_Name: item.M06_Name,
                        M06_M11_ProductID: item.M06_M11_ProductID,
                        M06_VariationID: item.M06_VariationID
                    },
                    vatiationOption: {
                        M07_Value: item.M07_Value,
                        M07_VariationOptionID: item.M07_VariationOptionID,
                        M07_M06_VariationID: item.M07_M06_VariationID,
                        M07_M11_ProductID: item.M07_M011_ProductId
                    },
                    source: 'cloud'
                })
            )

            // setting highlights

            const highlights: productHighlightOverview[] = res.data.data.highlights.map(
                item => ({
                    ...item,
                    mode: 'cloude'
                })
            )

            setHighlights(highlights)

            set_variation_object(variations)

            // load customization
            if (res.data.data.customization?.customizationOptions?.length) {
                setStep('completed')
                setCustomizationName(res.data.data.customization.M21_customization_name)
                setCustomizationId(res.data.data.customization.M21_customization_id)
                set_update_customization(!update_customization)
                const customizationObj = res.data.data.customization.customizationOptions.map(
                    option => {
                        return ({
                            baseRate: option.M22_baseRate,
                            customization_name: option.M22_customization_name,
                            hikePerc: option.M22_hikePerc ? Number(option.M22_hikePerc) : null,
                            hikePercInRate: option.M22_hikePercInRate ? Number(option.M22_hikePercInRate) : null,
                            isDefault: option.M22_isDefault,
                            isSlab: option.M22_isSlab,
                            maxVal: option.M22_maxVal ? Number(option.M22_maxVal) : null,
                            minVal: option.M22_minVal ? Number(option.M22_minVal) : null,
                            order: option.M22_order,
                            rate: option.M22_rate ? Number(option.M22_rate) : null,
                            type: option.M22_type,
                            uniqueId: generateId(),
                            value: option.M22_value,
                            varientID: option.M22_varientID,
                        } satisfies ProductCustomizationOptionClient)
                    }
                )

                // convertToCustomizationVariationLevels(customizationObj)

                setCustomizationObjs(customizationObj)

            }




            console.log(images_url)
            // res.data.data.M11_ProductImages = []
            setFormData(res.data.data)
            set_image_url({
                ...images_url,
                images: [...images_url.images]
            })
        } else {
            alertModal.open_alert_modal({
                content: res.data.message,
                footer: <Button onClick={() => {
                    alertModal.close_alert_modal()
                    setShow(false)
                }}>Ok</Button>,
                title: 'Error'
            })
        }


    }

    const delete_image = (image_id: string | undefined | null) => {

        const fileArray: CustomFile[] = formData.M08_ProductImages as CustomFile[]
        //DELETING FROM BLOB FILE
        if (fileArray) {
            const newFileArray = fileArray.filter(
                item => item.id !== image_id
            )
            formData.M08_ProductImages = newFileArray
        }
        // console.log(newFileArray)
        setFormData(formData)

        //DELETING FROM URLS
        const new_image_urls = images_url.images?.filter(
            item => {
                if (item.M18_ImageID === image_id) {

                    if (item.mode === 'cloude') {
                        images_url_deleted.push(String(item.M18_ImageID))
                        const uniqueArray = [...new Set(images_url_deleted)];
                        set_image_url_deleted(uniqueArray)
                    }

                    return false
                }
                return true
            }
        )

        set_image_url(
            {
                ...images_url,
                images: new_image_urls
            }
        )


    }

    const clear_form = () => {
        setFormData({
            M08_is_active: 1,
            M08_is_Promotion_applicable: 0,
            M08_M11_ProductID: '',
            M08_M12_Supplier_id: '',
            M08_ProductImages: [],
            M08_ProductItemName: '',
            M08_SKU: '',
            M08_Vendor_Sku: '',
            images: [],
            M08_15_is_created_by: '',
            M10_End_date: '',
            M10_Is_Active: 1,
            M10_List_price: '',
            M10_M01_Created_by: '',
            M10_M08_Product_Item_id: '',
            M10_MRP_price: '',
            M10_Start_date: '',
            M08_Description: '',
            M10_T02_Tax_Rate_id: '1',
            M08_is_customization_available: 0,
            M08_meta_description: '',
            M08_meta_tags: ''
        })
        set_is_variation_add_pressed(false)
        set_is_customization_add_pressed(false)

        set_thumbnail_image(undefined)
        set_add_progress(0)
        setStep('variationStep')
        set_variation_object([])

        setCustomizationName('')
        set_customization_object([])
        setCustomizationObjs([])
        set_deleted_hightlights_ids([])
        set_updated_highlights([])
        set_image_url({
            images: [],
            M08_ProductImages: []
        })
        setHighlights([])
    }

    useEffect(
        () => {

            const initListSetup = () => {
                // set_loading(true)
                clear_form()
                set_loading(false)


            }
            const initEditSetup = () => {
                set_loading(true)
                clear_form()
                Promise.all(
                    [load_product()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        console.log(err)
                        set_loading(false)

                    }
                )
                // set_loading(false)


            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)
                set_image_url({
                    M08_ProductImages: [],
                    images: []
                })
                clear_form()
            }


        }, [show, update_sku_details]
    )

    const onclick_add_image_input = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }
    const onclick_add_thumbnailImage_image_input = () => {
        thumbnail_image_input_ref.current?.click();
        thumbnail_image_input_ref.current!.value = '';

    }


    return (
        <AdminModal show={show} setShow={(show) => {
            if (show) {
                setShow(show)
            }
        }}  >
            <AdminModalContent disableCloseButton  >

                <>
                    <AdminModalHeader className='relative' progressValue={add_progress}  >
                        <div onClick={() => setShow(false)} className=' absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer p-2'><IconX size={20} /></div>
                        <div className=' text-2xl font-bold '>{mode === 'edit' ? 'Edit Product SKU' : 'Add Product SKU'}</div>
                    </AdminModalHeader>
                    <AdminModalMiddle>
                        {/* <div onClick={closeModal} className=" absolute right-0 top-0 p-1 cursor-pointer"><IconX /></div> */}

                        {
                            isSpinLoading
                            &&
                            <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                                <div className=' animate-spin '><IconLoader /></div>
                            </div>
                        }
                        <div className=' pt-2'></div>
                        <Label className=' pt-5'>meta title</Label>
                        <Input disabled={isLoading} value={formData.M08_meta_tags} onChange={handleChangeInput} type="text" name={ProductItemKeys.M08_meta_tags} placeholder='name' />
                        <div className=' pt-2'></div>
                        <Label className=' pt-5'>meta description</Label>
                        <Input disabled={isLoading} value={formData.M08_meta_description} onChange={handleChangeInput} type="text" name={ProductItemKeys.M08_meta_description} placeholder='name' />
                        <div className=' pt-2'></div>
                        <SelectCustomLoadingSearching<Product & CustomSelectCustomOptionOption>
                            loadOption={
                                load_product_options
                            }
                            fetchSelectOption={fetch_selected_product}
                            disable={mode === 'edit' || loading || isLoading ? true : false}
                            onselect={handleChangeInput}
                            value={formData.M08_M11_ProductID}

                            customItem={{
                                element: (props) => {
                                    return <div key={props.key} onClick={() => {
                                        props.select_item()
                                        props.close_menu()
                                    }} className='px-2 py-2  hover:bg-gray-400 cursor-pointer flex items-center gap-1'>
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.images?.[0]?.P13Image_path}`} alt="" height={60} width={60} />
                                        <div>{props.M11_Name}</div>
                                    </div>
                                },
                                valueName: ProductKeys.M11_Product_id,
                                selectedItem: (props) => {
                                    return (<div className=' w-full p-2 border flex relative'>
                                        <div style={{ display: mode === 'edit' ? 'none' : 'block' }} onClick={() => {
                                            props.close();
                                            clear_form();
                                        }} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.images?.[0]?.P13Image_path}`} alt="" height={60} width={60} />
                                        <div>{props.M11_Name}</div>
                                    </div>)
                                }
                            }}
                            title='Product'

                            // value={}

                            name={ProductItemKeys.M08_M11_ProductID}
                            placeholder='product'
                        />
                        <div className=' text-red-500'>{errorData.M08_M11_ProductID ? errorData.M08_M11_ProductID : null}</div>

                        <Label className=' pt-5'>Product name</Label>
                        <Input disabled={isLoading || !formData.M08_M11_ProductID || isSEOs(employee?.employeeData?.M15_Employee_id!)} value={formData.M08_ProductItemName} onChange={handleChangeInput} type="text" name={ProductItemKeys.M08_ProductItemName} placeholder='name' />
                        <div className=' text-red-500'>{errorData.M08_ProductItemName ? errorData.M08_ProductItemName : null}</div>
                        <div className=' pt-2'></div>
                        <Label className=' '>Description</Label>
                        <Textarea disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} rows={3} value={formData.M08_Description} onChange={handleChangeInput as unknown as React.ChangeEventHandler<HTMLTextAreaElement>} name={ProductItemKeys.M08_Description} placeholder='Description' />
                        <div className=' text-red-500'>{errorData.M08_Description ? errorData.M08_Description : null}</div>

                        <div className=' pt-2'></div>
                        <SelectCustomLoadingSearching<Supplier & CustomSelectCustomOptionOption>
                            loadOption={load_supplier_options}
                            fetchSelectOption={fetch_selected_supplier}
                            value={formData.M08_M12_Supplier_id}
                            name={ProductItemKeys.M08_M12_Supplier_id}
                            placeholder='supplier'
                            onselect={handleChangeInput}
                            title='Supplier'
                            disable={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID}
                        />
                        <div className=' text-red-500'>{errorData.M08_M12_Supplier_id ? errorData.M08_M12_Supplier_id : null}</div>

                        {/* <VariationComponent
                            formData={formData}
                            is_variation_add_pressed={is_variation_add_pressed}
                            selected_product={selected_product}
                            selected_variation={selected_variation}
                            selected_variation_option={selected_variation_option}
                            set_is_variation_add_pressed={set_is_variation_add_pressed}
                            set_selected_variation={set_selected_variation}
                            set_selected_variation_option={set_selected_variation_option}
                            set_variation_object={set_variation_object}
                            set_variation_option_deleted={set_variation_option_deleted}
                            variation_object={variation_object}
                            variation_option_deleted={variation_option_deleted}
                        /> */}

                        <VariationComponentNew
                            set_variation_objects={set_variation_object}
                            variation_objects={variation_object}
                            disabled={isSEOs(employee?.employeeData?.M15_Employee_id!)}
                        />
                        {/* <Button onClick={()=>{
                            if(validateVariationObject(variation_object)===false){
                                alert('validation failed')
                            }
                        }}>Validate</Button> */}

                        <div className=' pt-2'></div>
                        <Label>Customization</Label><br />
                        {
                            customizationName
                                ?
                                <div className=' flex flex-col gap-1 w-40' >
                                    <div className=' p-2 border rounded-sm w-40 truncate bg-slate-500 text-white'>{customizationName}</div>
                                    <div className=' w-full flex justify-end gap-2'>
                                        <ButtonIcon
                                            icon={<Trash className=' w-4 h-4' />}
                                            toolTipText='Delete'
                                            onClick={() => {
                                                setCustomizationObjs([])
                                                setCustomizationName('')
                                                setStep('variationStep')
                                            }}
                                            disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID}
                                        />
                                        <ButtonIcon
                                            icon={<View className=' w-4 h-4' />}
                                            toolTipText='View'
                                            onClick={() => setShowCustomization(true)}
                                            disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID}
                                        />
                                    </div>
                                </div>
                                :
                                <Button disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} onClick={() => setShowCustomization(true)} >Add Customization</Button>
                        }
                        <CustomizationPage
                            customizationObjs={customizationObjs}
                            setCustomizationObjs={setCustomizationObjs}
                            setShow={setShowCustomization}
                            show={showCustomization}
                            customizationName={customizationName}
                            setCustomizationName={setCustomizationName}
                            customizationId={customizationId}
                            setStep={setStep}
                            step={step}
                            updateVariable={update_customization}
                            mode={mode}
                            updateSkuDetails={() => set_update_sku_details(!update_sku_details)}
                            productSku={formData}

                        />
                        <div className=' pt-2'></div>
                        <Label>vendor SKU (optional)</Label>
                        <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M08_Vendor_Sku} onChange={handleChangeInput} type="text" name={ProductItemKeys.M08_Vendor_Sku} placeholder='name' />
                        <div className=' text-red-500'>{errorData.M08_Vendor_Sku ? errorData.M08_Vendor_Sku : null}</div>
                        <div className=' pt-2'></div>
                        <Label>SKU</Label>
                        <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M08_SKU} onChange={handleChangeInput} type="text" name={ProductItemKeys.M08_SKU} placeholder='sku' />
                        <div className=' text-red-500'>{errorData.M08_SKU ? errorData.M08_SKU : null}</div>
                        <HightlightsComponent
                            highlights={highlights}
                            setHighlights={setHighlights}
                            delete_highlights_ids={deleted_highlights_ids}
                            set_delete_highlights_ids={set_deleted_hightlights_ids}
                            update_highlights={updated_highlights}
                            setUpdate_highlights={set_updated_highlights}
                            disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID}
                        />
                        <div className=' pt-2'></div>
                        <Label>Thumbnail image (ratio 1.9 - recommended - 329 x 173)</Label>
                        {thumbnail_image !== undefined
                            ?
                            <div className=' relative  h-20 aspect-[1.9]    group'>
                                <img src={`${thumbnail_image.mode === 'cloud' ? `${process.env.REACT_APP_BACKEND_URL}/${thumbnail_image.imagePath}` : thumbnail_image.imagePath}`} alt="" className=' h-full w-full' />
                                <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                    <Button disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} onClick={() => {
                                        set_thumbnail_image(undefined)
                                        setFormData(
                                            { ...formData, M08_ProductImage: undefined }
                                        )
                                    }} >delete</Button>
                                </div>
                            </div>
                            :
                            <div onClick={onclick_add_thumbnailImage_image_input} className=' min-h-20 min-w-20 max-w-20 max-h-20 border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>
                        }

                        <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} ref={thumbnail_image_input_ref} className=' hidden' onChange={handleChangeInput} type="file" accept='.png, .jpg' name={ProductItemKeys.M08_ProductImage} placeholder='Image' />
                        <div className=' text-red-500'>{errorData.M08_ProductImage ? errorData.M08_ProductImage as string : null}</div>
                        <div className=' pt-2'></div>
                        <Label>Image</Label>
                        {
                            <div className=' flex gap-1 max-w-full overflow-x-auto overflow-y-hidden min-h-20'>
                                {images_url.images && Array.isArray(images_url.images) ? images_url.images.map(
                                    (image, index) => {
                                        return <div key={index} className=' relative w-20- h-20 min-w-20 min-h-20 max-w-20 max-h-20   group'>
                                            <img src={`${image.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${image.M18_ImagePath}` : image.M18_ImagePath}`} alt="" className=' h-full w-full' />
                                            <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                                <Button disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} onClick={() => delete_image(image.M18_ImageID as string)} >delete</Button>
                                            </div>
                                        </div>
                                    }
                                    //    (image) => <img src={`${process.env.REACT_APP_BACKEND_URL}/${image as string}`} alt="" height={80} width={80} />
                                ) : null}
                                <div onClick={onclick_add_image_input} className=' min-h-20 min-w-20 border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>
                                <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} ref={image_input_ref} id='imageInput' className=' hidden' onChange={handleChangeInput} type="file" multiple accept='.png, .jpg' name={ProductItemKeys.M08_ProductImages} placeholder='Image' />
                            </div>
                        }
                        <div className=' text-red-500'>{errorData.M08_ProductImages ? errorData.M08_ProductImages as string : null}</div>
                        {
                            mode === 'add' ?
                                <>
                                    <div className=' pt-4'></div>
                                    <div className=' font-bold'>Pricing</div>
                                    <div>MRP</div>
                                    <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M10_MRP_price} onChange={handleChangeInput} type="text" name={ProductPriceKeys.M10_MRP_price} placeholder='MRP Price' />
                                    <div className=' text-red-500'>{errorData.M10_MRP_price ? errorData.M10_MRP_price : null}</div>
                                    <div className=' pt-2'></div>
                                    <div>Selling price</div>
                                    <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M10_List_price} onChange={handleChangeInput} type="text" name={ProductPriceKeys.M10_List_price} placeholder='List Price' />
                                    <div className=' text-red-500'>{errorData.M10_List_price ? errorData.M10_List_price : null}</div>
                                    <div className=' pt-2'></div>
                                    <div>Start Date</div>
                                    <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M10_Start_date} onChange={handleChangeInput} type="date" name={ProductPriceKeys.M10_Start_date} placeholder='MRP Price' />
                                    <div className=' text-red-500'>{errorData.M10_Start_date ? errorData.M10_Start_date : null}</div>
                                    <div className=' pt-2'></div>
                                    <div>End Date</div>
                                    <Input disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} value={formData.M10_End_date} onChange={handleChangeInput} type="date" name={ProductPriceKeys.M10_End_date} placeholder='MRP Price' />
                                    <div className=' text-red-500'>{errorData.M10_End_date ? errorData.M10_End_date : null}</div>
                                </>
                                :
                                null
                        }

                        <div className=' flex gap-1 pt-3'>
                            <Switch disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} checked={formData.M08_is_Promotion_applicable === 1 ? true : false}
                                name={ProductItemKeys.M08_is_Promotion_applicable}
                                onCheckedChange={
                                    (checked) => handleChangeInput({
                                        target: {
                                            name: ProductItemKeys.M08_is_Promotion_applicable,
                                            value: checked ? 1 : '0',
                                            checked: checked
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                            />
                            <div>is promotion</div>
                        </div>
                        <div className=' flex gap-1 pt-3'>
                            <Switch disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} checked={formData.M08_is_active === 1 ? true : false}
                                name={ProductItemKeys.M08_is_active}
                                onCheckedChange={
                                    (checked) => handleChangeInput({
                                        target: {
                                            name: ProductItemKeys.M08_is_active,
                                            value: checked ? 1 : '0',
                                            checked: checked
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                            />
                            <div>is active</div>
                        </div>
                    </AdminModalMiddle>

                    <AdminModalFooter>
                        <div className=' flex justify-end gap-1'>
                            <Button variant={'outline'} onClick={() => setShow(false)}>Cancel</Button>
                            {
                                mode === 'add' ?
                                    <Button disabled={isSEOs(employee?.employeeData?.M15_Employee_id!) || isLoading || !formData.M08_M11_ProductID} onClick={add_product} >{isLoading ? 'adding...' : 'Add'}</Button>
                                    :
                                    <Button disabled={isLoading || !formData.M08_M11_ProductID} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}


                        </div>
                    </AdminModalFooter>
                </>
            </AdminModalContent>
        </AdminModal>
    )
}
