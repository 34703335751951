import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet-async';

const ReturnRefundPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-50 min-h-screen ">
            <Helmet>
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            <div className="md:container mx-auto py-12 px-6 lg:px-8">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden relative">
                    <div className="flex">
                        <div className="z-0 lg:w-1/2">
                            <div
                                className="inset-0 absolute bg-black bg-no-repeat bg-cover"
                                style={{
                                    backgroundImage: 'url(/assets/return_policy_image.jpg)',
                                    backgroundAttachment: 'fixed'
                                }}
                            ></div>
                        </div>
                        <div className="w-full p-8 z-10 bg-white/50 lg:w-1/2 backdrop-blur-sm">
                            <h1 className="text-4xl font-bold text-gray-800 mb-4">
                                Return and Refund Policy
                            </h1>
                            <p className="text-gray-900 mb-6">
                                We are committed to ensuring your satisfaction with Mariot Kitchen Equipment. If you are not completely satisfied with your purchase, we offer a convenient return and replacement policy within the GCC. Please review the following terms and conditions:
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">1. Eligibility</h2>
                            <p className="text-gray-900 mb-4">
                                You may return or replace a product within 14 days from the date of delivery, provided it meets the following criteria:
                                <ul className="list-disc ml-6">
                                    <li>All regularly stocked items, except for consumable products, can be returned within 14 days of receipt.</li>
                                    <li>The item must be in unused condition and in its original packaging.</li>
                                    <li>If you do not have your purchase receipt, exchange will not be possible.</li>
                                    <li>Repairs of the products damaged due to wear & tear will be entertained at management discretion within 6 months of the purchase.</li>
                                    <li>The management has the right to assess the age & condition of the returned goods prior to offering a repair or exchange.</li>
                                    <li>Upon request, and at our discretion, we may exchange defective/faulty items for identical items of equivalent value.</li>
                                    <li>CCTV cameras are in operation on these premises for your security & personal safety.</li>
                                    <li>The management reserves the right to amend this policy without prior notice.</li>
                                </ul>
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">2. Refund Process</h2>
                            <p className="text-gray-900 mb-4">
                                Depending on your original payment method:
                                <ul className="list-disc ml-6">
                                    <li>If you paid by Credit/Debit card, we will process the refund by crediting your account within 7-14 working days.</li>
                                    <li>If you chose Cash on Delivery, a bank wire transfer will be initiated to the individual who placed the order within 7-14 working days.</li>
                                    <li>For orders paid via Bank Wire, the refund will be arranged via bank wire transfer within 7-14 working days.</li>
                                </ul>
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">3. Return & Replacement Conditions</h2>
                            <p className="text-gray-900 mb-4">
                                Please note the following conditions for returns and replacements:
                                <ul className="list-disc ml-6">
                                    <li>The item must be in an unused condition.</li>
                                    <li>It must be returned in the original packaging.</li>
                                </ul>
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">4. Exchange and Refund Policy</h2>
                            <p className="text-gray-900 mb-4">
                                The consumer has the right to refund or replace the product only if it is defective:
                                <ul className="list-disc ml-6">
                                    <li>whithin a period 5 days</li>
                                    <li>The products must be unused with all original packaging material.</li>
                                    <li>The products should be accompaniedby the original Sale invoice.</li>
                                </ul>
                                The Return and Exchange not applicable
                                <ul className="list-disc ml-6">
                                    <li>You do not desire the product</li>
                                    <li>All Stainless-steel fabrication made by our factory </li>
                                </ul>
                            </p>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">5. Contact Us</h2>
                            <p className="text-gray-900 mb-4">
                                If you have any questions or need to return an item, please contact our Customer Service:
                                <ul className="list-disc ml-6">
                                    <li>Call or WhatsApp: +971 56 918 6717</li>
                                    <li>Email:
                                        <a href="mailto:admin@chefssouq.com" className="text-blue-500 hover:underline ml-1">admin@chefssouq.com</a>,
                                        <a href="mailto:support@chefssouq.com" className="text-blue-500 hover:underline ml-1">support@chefssouq.com</a>
                                    </li>
                                </ul>
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Shipping</h2>
                            <p className="text-gray-900 mb-4">
                                chefssouq Group offers free shipping for certain products within all emirates of UAE. Shipping for heavy-duty items may incur a charge of up to AED 150. Collection from our branches is free of cost.
                            </p>

                            <p className="text-gray-900">
                                Refunds will be processed using the original method of payment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { ReturnRefundPolicy };
