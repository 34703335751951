import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartItems, useCartApi } from "src/hooks/cart_api";
import { useConfiguration } from "src/hooks/configuration_hook";
import { useRESTapi } from "src/hooks/rest_API";
import { removeFromCart } from "src/redux/cart/cart_slice";
import { RootState } from "src/redux/store";
import { CustomFile, ProductItem, ProductVariation, ProductVariationOption } from "src/types";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { CustomizationOption, CustomizationOptionStored } from "src/types/local_storage";
import { StandardCartCard } from "../../components/cartCard";
import { useDeliveryChargeCalculation } from "@/src/hooks/delivery_charge_ calculation";
import { useAlertModal } from "@/src/hooks/alert_modal";
import { Button } from "@/src/components/ui/button";
import { useTaxCalculation } from "@/src/hooks/tax_ calculation";
import { Helmet } from "react-helmet-async";

type cartListItem = {
    variations: ({
        variation_name: string,
        variation_id: string,
        option: ({
            option_id: string,
            option_name: string
        })
    })[],
    price: number,
    savePrice: number,       //MRP- list price
    quantity: number,
    product_name: string,
    description: string,
    product_id: string,
    image: string,
    isActive: 1 | 0,
    customizationOption?: CustomizationOptionStored[],
    availableQuantity: number,
    isCustomizationAvailable: number,
    loading: boolean
}


export function CartPage() {


    const [cart_product_list, set_cart_product_list] = useState<cartListItem[]>([])
    const [update_ui, set_update_ui] = useState(false)
    const [is_loading_cart, set_isLoading_cart] = useState(false)
    const { load_cart_from_cart, remove_from_Cart, update_to_Cart, load_original_cart } = useCartApi()
    const cartItems = useSelector((state: RootState) => state.cart.cart)
    const currency = useSelector((state: RootState) => state.configuration.currencyDetails)
    const { customerData: customer, customerLoggedIn } = useSelector((state: RootState) => state.customer)
    const deliveryConfig = useDeliveryChargeCalculation()
    const taxConfig = useTaxCalculation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const alertModal = useAlertModal()
    const [totalPrices, setTotalPrices] = useState({
        originalPrice: 0,
        Savings: 0,
        DeliveryCharge: 40,
        tax: NaN,
    })
    const {
        show_category_tab,
        hide_category_tab,
        set_category_tab_theme
    } = useConfiguration()
    const alsoBaughtProducts = [
        {
            imageUrl: "https://m.media-amazon.com/images/I/61kq93AECHL._AC_UF1000,1000_QL80_.jpg",
            title: "Apple AirPods",
            price: "$95.00",
            description: "With plenty of talk and listen time, voice-activated Siri access, and an available wireless charging case.",
            newproduct: true
        },
        {
            imageUrl: "https://m.media-amazon.com/images/I/61kq93AECHL._AC_UF1000,1000_QL80_.jpg",
            title: "Apple AirPods",
            price: "$95.00",
            description: "With plenty of talk and listen time, voice-activated Siri access, and an available wireless charging case."
        },
        {
            imageUrl: "https://m.media-amazon.com/images/I/61kq93AECHL._AC_UF1000,1000_QL80_.jpg",
            title: "Apple AirPods",
            price: "$95.00",
            description: "With plenty of talk and listen time, voice-activated Siri access, and an available wireless charging case."
        }
    ]
    const [loadingTax, setLoadingTax] = useState(false)



    const updateUi = () => set_update_ui(update_ui!)

    const calculateTotals = async (cart_product_list: cartListItem[]) => {
        // calculating tax
        setLoadingTax(true)
        console.log(cart_product_list)
        totalPrices.originalPrice = 0
        totalPrices.Savings = 0
        cart_product_list.forEach(
            item => {
                if (item.isActive == 1) {
                    totalPrices.originalPrice += item.price * item.quantity
                    totalPrices.Savings += item.savePrice * item.quantity
                }
            }
        )
        // DELIVERY CHARGE CALCULATION
        const res2 = await deliveryConfig.calculateDeliveryCharge({
            grossAmount: totalPrices.originalPrice,
        })

        if (res2.success === true) {
            totalPrices.DeliveryCharge = res2.data!.delivery_charge
        } else {
            alertModal.open_alert_modal({
                content: res2.message || 'Error calculating delivery charge',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }

        // TAX CHARGE CALCULATION
        const res = await taxConfig.calculateTax({
            grossAmount: totalPrices.originalPrice,
            netAmount: totalPrices.DeliveryCharge + totalPrices.originalPrice
        })
        if (res.success === true) {
            totalPrices.tax = res.data!.tax_amount
        } else {
            alertModal.open_alert_modal({
                content: res.message || 'Error loading tax',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }
        setTotalPrices(totalPrices)
        setLoadingTax(false)
        setTotalPrices({ ...totalPrices })
    }

    const updateCart = async ({ productId, newQuantity, isActive }: { productId: string | number, newQuantity: number, isActive: 1 | 0 }) => {

        const indexUpdate = cart_product_list.findIndex(
            item => item.product_id == productId
        )


        if (indexUpdate >= 0) {
            cart_product_list[indexUpdate].loading = true;
            set_cart_product_list([...cart_product_list])
            if (newQuantity === 0) {
                const res = await remove_from_Cart({ productItemId: String(productId), customerId: String(customer.M01_CustomerID) })
                if (res === true) {
                    cart_product_list.splice(indexUpdate, 1)
                }
            } else {

                if (newQuantity <= cart_product_list[indexUpdate].availableQuantity || cart_product_list[indexUpdate].isCustomizationAvailable == 1) {
                    const res = await update_to_Cart({
                        customerId: String(customer.M01_CustomerID),
                        productItemId: String(productId),
                        quantity: newQuantity,
                        isActive: isActive
                    })
                    if (res === true) {
                        cart_product_list[indexUpdate].quantity = newQuantity
                        cart_product_list[indexUpdate].isActive = isActive
                    }
                }
                cart_product_list[indexUpdate].loading = false
            }
            // calculating total
            calculateTotals(cart_product_list)
            set_cart_product_list([...cart_product_list])
        } else {
            alert('product quantity not increased')
        }

    }

    const proceedToBy = () => {

        let isAllProductAvailable = true;

        cart_product_list.forEach(
            item => {
                if ((item.availableQuantity === 0 || item.availableQuantity === null) && item.isCustomizationAvailable === 0) {
                    isAllProductAvailable = false
                }
            }
        )

        if (isAllProductAvailable) {
            navigate('/checkout-from-cart')
        } else {
            alertModal.open_alert_modal({
                content: 'Some product is not available',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Product not available'
            })
        }


    }


    useEffect(
        () => {
            window.scrollTo({ top: 0 })
            set_category_tab_theme({ theme: 'colorful' })

            //load cart login
            switch (customerLoggedIn) {
                case "1":
                    set_isLoading_cart(true)
                    load_original_cart().then(
                        res => {

                            if (res) {
                                const cartItemOg: cartListItem[] = [];

                                res.forEach(
                                    itemOnline => {
                                        const cartItemList: cartListItem = {
                                            description: itemOnline.product_item.M08_Description,
                                            image: itemOnline.product_item.M08_ProductImage,
                                            price: itemOnline.originalPrice,
                                            product_id: String(itemOnline.C02_M08_product_item_ID),
                                            product_name: itemOnline.product_item.M08_ProductItemName,
                                            quantity: itemOnline.C02_quantity,
                                            savePrice: Number(itemOnline.product_item.price?.M10_MRP_price) - Number(itemOnline.product_item.price?.M10_List_price),
                                            variations: itemOnline.product_item.variations ? itemOnline.product_item.variations.map(
                                                variation => ({
                                                    variation_id: String(variation.M06_VariationID),
                                                    variation_name: variation.M06_Name,
                                                    option: {
                                                        option_id: String(variation.variation_option.M07_VariationOptionID),
                                                        option_name: variation.variation_option.M07_Value
                                                    },

                                                })
                                            ) : [],
                                            isActive: itemOnline.C02_is_active,
                                            availableQuantity: itemOnline.product_item.customer_stock_quantity,
                                            customizationOption: itemOnline.customizations.map(item => ({
                                                baseRate: item.M23_baseRate,
                                                hikePerc: item.M23_hikePerc ? Number(item.M23_hikePerc) : null,
                                                hikePercInRate: item.M23_hikePercInRate ? Number(item.M23_hikePercInRate) : null,
                                                id: item.M23_cart_customization_id ? String(item.M23_cart_customization_id) : '',
                                                Inputvalue: item.M23_inputValue ? Number(item.M23_inputValue) : NaN,
                                                name: item.M23_customization_name,
                                                isSlab: item.M23_isSlab ? Boolean(item.M23_isSlab) : null,
                                                maxVal: item.M23_maxVal ? Number(item.M23_maxVal) : null,
                                                minVal: item.M23_minVal ? Number(item.M23_minVal) : null,
                                                optionId: item.M23_M22_customization_option_id ? String(item.M23_M22_customization_option_id) : '',
                                                order: item.M23_order,
                                                rate: item.M23_rate ? Number(item.M23_rate) : null,
                                                type: item.M23_type,
                                                value: item.M23_value,
                                                varientID: item.M23_varientID,
                                                isDefault: null,

                                            } satisfies CustomizationOptionStored)),
                                            isCustomizationAvailable: itemOnline.product_item.M08_is_customization_available,
                                            loading: false
                                        }

                                        cartItemOg.push(cartItemList)
                                    }
                                )

                                //calculating total prices
                                calculateTotals(cartItemOg)
                                set_cart_product_list(cartItemOg)
                                set_isLoading_cart(false)

                            } else {
                                set_cart_product_list([])
                            }
                        }
                    )
                    break;
                case "0":
                    set_isLoading_cart(true)
                    load_cart_from_cart()
                        .then(res => {
                            if (res) {

                                const cartlist: cartListItem[] = []
                                console.log(res)
                                res.forEach(
                                    item => {
                                        const images = item.M08_ProductImage as string[]
                                        if (item.price) {
                                            const cartItem: cartListItem = {
                                                description: item.M08_Description!,
                                                price: item.originalPrice,
                                                product_id: String(item.M08_ProductItemID),
                                                product_name: item.M08_ProductItemName,
                                                quantity: item.C02_quantity,
                                                isActive: item.C02_is_active,
                                                savePrice: item.price !== undefined ? (Number(item.price?.M10_MRP_price) - Number(item.price?.M10_List_price)) : NaN,
                                                variations: item.variations
                                                    ?
                                                    item.variations.map(
                                                        variationitems => {
                                                            return {
                                                                variation_name: variationitems.M06_Name,
                                                                variation_id: String(variationitems.M06_VariationID),
                                                                option: variationitems.variation_option
                                                                    ?
                                                                    {
                                                                        option_id: String(variationitems.variation_option.M07_VariationOptionID),
                                                                        option_name: variationitems.variation_option.M07_Value
                                                                    }
                                                                    :
                                                                    {
                                                                        option_id: 'null',
                                                                        option_name: 'undifined'
                                                                    }
                                                            }
                                                        }
                                                    )
                                                    : [],
                                                image: item.M08_ProductImage as string,
                                                customizationOption: item.customizationOption,
                                                availableQuantity: item.customer_stock_quantity,
                                                isCustomizationAvailable: item.M08_is_customization_available,
                                                loading: false
                                            }
                                            cartlist.push(cartItem)
                                        }
                                    }
                                )
                                //calculating total prices
                                calculateTotals(cartlist)
                                set_cart_product_list(cartlist)
                                console.log(cartlist)
                                set_isLoading_cart(false)

                            } else {
                                set_cart_product_list([])
                                set_isLoading_cart(false)
                            }
                        })
                        .catch(err => console.log(err))
            }



        }, [cartItems.length, customerLoggedIn]
    )

    return (
        <div className=' w-full'>
            <section className="bg-blue-gray-100 py-8 antialiased dark:bg-gray-900 md:py-16">
                <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Shopping Cart</h2>
                    <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
                        <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
                            <div className="space-y-6">
                                {
                                    is_loading_cart
                                        ?
                                        Array(4).fill(4).map(
                                            res => (
                                                <CartPageProductSkeleton />
                                            )
                                        )
                                        :
                                        cart_product_list.length === 0
                                            ?
                                            <div className="flex flex-col items-center justify-center h-full text-center text-gray-700 py-28">
                                                <div className="mb-4">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-16 h-16 text-gray-500"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M3 3h18M9 3v18m6-18v18m6-6H3"
                                                        />
                                                    </svg>

                                                </div>
                                                <h1 className="text-2xl font-semibold mb-2">No Products Found in Cart</h1>
                                                <p className="text-gray-500">We couldn't find any products matching your search.</p>
                                            </div>
                                            :
                                            cart_product_list.map(
                                                (product, index) => {
                                                    return (
                                                        <>
                                                            {
                                                                product.loading === true
                                                                    ?
                                                                    <CartPageProductSkeleton />
                                                                    :

                                                                    <StandardCartCard
                                                                        checked={product.isActive == 0 ? false : true}
                                                                        image={`${process.env.REACT_APP_BACKEND_URL}/${product.image}`}
                                                                        onDicreaseCount={

                                                                            () => updateCart({
                                                                                productId: product.product_id,
                                                                                newQuantity: product.quantity - 1,
                                                                                isActive: product.isActive
                                                                            })

                                                                        }
                                                                        onIncreaseCount={
                                                                            () => updateCart({
                                                                                productId: product.product_id,
                                                                                newQuantity: product.quantity + 1,
                                                                                isActive: product.isActive
                                                                            })}
                                                                        onSelect={e => {
                                                                            updateCart({
                                                                                isActive: e.target.checked ? 1 : 0,
                                                                                productId: product.product_id,
                                                                                newQuantity: product.quantity
                                                                            })
                                                                        }}
                                                                        price={product.price}
                                                                        savePrice={product.savePrice}
                                                                        productName={product.product_name}
                                                                        product_id={product.product_id}
                                                                        quantity={product.quantity}
                                                                        removeFromCart={() => {
                                                                            remove_from_Cart({
                                                                                customerId: String(customer.M01_CustomerID),
                                                                                productItemId: String(product.product_id)
                                                                            })
                                                                        }}
                                                                        variations={product.variations}
                                                                        customizationOption={product.customizationOption}
                                                                        availableQuantity={product.availableQuantity ? product.availableQuantity : 0}
                                                                        isCustomizationAvailable={product.isCustomizationAvailable}
                                                                    />
                                                            }
                                                        </>
                                                    )
                                                }
                                            )
                                }
                            </div>
                            {/* <div className="hidden xl:mt-8 xl:block">
                                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">People also bought</h3>
                                <div className="mt-6 grid grid-cols-3 gap-4 sm:mt-8">
                                    {
                                        alsoBaughtProducts.map(
                                            (product, index) => (
                                                <div key={index} className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-[280px]" >
                                                    <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl h-56 min-w-56">
                                                        <img
                                                            src={product.imageUrl}
                                                            alt="card-image"
                                                            className="object-cover w-full h-full"
                                                        />
                                                    </div>
                                                    <div className="p-6">
                                                        <div className="flex items-center justify-between mb-2">
                                                            <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                                                                {product.title}
                                                                {product.newproduct && (
                                                                    <span className='bg-[#c0292938] p-[2px] font-bold rounded-[4px] text-[#c62626] text-[12px] pl-[5px] pr-[6px] ml-1'>New</span>
                                                                )}
                                                            </p>
                                                            <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                                                                {product.price}
                                                            </p>
                                                        </div>
                                                        <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75">
                                                            {product.description}
                                                        </p>
                                                    </div>
                                                    <div className="p-6 pt-0">
                                                        <button
                                                            className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                                            type="button"
                                                        >
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </div> */}
                        </div>
                        {
                            is_loading_cart || loadingTax
                                ?
                                <OrderSummarySkeleton />
                                :

                                cart_product_list.length !== 0 &&
                                <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                                    <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                                        <p className="text-xl font-semibold text-gray-900 dark:text-white">Order summary</p>
                                        <div className="space-y-4">
                                            <div className="space-y-2">
                                                <dl className="flex items-center justify-between gap-4">
                                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Original price</dt>
                                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.originalPrice + totalPrices.Savings}</dd>
                                                </dl>
                                                <dl className="flex items-center justify-between gap-4">
                                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Savings</dt>
                                                    <dd className="text-base font-medium text-green-600">-{totalPrices.Savings}</dd>
                                                </dl>
                                                <dl className="flex items-center justify-between gap-4">
                                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Store Pickup</dt>
                                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.DeliveryCharge}</dd>
                                                </dl>
                                                <dl className="flex items-center justify-between gap-4">
                                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Tax</dt>
                                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.tax}</dd>
                                                </dl>
                                                {/* <dl className="flex items-center justify-between gap-4">
                                                <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Tax</dt>
                                                <dd className="text-base font-medium text-gray-900 dark:text-white">$799</dd>
                                            </dl> */}
                                            </div>
                                            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                                                <dt className="text-base font-bold text-gray-900 dark:text-white">Total</dt>
                                                <dd className="text-base font-bold text-gray-900 dark:text-white"><span className=" font-light text-xs">{currency.M05_CurrencySymbol}</span>{totalPrices.DeliveryCharge + totalPrices.originalPrice + totalPrices.tax}</dd>
                                            </dl>
                                        </div>
                                        <button onClick={proceedToBy} className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Proceed to Buy</button>
                                        <div className="flex items-center justify-center gap-2">
                                            <span className="text-sm font-normal text-gray-500 dark:text-gray-400"> or </span>
                                            <div onClick={() => navigate('/')} className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
                                                Continue Shopping
                                                <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 12H5m14 0-4 4m4-4-4-4" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </section>
            <Footer />

        </div>

    )
}



const CartPageProductSkeleton: React.FC = () => {
    return (
        <div className="animate-pulse space-y-6">
            <Helmet>
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6">
                <div className="mb-3 w-5 h-5 bg-gray-300 dark:bg-gray-700"></div>
                <div className="flex justify-between gap-6">
                    <div className="shrink-0">
                        <div className="h-20 w-20 bg-gray-300 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex-1 space-y-4">
                        <div className="h-4 bg-gray-300 rounded dark:bg-gray-700"></div>
                        <div className="h-4 bg-gray-300 rounded dark:bg-gray-700 w-3/4"></div>
                        <div className="flex gap-2">
                            <div className="h-4 bg-gray-300 rounded dark:bg-gray-700 w-1/4"></div>
                            <div className="h-4 bg-gray-300 rounded dark:bg-gray-700 w-1/4"></div>
                        </div>
                        <div className="h-4 bg-gray-300 rounded dark:bg-gray-700 w-1/2"></div>
                    </div>
                    <div className="text-end">
                        <div className="h-4 bg-gray-300 rounded dark:bg-gray-700 w-16"></div>
                    </div>
                    <div className="flex items-center">
                        <div className="inline-flex h-5 w-5 bg-gray-300 rounded-md dark:bg-gray-700"></div>
                        <div className="mx-2 h-4 bg-gray-300 rounded dark:bg-gray-700 w-10"></div>
                        <div className="inline-flex h-5 w-5 bg-gray-300 rounded-md dark:bg-gray-700"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const OrderSummarySkeleton: React.FC = () => {
    return (
        <div className="animate-pulse mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                <p className="h-6 w-1/3 bg-gray-300 rounded dark:bg-gray-700"></p>
                <div className="space-y-4">
                    <div className="space-y-2">
                        <div className="flex items-center justify-between gap-4">
                            <div className="h-4 w-1/3 bg-gray-300 rounded dark:bg-gray-700"></div>
                            <div className="h-4 w-1/6 bg-gray-300 rounded dark:bg-gray-700"></div>
                        </div>
                        <div className="flex items-center justify-between gap-4">
                            <div className="h-4 w-1/3 bg-gray-300 rounded dark:bg-gray-700"></div>
                            <div className="h-4 w-1/6 bg-gray-300 rounded dark:bg-gray-700"></div>
                        </div>
                        <div className="flex items-center justify-between gap-4">
                            <div className="h-4 w-1/3 bg-gray-300 rounded dark:bg-gray-700"></div>
                            <div className="h-4 w-1/6 bg-gray-300 rounded dark:bg-gray-700"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                        <div className="h-4 w-1/4 bg-gray-300 rounded dark:bg-gray-700"></div>
                        <div className="h-4 w-1/6 bg-gray-300 rounded dark:bg-gray-700"></div>
                    </div>
                </div>
                <div className="h-10 w-full bg-gray-300 rounded dark:bg-gray-700"></div>
                <div className="flex items-center justify-center gap-2">
                    <span className="h-4 w-10 bg-gray-300 rounded dark:bg-gray-700"></span>
                    <div className="h-5 w-20 bg-gray-300 rounded dark:bg-gray-700"></div>
                </div>
            </div>
        </div>
    );
};

