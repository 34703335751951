export  const useCheckUserType = () => {

    const SEOs = [
        '110000'
    ]

    const isSEOs = (userid: string|number) => {
        return SEOs.some((v) => v == userid)
    }

    return {
        isSEOs
    }
}