import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CartLocalstorateType, localStorageKeys } from '../../types/local_storage'



const initialState: {
  cart: CartLocalstorateType[]
} = {
  cart: []
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    initcartRedux: (state, action: PayloadAction<{
      mode: 'loggedIn' | 'notLoggedIn'
    }>) => {
      if (action.payload.mode === 'notLoggedIn') {
        try {

          const stringcart = localStorage.getItem(localStorageKeys.customerCart)

          if (stringcart) {
            state.cart = JSON.parse(stringcart) as CartLocalstorateType[]
          }

        } catch (error) {
          console.log('error')
        }
      }

    },
    addToCart: (state, action: PayloadAction<CartLocalstorateType & { mode: 'loggedIn' | 'notLoggedIn' }>) => {
      const findIndex = state.cart.findIndex(
        item => item.productItemId == action.payload.productItemId
      )
      if (findIndex < 0) {
        state.cart.push({
          productItemId: action.payload.productItemId,
          quantity: action.payload.quantity,
          isActive: action.payload.isActive,
          customizationOption: action.payload.customizationOption
        })
        state.cart = [...state.cart]
      } else {
        state.cart[findIndex] = {
          productItemId: action.payload.productItemId,
          quantity: action.payload.quantity,
          isActive: action.payload.isActive,
          customizationOption: undefined
        }
        state.cart = [...state.cart]
      }
      if (action.payload.mode === 'notLoggedIn') {
        localStorage.setItem(localStorageKeys.customerCart, JSON.stringify(state.cart))
      }
    },
    removeFromCart: (state, action: PayloadAction<{
      productItemId: String,
      mode: 'loggedIn' | 'notLoggedIn'
    }>) => {    //product item id

      const newCart: CartLocalstorateType[] = [];
      state.cart.forEach(
        item => {
          if (item.productItemId != action.payload.productItemId) {
            newCart.push({
              productItemId: item.productItemId,
              quantity: item.quantity,
              isActive: item.isActive,
              customizationOption: item.customizationOption

            })
          }
        }
      )
      state.cart = [...newCart]
      if (action.payload.mode === 'notLoggedIn') {
        localStorage.setItem(localStorageKeys.customerCart, JSON.stringify(newCart))
      }
      return;
    },
    //clear cart
    clearCart: (state, action: PayloadAction<{
      mode: 'loggedIn' | 'notLoggedIn'
    }>) => {

      if (action.payload.mode === 'notLoggedIn') {
        localStorage.setItem(localStorageKeys.customerCart, JSON.stringify([]))
      }
      state.cart = []

    },

    //update to cart
    updateToCart: (state, action: PayloadAction<{
      mode: 'loggedIn' | 'notLoggedIn',
      productItemId: string,
      quantity: number,
      isActive: 1 | 0
    }>) => {

      const updateIndex = state.cart.findIndex(
        item => item.productItemId == action.payload.productItemId
      )

      if (updateIndex < 0)
        return;

      const newCart = state.cart
      newCart[updateIndex].quantity = action.payload.quantity
      newCart[updateIndex].isActive = action.payload.isActive
      if (action.payload.mode === 'notLoggedIn') {
        localStorage.setItem(localStorageKeys.customerCart, JSON.stringify(newCart))
      }
      state.cart = newCart

    },
    //store the data to cart
    //update to cart
    setCart: (state, action: PayloadAction<{
      mode: 'loggedIn' | 'notLoggedIn',
      cartItems: CartLocalstorateType[]
    }>) => {

      state.cart = action.payload.cartItems

      if (action.payload.mode === 'notLoggedIn') {
        localStorage.setItem(localStorageKeys.customerCart, JSON.stringify(action.payload.cartItems))
      }

    }


  },
})

// Action creators are generated for each case reducer function
export const {
  addToCart,
  removeFromCart,
  initcartRedux,
  clearCart,
  updateToCart,
  setCart
} = cartSlice.actions

export const cartSliceReducer = cartSlice.reducer