import { ButtonIcon } from '@/src/components/buttons/buttonIcon';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import { productOverview, VariationItem, variationOptionItem } from '../addmodal';
import { OptionAddModal } from './components/optionAddModal';
import { OptionDeleteModal } from './components/optionDeleteModal';
import OptionEditModal from './components/optionModal';
import { VariationAddModal } from './components/variationAddModal';
import { VariationDeleteModal } from './components/variationDeleteModal';
import VariationEditModal from './components/variationModal';

type Props = {
    variationObject: VariationItem[],
    setvariationObject: (objects: VariationItem[]) => any,
    disabled: boolean,
    mode: 'add' | 'edit',
    reload_product: () => any,
    productDetails: productOverview
}

export type edit_variation_props = {
    variation?: VariationItem,
    show: boolean,
    showDeleteModal: boolean
}
export type edit_option_props = {
    option?: variationOptionItem,
    show: boolean,
    showDeleteModal: boolean
}

export type variation_add_modal_props = {
    show: boolean,
    variation_name: string,
    productId: string,
    option_name: string,
}
export type option_add_modal_props = {
    show: boolean,
    variation_id:string
    productId:string,
}

const VariationTable: React.FC<Props> = (props) => {
    const [newVariation, setNewVariation] = useState<string>('');
    const [newOption, setnewOption] = useState<string>('');
    const [edit_variation_modal_config, set_edit_variation_modal_config] = useState<edit_variation_props>({
        show: false,
        variation: undefined,
        showDeleteModal: false
    })
    const [edit_option_modal_config, set_edit_option_modal_config] = useState<edit_option_props>({
        show: false,
        option: undefined,
        showDeleteModal: false,
    })

    const [add_variation_config, set_add_variation_config] = useState<variation_add_modal_props>({
        show: false,
        option_name: '',
        productId: '',
        variation_name: ''
    })
    const [add_option_config, set_add_option_config] = useState<option_add_modal_props>({
        show: false,
        variation_id:'',
        productId:''
    })

    const addVariation = (): void => {
        if (newVariation.trim() !== '' && newOption.trim() !== '') {

            switch (props.mode) {
                case 'add': {
                    const index = props.variationObject.findIndex(variation => variation.variation == newVariation)

                    if (index < 0) {
                        const newItem: VariationItem = {
                            id: Date.now(),
                            variation: newVariation,
                            options: [{ id: Date.now(), name: newOption, error: undefined }],
                        };
                        props.setvariationObject([...props.variationObject, newItem]);
                        setNewVariation('');
                        setnewOption('')
                    }
                    break;
                }

                case 'edit': {

                    set_add_variation_config({
                        show: true,
                        option_name: newOption,
                        productId: String(props.productDetails.M11_ProductID),
                        variation_name: newVariation
                    })
                    

                    break;
                }

                default:
                    break;
            }
        }
    };

    const deleteVariation = (variation: VariationItem): void => {
        switch (props.mode) {
            case 'add':
                props.setvariationObject(props.variationObject.filter(item => item.id !== variation.id));
                break;

            case 'edit':

                set_edit_variation_modal_config({
                    show: false,
                    showDeleteModal: true,
                    variation: variation
                })

                break;

            default:
                break;
        }
    };

    const updateVariation = (id: string | number, value: string): void => {
        props.setvariationObject(props.variationObject.map(item =>
            item.id === id ? { ...item, variation: value, error: undefined } : item
        ));
    };

    const addOption = (variation:VariationItem): void => {
        switch (props.mode) {
            case 'add':
                props.setvariationObject(props.variationObject.map(item => {
                    if (item.id === variation.id) {
                        return {
                            ...item,
                            options: [...item.options, { id: Date.now(), name: '', error: undefined }]
                        };
                    }
                    return item;
                }));
                break;

            case 'edit':
                set_add_option_config({
                    productId:String(props.productDetails.M11_ProductID),
                    show:true,
                    variation_id:String(variation.id)
                })
                break;
        
            default:
                break;
        }
    };

    const updateOption = (variationId: string | number, optionId: string | number, value: string): void => {
        props.setvariationObject(props.variationObject.map(item => {
            if (item.id === variationId) {
                return {
                    ...item,
                    options: item.options.map(option =>
                        option.id === optionId ? { ...option, name: value, error: undefined } : option
                    )
                };
            }
            return item;
        }));
    };

    const deleteOption = (variationId: string | number, option: variationOptionItem): void => {
        switch (props.mode) {
            case 'add':
                const updatedVariations = props.variationObject.map(item => {
                    if (item.id === variationId) {
                        const updatedOptions = item.options.filter(option2 => option2.id !== option.id);
                        return {
                            ...item,
                            options: updatedOptions
                        };
                    }
                    return item;
                });

                // Remove variation if it has no options
                const finalVariations = updatedVariations.filter(item => item.options.length > 0);

                props.setvariationObject(finalVariations);
                break;

            case 'edit':
                set_edit_option_modal_config({
                    show: false,
                    showDeleteModal: true,
                    option: option
                })
                break;

            default:
                break;
        }
    };





    return (
        <div>
            {/* VARIATION EDIT MODAL */}
            <VariationEditModal
                edit_variation_modal_config={edit_variation_modal_config}
                set_edit_variation_modal_config={set_edit_variation_modal_config}
                reload_product={props.reload_product}
            />
            {/* OPTION EIDIT MODAL */}
            <OptionEditModal
                edit_option_modal_config={edit_option_modal_config}
                set_edit_option_modal_config={set_edit_option_modal_config}
                reload_product={props.reload_product}
            />
            {/* VARIATION DELETE MODAL */}
            <VariationDeleteModal
                delete_variation_modal_config={edit_variation_modal_config}
                reload_product={props.reload_product}
                set_delete_variation_modal_config={set_edit_variation_modal_config}
            />
            {/* OPTION DELETE MODAL */}
            <OptionDeleteModal
                edit_option_modal_config={edit_option_modal_config}
                set_edit_option_modal_config={set_edit_option_modal_config}
                reload_product={props.reload_product}
            />
            {/* VARIATION ADD MODAL */}
            <VariationAddModal
                add_variation_config={add_variation_config}
                set_add_variation_config={set_add_variation_config}
                reload_product={props.reload_product}
                clearInput={()=>{
                    setnewOption('')
                    setNewVariation('')
                }}
            />
            {/* OPTION ADD MODAL */}
            <OptionAddModal
                add_option_config={add_option_config}
                set_add_option_config={set_add_option_config}
                reload_product={props.reload_product}
            />
            <p>Variations (optional)</p>
            <div className="p-4">
                <div className='border rounded-md overflow-auto'>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Variation</TableHead>
                                <TableHead>Options</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                props.variationObject.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={3} className=' text-center italic'>No Variations</TableCell>
                                </TableRow>
                            }
                            {props.variationObject.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell className=' align-top'>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex gap-1">
                                                <Input
                                                    type="text"
                                                    value={item.variation}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        updateVariation(item.id, e.target.value)
                                                    }
                                                    className={`${item.error ? 'border-red-500' : ''} min-w-40`}
                                                    disabled={props.disabled || props.mode === 'edit'}
                                                />
                                                {props.mode === 'edit' && <ButtonIcon
                                                    onClick={() => set_edit_variation_modal_config({
                                                        show: true,
                                                        variation: item,
                                                        showDeleteModal: false
                                                    })}
                                                    disabled={props.disabled}
                                                    toolTipText='Edit Variation'
                                                    icon={<IconPencil />}
                                                ></ButtonIcon>}
                                            </div>
                                            {item.error && <p className="text-red-500 text-sm mt-1">{item.error}</p>}
                                        </div>
                                    </TableCell>
                                    <TableCell className=' flex flex-col gap-2'>
                                        {item.options.map((option) => (
                                            <div>
                                                <div key={option.id} className="flex items-center gap-1 ">
                                                    <Input
                                                        type="text"
                                                        value={option.name}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            updateOption(item.id, option.id, e.target.value)
                                                        }
                                                        className={`${option.error ? 'border-red-500 mr-2' : ''} min-w-40`}
                                                        disabled={props.disabled || props.mode === 'edit'}
                                                    />
                                                    {props.mode === 'edit' && <ButtonIcon
                                                        onClick={() => set_edit_option_modal_config({
                                                            show: true,
                                                            option: option,
                                                            showDeleteModal: false
                                                        })}
                                                        disabled={props.disabled}
                                                        toolTipText='Edit option'
                                                        icon={<IconPencil />}
                                                    ></ButtonIcon>}
                                                    <ButtonIcon
                                                        onClick={() => deleteOption(item.id, option)}
                                                        disabled={props.disabled}
                                                        toolTipText='Delete option'
                                                        icon={<IconTrash />}
                                                    ></ButtonIcon>
                                                </div>
                                                {option.error && <p className="text-red-500 text-sm mt-2">{option.error}</p>}
                                            </div>
                                        ))}
                                        <Button
                                            size="sm"
                                            onClick={() => addOption(item)}
                                            disabled={props.disabled}
                                        >
                                            Add Option
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="destructive"
                                            size="sm"
                                            onClick={() => deleteVariation(item)}
                                            disabled={props.disabled}
                                        >
                                            Delete Variation
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div className="mt-4 flex gap-2">
                    <Input
                        type="text"
                        value={newVariation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewVariation(e.target.value)}
                        placeholder="New variation"
                        disabled={props.disabled}
                    />
                    <Input
                        type="text"
                        value={newOption}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewOption(e.target.value)}
                        placeholder="Default Option"
                        disabled={props.disabled}
                    />
                    <Button
                        onClick={addVariation}
                        disabled={props.disabled}
                    >
                        Add Variation
                    </Button>
                </div>
            </div>
        </div>
    );
};

export { VariationTable };
