import React, { useState } from 'react'
import { IconTool, IconTruckDelivery } from '@tabler/icons-react';
import { Github } from "lucide-react"
import {
    Cloud,
    CreditCard,
    Keyboard,
    LifeBuoy,
    LogOut,
    Mail,
    MessageSquare,
    Plus,
    PlusCircle,
    Settings,
    User,
    UserPlus,
    CircleUser,
    Users,
} from "lucide-react"

import { Button } from "../../../../components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"

import { IconLogin, IconUserCircle } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { useCustomerLogin } from 'src/hooks/user_login'

export default function DropdownShadecn() {
    const { customerLoggedIn, customerData } = useSelector((state: RootState) => state.customer)
    const navigate = useNavigate()
    const { logoutUser } = useCustomerLogin()
    const [open, setOpen] = useState(false)

    const onClickLogout = async () => {
        await logoutUser();
        navigate('/')
    }
    return (
        <div>
            <DropdownMenu open={open} onOpenChange={setOpen}  >
                <DropdownMenuTrigger asChild>

                    <IconUserCircle size={28} className='cursor-pointer' />


                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 z-[50000000000] bg-white">
                    {
                        customerLoggedIn === '1' ?
                            <>
                                {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
                                <DropdownMenuLabel>
                                    <div className=' w-full '>
                                        <p className='text-black'>{customerData.email}</p>
                                        <p className=' font-medium text-gray-400'>{customerData.M01_CustomerUsername}</p>
                                    </div>
                                </DropdownMenuLabel>

                                <DropdownMenuSeparator />
                                <DropdownMenuGroup >
                                    {/* <Link to={'/userprofile'} ><DropdownMenuItem className='cursor-pointer'>

                                        <User className="mr-2 h-4 w-4" />
                                        <span>Profile</span>
                                        <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>

                                    </DropdownMenuItem>
                                    </Link>
                                    <DropdownMenuItem>
                                        <CreditCard className="mr-2 h-4 w-4" />
                                        <span>Billing</span>
                                        <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
                                    </DropdownMenuItem> */}
                                    <Link to={'/profile'} className=' flex hover:bg-gray-400 cursor-pointer items-end p-1 px-2 rounded-md ' onClick={() => setOpen(false)} >
                                        <CircleUser className="mr-2 h-4 w-4" />
                                        <span>Profile</span>
                                        {/* <DropdownMenuShortcut>⌘S</DropdownMenuShortcut> */}
                                    </Link>
                                    {/* <DropdownMenuItem>
                                        <Keyboard className="mr-2 h-4 w-4" />
                                        <span>Keyboard shortcuts</span>
                                        <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
                                    </DropdownMenuItem> */}
                                </DropdownMenuGroup>

                                {/* dropdown action section  */}
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup >
                                    <Link to={'/orderPage'} className=' flex hover:bg-gray-400 cursor-pointer items-end p-1 px-2 rounded-md ' onClick={() => setOpen(false)} >
                                        {/* <CircleUser className="mr-2 h-4 w-4" /> */}
                                        <IconTruckDelivery className="mr-2 h-4 w-4" />
                                        <span>Orders</span>
                                        {/* <DropdownMenuShortcut>⌘S</DropdownMenuShortcut> */}
                                    </Link>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup >
                                    <Link to={'/services'} className=' flex hover:bg-gray-400 cursor-pointer items-end p-1 px-2 rounded-md ' onClick={() => setOpen(false)} >
                                        {/* <CircleUser className="mr-2 h-4 w-4" /> */}
                                        <IconTool className="mr-2 h-4 w-4" />
                                        <span>Servies</span>
                                        {/* <DropdownMenuShortcut>⌘S</DropdownMenuShortcut> */}
                                    </Link>
                                </DropdownMenuGroup>
                                {/* <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem>
                                        <Users className="mr-2 h-4 w-4" />
                                        <span>Team</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuSub>
                                        <DropdownMenuSubTrigger>
                                            <UserPlus className="mr-2 h-4 w-4" />
                                            <span>Invite users</span>
                                        </DropdownMenuSubTrigger>
                                        <DropdownMenuPortal>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>
                                                    <Mail className="mr-2 h-4 w-4" />
                                                    <span>Email</span>
                                                </DropdownMenuItem>
                                                <DropdownMenuItem>
                                                    <MessageSquare className="mr-2 h-4 w-4" />
                                                    <span>Message</span>
                                                </DropdownMenuItem>
                                                <DropdownMenuSeparator />
                                                <DropdownMenuItem>
                                                    <PlusCircle className="mr-2 h-4 w-4" />
                                                    <span>More...</span>
                                                </DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuPortal>
                                    </DropdownMenuSub>
                                    <DropdownMenuItem>
                                        <Plus className="mr-2 h-4 w-4" />
                                        <span>New Team</span>
                                        <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem>
                                    <Github className="mr-2 h-4 w-4" />
                                    <span>GitHub</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem>
                                    <LifeBuoy className="mr-2 h-4 w-4" />
                                    <span>Support</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem disabled>
                                    <Cloud className="mr-2 h-4 w-4" />
                                    <span>API</span>
                                </DropdownMenuItem> */}
                                <DropdownMenuSeparator />
                                {/* <DropdownMenuItem >
                                    <LogOut className="mr-2 h-4 w-4" />
                                    <span>Log out</span>
                                    <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
                                </DropdownMenuItem> */}
                                <div className=' flex hover:bg-gray-400 cursor-pointer items-end p-1 px-2 ' onClick={onClickLogout} >
                                    <LogOut className="mr-2 h-4 w-4" />
                                    <span>Log out</span>
                                    {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
                                </div>
                            </>
                            :
                            <>
                                <DropdownMenuGroup >
                                    <Link to={'/common-order-page'} className=' flex hover:bg-gray-400 cursor-pointer items-end p-1 px-2 rounded-md ' onClick={() => setOpen(false)} >
                                        {/* <CircleUser className="mr-2 h-4 w-4" /> */}
                                        <IconTruckDelivery className="mr-2 h-4 w-4" />
                                        <span>Track Orders</span>
                                        {/* <DropdownMenuShortcut>⌘S</DropdownMenuShortcut> */}
                                    </Link>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <div className=' flex flex-col gap-1'>
                                    <p>You not logged in</p>
                                    <button className=' bg-gray-700  p-2'
                                        onClick={() => navigate('/login')}
                                    >Login</button>
                                </div>
                            </>
                    }
                </DropdownMenuContent>
            </DropdownMenu>

        </div>
    )
}
