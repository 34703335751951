import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, clearCart, removeFromCart, setCart, updateToCart } from 'src/redux/cart/cart_slice'
import { CartLocalstorateType, CustomizationOption, CustomizationOptionKeys, CustomizationOptionStored, CustomizationOptionStoredKeys, customerLoggedInLocalStorageType, localStorageKeys } from 'src/types/local_storage'
import { useRESTapi } from './rest_API'
import { CartProductCustomizationOptionKeys, ProductCustomizationOptionCart, ProductCustomizationOptionKeys, ProductItem, ProductPrice, ProductVariation, ProductVariationOption, ShoppingCartItem, customizationOptionType } from 'src/types'
import { RootState } from 'src/redux/store'
import { useCustomizationApi } from './customizaton_api'
import { useAlertModal } from './alert_modal'

// cartVariationList = 

export type CartItems = ProductItem & ShoppingCartItem & {
    variations: ({
        variation_option: ProductVariationOption
    } & ProductVariation)[],
    price: ProductPrice,
    customizationOption?: CustomizationOptionStored[],
    originalPrice: number,
    customer_stock_quantity: number
}

export type CartItemOriginal = ShoppingCartItem & {
    product_item: (
        ProductItem & {
            variations: ({
                variation_option: ProductVariationOption
            } & ProductVariation)[],
            price: ProductPrice,
            M08_ProductImage: string,
            customer_stock_quantity: number

        }
    ),
    customizations: ProductCustomizationOptionCart[],
    originalPrice: number,

}




export function useCartApi() {

    const dispatch = useDispatch()
    const api = useRESTapi()
    const cartItems = useSelector((state: RootState) => state.cart.cart)
    const customer = useSelector((state: RootState) => state.customer)
    const {
        close_alert_modal,
        open_alert_modal
    } = useAlertModal()
    const {
        findTotalValue
    } = useCustomizationApi()

    const add_to_Cart = async ({
        customerId,
        productItemId,
        quantity,
        isCustomizationAvailable,
        isActive,
        customizationOption
    }: {
        productItemId: string,
        quantity: number,
        customerId: string,
        isCustomizationAvailable: 0 | 1,
        isActive: 1 | 0,
        customizationOption?: CustomizationOptionStored[]
    }) => {

        const customerLoggedin: customerLoggedInLocalStorageType | undefined = localStorage.getItem('customerLoggedIn') as customerLoggedInLocalStorageType


        if (customerLoggedin === '1') {     //if logged in

            let requistBody: any = {
                C01_M01_customerID: String(customerId),
                C02_M08_product_item_ID: String(productItemId),
                C02_quantity: String(quantity),
            }
            if (customizationOption && customizationOption.length > 0) {
                requistBody.customizationID = customizationOption[0].optionId
                requistBody.varientID = customizationOption[0].varientID
                requistBody.customizationOption = []
                customizationOption.forEach(
                    (option, index) => {
                        let optionSample: Partial<ProductCustomizationOptionCart & { inputValue: number }> = {}
                        Object.entries(option).map(
                            data => {
                                switch (data[0]) {
                                    case CustomizationOptionStoredKeys.value:
                                        optionSample.M23_value = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.id:
                                        optionSample.M23_M22_customization_option_id = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.name:
                                        optionSample.M23_customization_name = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.type:
                                        optionSample.M23_type = data[1] as customizationOptionType
                                        break;
                                    case CustomizationOptionStoredKeys.varientID:
                                        optionSample.M23_varientID = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.isSlab:
                                        optionSample.M23_isSlab = data[1] as 1 | 0
                                        break;
                                    case CustomizationOptionStoredKeys.rate:
                                        optionSample.M23_rate = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.hikePerc:
                                        optionSample.M23_hikePerc = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.hikePercInRate:
                                        optionSample.M23_hikePercInRate = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.minVal:
                                        optionSample.M23_minVal = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.maxVal:
                                        optionSample.M23_maxVal = String(data[1] ? data[1] : '')
                                        break;
                                    case CustomizationOptionStoredKeys.order:
                                        optionSample.M23_order = Number(data[1])
                                        break;
                                    case CustomizationOptionStoredKeys.baseRate:
                                        optionSample.M23_baseRate = Number(data[1])
                                        break;
                                    case CustomizationOptionStoredKeys.Inputvalue:
                                        optionSample.inputValue = Number(data[1] ? data[1] : '')
                                        break;

                                    default:
                                        break;
                                }
                            }
                        )
                        requistBody.customizationOption.push(optionSample)
                    }
                )

            }
            console.log(requistBody)
            try {
                const res = await api.post<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/add_sku_to_cart`,
                    body: requistBody,
                    mode: 'customer',

                })
                if (res.data.success === true) {
                    dispatch(addToCart({
                        productItemId: productItemId,
                        quantity: quantity,
                        mode: 'loggedIn',
                        isActive: isActive
                    }))

                } else {
                    alert(res.data.message || 'something went wrong')
                    return false
                }

            } catch (error) {
                console.log(error)
                alert('server error')
                return false
            }

        } else {
            // Not logged 

            switch (isCustomizationAvailable) {
                case 0:
                    dispatch(addToCart({
                        productItemId: productItemId,
                        quantity: quantity,
                        mode: 'notLoggedIn',
                        isActive: isActive
                    }))
                    break;
                case 1:
                    if (!customizationOption) {
                        throw new Error('need customization option parameter')
                    }
                    dispatch(addToCart({
                        productItemId: productItemId,
                        quantity: quantity,
                        mode: 'notLoggedIn',
                        isActive: isActive,
                        customizationOption
                    }))
                    break;

                default:
                    break;
            }



        }

        return true


    }

    const update_to_Cart = async ({
        customerId,
        productItemId,
        quantity,
        isActive
    }: {
        productItemId: string,
        quantity: number,
        customerId: string,
        isActive: 1 | 0
    }) => {

        const customerLoggedin: customerLoggedInLocalStorageType | undefined = localStorage.getItem('customerLoggedIn') as customerLoggedInLocalStorageType


        if (customerLoggedin === '1') {     //if logged in

            const res = await api.put<{ success: boolean, data: ProductVariation, errors: any, Message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cart/${productItemId}`,
                body: {
                    C01_M01_customerID: customerId,
                    C02_M08_product_item_ID: productItemId,
                    C02_quantity: quantity,
                    C02_is_active: isActive
                },
                mode: 'customer',

            })
            if (res.data.success === true) {
                dispatch(updateToCart({
                    productItemId: productItemId,
                    quantity: quantity,
                    mode: 'loggedIn',
                    isActive: isActive
                }))
                return true
            } else {
                alert(res.data.Message || 'something went wrong')
                return false
            }

        } else {

            dispatch(updateToCart({
                productItemId: productItemId,
                quantity: quantity,
                mode: 'notLoggedIn',
                isActive: isActive
            }))


        }

        return true


    }

    const remove_from_Cart = async ({
        productItemId,
        customerId,
    }: {
        productItemId: string,
        customerId: string
    }) => {

        const customerLoggedin: customerLoggedInLocalStorageType | undefined = localStorage.getItem(localStorageKeys.customerLoggedIn) as customerLoggedInLocalStorageType


        if (customerLoggedin === '1') {     //if logged in

            try {
                const res = await api.Delete<{ success: boolean, data: ProductVariation, errors: any, Message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cart/${productItemId}`,
                    body: {
                        C01_M01_customerID: customerId,
                        C02_M08_product_item_ID: productItemId
                    },
                    mode: 'customer',

                })
                if (res.data.success === true) {
                    dispatch(removeFromCart({
                        productItemId: productItemId,
                        mode: 'loggedIn'
                    }))
                    return true
                } else {
                    alert(res.data.Message || 'something went wrong')
                    return false
                }

            } catch (error) {
                console.log(error)
                alert('server error')
                return false
            }
        } else {

            dispatch(removeFromCart({
                productItemId: productItemId,
                mode: 'notLoggedIn'
            }))
            return true

        }


    }

    const clear_cart = async () => {

        const customerLoggedin: customerLoggedInLocalStorageType | undefined = localStorage.getItem('customerLoggedIn') as customerLoggedInLocalStorageType


        if (customerLoggedin === '1') {     //if logged in

        } else {

            dispatch(clearCart({
                mode: 'notLoggedIn'
            }))


        }


    }

    //load all cart items from online by product ids
    const load_cart_from_cart = async () => {
        try {

            if (cartItems.length === 0) {
                return;
            }

            const formDataBody = new FormData()

            cartItems.forEach(
                item => formDataBody.append('M08_ProductItemID[]', item.productItemId)
            )

            const res = await api.post<{ success: boolean, data: CartItems[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/productitemlist`,
                body: formDataBody,
                mode: 'customer',
            })

            if (res.data.success === true) {
                const carts = res.data.data
                // removing cart element if product not found
                cartItems.forEach(
                    localItem => {
                        const index = carts.findIndex(onlineProducts => onlineProducts.M08_ProductItemID == localItem.productItemId)
                        if (index < 0) {
                            remove_from_Cart({
                                customerId: String(customer.customerData.M01_CustomerID),
                                productItemId: localItem.productItemId
                            })
                        }
                    }
                )

                carts.forEach(
                    item => {
                        const index = cartItems.findIndex(item1 => item1.productItemId == item.M08_ProductItemID)
                        item.C02_quantity = cartItems[index].quantity
                        item.C02_is_active = cartItems[index].isActive
                        item.customizationOption = cartItems[index].customizationOption
                        // item.availableQuantity = 0;
                        if (cartItems[index].customizationOption) {
                            item.originalPrice = findTotalValue(Number(item.price?.M10_List_price), cartItems[index].customizationOption!)
                        } else {
                            item.originalPrice = Number(item.price?.M10_List_price)
                        }
                    }
                )

                return carts
            }
            throw res.data
        } catch (error) {
            throw error
        }
    }

    //load cart items if signed in 
    const load_original_cart = async () => {

        try {

            const res = await api.get<{ success: boolean, data: CartItemOriginal[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cart`,
                mode: 'customerPrivate',
            })
            const carts = res.data.data


            if (res.data.success === true) {
                const cartitemLocal: CartLocalstorateType[] = carts.map(
                    item => {

                        const customizations: CustomizationOptionStored[] = item.customizations.map(
                            item => ({
                                baseRate: item.M23_baseRate,
                                hikePerc: Number(item.M23_hikePerc),
                                hikePercInRate: Number(item.M23_hikePercInRate),
                                id: String(item.M23_cart_customization_id),
                                Inputvalue: Number(item.M23_inputValue),
                                isSlab: item.M23_isSlab == 1 ? true : false,
                                isDefault: true,
                                maxVal: Number(item.M23_maxVal),
                                minVal: Number(item.M23_minVal),
                                name: item.M23_customization_name,
                                optionId: String(item.M23_M22_customization_option_id),
                                order: item.M23_order,
                                rate: Number(item.M23_rate),
                                type: item.M23_type,
                                value: item.M23_value,
                                varientID: item.M23_varientID
                            } satisfies CustomizationOptionStored)
                        )

                        if (customizations.length) {
                            item.originalPrice = findTotalValue(Number(item.product_item.price?.M10_List_price), customizations)
                        } else {
                            item.originalPrice = Number(item.product_item.price?.M10_List_price)
                        }

                        return ({
                            productItemId: String(item.C02_M08_product_item_ID),
                            quantity: item.C02_quantity,
                            customizationOption: customizations,
                            isActive: item.C02_is_active
                        })

                    }
                )


                dispatch(setCart({
                    cartItems: cartitemLocal,
                    mode: 'loggedIn'
                }))

                return carts
            }


        } catch (error) {
            console.log(error)
            alert('server error')
        }

    }

    // add to cart by product id quantity
    const add_to_cart_by_product = async () => {

        if (cartItems.length <= 0) {
            return;
        }
        const formDataReal = new FormData();
        cartItems.map(
            (item, index) => {
                formDataReal.append(`cartItem[${index}][productItemId]`, String(item.productItemId))
                formDataReal.append(`cartItem[${index}][quantity]`, String(item.quantity))
                // Append customization options if they exist
                if (item.customizationOption && item.customizationOption.length > 0) {
                    formDataReal.append(`cartItem[${index}][customizationID]`, String(item.customizationOption[0].optionId))
                    formDataReal.append(`cartItem[${index}][varientID]`, String(item.customizationOption[0].varientID))
                    item.customizationOption.forEach((option, optionIndex) => {
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_M22_customization_option_id]`, option.id);
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_customization_name]`, option.name);
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_value]`, String(option.value || ''));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_type]`, option.type);
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_varientID]`, option.varientID);
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_isSlab]`, option.isSlab?'1':'0');
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_rate]`, String(option.rate || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_hikePerc]`, String(option.hikePerc || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_hikePercInRate]`, String(option.hikePercInRate || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_minVal]`, String(option.minVal || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_maxVal]`, String(option.maxVal || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_order]`, String(option.order));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][M23_baseRate]`, String(option.baseRate || 0));
                        formDataReal.append(`cartItem[${index}][customizationOption][${optionIndex}][inputValue]`, String(option.Inputvalue));
                    });
                }
            }
        )

        try {

            const res = await api.post<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/add_to_cart`,
                body: formDataReal,
                mode: 'customer',
            })

            if (res.data.success === true) {
                return true
            }

        } catch (error) {
            console.log(error)
            return false
        }

        return false

    }

    return {
        add_to_Cart,
        remove_from_Cart,
        load_cart_from_cart,
        clear_cart,
        update_to_Cart,
        load_original_cart,
        add_to_cart_by_product
    }
}
