import { customerFeeds } from '@/src/types/databaseFeeds';
import React, { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCartApi } from 'src/hooks/cart_api';
import { useConfiguration } from 'src/hooks/configuration_hook';
import { useRESTapi } from 'src/hooks/rest_API'
import { setCurrencyDetails } from 'src/redux/configuration/configuration';
import { setCustomer, setCustomerLoggedIn } from 'src/redux/customer/customer_slice';
import { Customer } from 'src/types';
import { localStorageKeys } from 'src/types/local_storage';
import {ReactComponent as Chefssouqlogo} from './chefssouqlogo.svg';

export function ProtectedRouteCustomer({
  children
}: {
  children: ReactNode
}) {

  const api = useRESTapi();
  const [is_checking_auth, set_is_checking_auth] = useState(true)
  const dispatch = useDispatch()
  const { clear_cart, load_original_cart } = useCartApi()
  const {
    get_user_currency
  } = useConfiguration()

  const checklogin = async () => {
    set_is_checking_auth(true)
    const currency = await get_user_currency()
    if (currency) {
      setCurrencyDetails(currency)
    }

    try {
      const res = await api.post<{ success: boolean, data: Customer, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/verify_token`,
        mode: 'customer',
      })

      if (res.data.success === true) {

        dispatch(setCustomer(res.data.data))
        await clear_cart()
        localStorage.setItem(localStorageKeys.user_mode, 'customer')
        localStorage.setItem(localStorageKeys.customerLoggedIn, '1')
        dispatch(setCustomerLoggedIn('1'))
        set_is_checking_auth(false)

        load_original_cart().then(
          res => console.log(res)
        ).catch(
          err => console.log(err)
        )

        return;
      }

    } catch (error) {
      console.log(error)
      alert('authentication error')
    }

    localStorage.setItem(localStorageKeys.customerLoggedIn, '0')
    localStorage.setItem(localStorageKeys.user_mode, 'customer')
    dispatch(setCustomer({
      email: '',
      M01_CustomerPhone: '',
      M01_CustomerUsername: '',
      M01_M20_emirateID: '',
      M01_CustomerID: customerFeeds.logoutCustomer.M01_CustomerID,    // logout mode
    }))

    set_is_checking_auth(false)

  }


  const { pathname } = useLocation()
  const isProductPath = pathname.split('/').includes('product')
  const productCategory = isProductPath ? pathname.split('/')[1] : ''

  useEffect(
    () => {
      checklogin()
    }, []
  )

  return (

    is_checking_auth === true
      ?
      <>
        <Helmet>
          <title>{productCategory?.toLowerCase()}</title>

        </Helmet>
        <div className=' fixed inset-0 flex items-center justify-center '><Chefssouqlogo className=' animate-pulse mx-auto my-auto'/></div>
      </>
      : <div>{children}</div>

  )
}
