import React from 'react'
import { useRESTapi } from './rest_API'
import { CustomizationOptionStored, localStorageKeys } from 'src/types/local_storage'
import { useDispatch } from 'react-redux'
import { setCustomerLoggedIn } from 'src/redux/customer/customer_slice'
import { useCartApi } from './cart_api'
import { ProductCustomizationOption, ProductItem, ProductItemHighlights, ProductPrice, ProductVariation, ProductVariationOption } from 'src/types'
export type SKUCompleteDetailsType = ProductItem & {
    variations: ({
        variation_option:  ProductVariationOption
    }&ProductVariation)[],
    M09_List_price?: ProductPrice,
    customization: ({
        customizationOptions:ProductCustomizationOption[]
    }&{
        M21_customization_id:string|number,
        M21_customization_name:string
    }),
    customer_stock_quantity:number,
    highlights:ProductItemHighlights[],
    categoryID:string|number,
    otherVarients:({
        productItemId:number,
        options:number[]
    })[],
    metadescription:string,
    M08_meta_tags:string
}

type productSKUMultipleId = ProductItem & {
    variations: ({
        variation_option: ProductVariationOption
    } & ProductVariation)[],
    price: ProductPrice,
    customizationOption?: CustomizationOptionStored[],
    originalPrice: number,
    customer_stock_quantity:number
}

interface VariationOption {
    M07_VariationOptionID: number;
    M07_Value: string;
    M07_M06_VariationID: number;
    M07_M11_ProductID: number;
  }

interface Variation {
    M06_VariationID: number;
    M06_M04_CategoryID: number;
    M06_Name: string;
    M06_M11_ProductID: number;
    options: VariationOption[];
  }

export function useProductSKU() {
    const api = useRESTapi()

    //get sku details full
    const get_full_sku_details_by_id = async ({
        productId
    }: { productId: string }) => {


        try {

            const res = await api.get<{ success: boolean, data: SKUCompleteDetailsType, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/product_items/${productId}`,
                mode: 'customer',
            })

            if (res.data.success === true) {

                return res.data.data
            } else if (res.data.success === false) {
                alert(res.data.message || 'something went wrong')
            }

            return false

        } catch (error) {
            console.log(error)
            alert('server error')
            return false
        }

    }

    //get skus by ids
    const get_skus_by_ids = async ({ ids }: { ids: string[] }) => {


        try {

            const formDataBody = new FormData()

            ids.forEach(
                item => formDataBody.append('M08_ProductItemID[]', item)
            )

            const res = await api.post<{ success: boolean, data: productSKUMultipleId[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/productitemlist`,
                body: formDataBody,
                mode: 'customer',
            })

            if (res.data.success === true) {
                const carts = res.data.data
                console.log(carts)
                return carts
            }

            // alert(res.data.message)

            // throw new Error('internal error')

        } catch (error) {
            console.log(error)
            alert('server error')
        }

    }
    
    const get_variations_by_sku_id = async ({ id }: { id: string}) => {


        try {


            const res = await api.get<{ success: boolean, data: Variation[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/productvariation/${id}`,
                mode: 'customer',
            })

            if (res.data.success === true) {
                const variations = res.data.data
                console.log(variations)
                return variations
            }

            // alert(res.data.message)

            // throw new Error('internal error')

        } catch (error) {
            console.log(error)
            alert('server error')
        }

    }

    return {
        get_full_sku_details_by_id,
        get_skus_by_ids,
        get_variations_by_sku_id
    }
}
