import { Item } from "@radix-ui/react-select";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCartApi } from "src/hooks/cart_api";
import { RootState } from "src/redux/store";

export type ProductCardProps = {
  productId: string;
  description: string;
  title: string;
  imagePath: string;
  listPrice: number;
  MRPPrice: number;
  quantity: number;
  productsku: string;
  onclick?: () => void;
  isCustomizationAvailable: 1 | 0;
};

export function ProductCard({
  MRPPrice,
  description,
  imagePath,
  listPrice,
  productId,
  title,
  productsku,
  isCustomizationAvailable,
  quantity,
  onclick = () => {
    console.log("clicked");
  },
}: ProductCardProps) {
  const { add_to_Cart, remove_from_Cart } = useCartApi();
  const [is_loading, set_is_loading] = useState(false);
  const cart = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const customer = useSelector(
    (state: RootState) => state.customer.customerData
  );
  const currency = useSelector(
    (state: RootState) => state.configuration.currencyDetails
  );
  // Calculate the discount percentage
  const discountPercentage = ((MRPPrice - listPrice) / MRPPrice) * 100;

  return (
    <div
      key={productId}
      onClick={onclick}
      className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl min-w-60 w-60  cursor-pointer p-4 gap-3 h-[300px]"
    >
      <div className="relative  z-10 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl w-full aspect-1 ">
        {imagePath ? (
          <img
            src={imagePath}
            className="object-cover hover:object-contain w-full h-full"
            alt="card"
          />
        ) : (
          <img src={``} alt="card" className="object-cover w-full h-full" />
        )}
      </div>
      <div className=" w-full z-10 px-1">
        <div className="flex grow justify-between  mb-2 h-12 gap-2">
          <p className="block font-sans text-sm grow  antialiased font-semibold leading-4 text-blue-gray-900 break-all line-clamp-3">
            {title}
            {/* NEW PRODUCT  */}
            {/* {product.newproduct && (
                  <span className='bg-[#c0292938] p-[2px] font-bold rounded-[4px] text-[#c62626] text-[12px] pl-[5px] pr-[6px] ml-1'>New</span>
              )} */}
          </p>
          {MRPPrice&&listPrice?<div className="text-right leading-4">
            <p className="block font-sans  antialiased text-sm font-medium  text-blue-gray-900">
              <span className=" font-thin text-xs text-gray-700">
                {currency.M05_CurrencySymbol}
              </span>
              {listPrice}
            </p>
            {MRPPrice && (
              <p className="text-xs line-through text-gray-500">
                <span className=" text-[10px]">
                  {currency.M05_CurrencySymbol}
                </span>
                {MRPPrice}
              </p>
            )}
            {discountPercentage > 0 && (
              <p className="text-xs font-bold text-green-500 whitespace-nowrap">
                {discountPercentage.toFixed(0)}% OFF
              </p>
            )}
          </div>:null}
        </div>
        {/* DESCRIPTION */}
        <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75 h-10 line-clamp-2">
          {description}
        </p>
      </div>
      <div className=" px-1 z-50">


        {
          !listPrice || !MRPPrice ?
            <button
              onClick={(e) => {
                e.stopPropagation();
                const name = title.replace(/ /g, '-').toLowerCase();
                const whatsappLink = `https://wa.me/971569186717?text=${encodeURIComponent(
                  `Hello, I'm interested in this product and would like to inquire more about it. Here are the details:\n\n` +
                  `Product Link: ${process.env.REACT_APP_FRONTEND_URL}/${name}/product/?productItemId=${productId}\n\n` +
                  `Product SKU: ${productsku}\n\n` +
                  `Could you please provide more information about the availability, pricing, and delivery options?`
                )}`
                window.location.href = whatsappLink
              }}
              className={`align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full ${is_loading
                ? "bg-green-500 text-white-400 cursor-default"
                : "bg-green-500 text-white"
                }  shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100`}
              type="button"
            >
              Connect Whatsapp
            </button> :
            cart.cart.some((item) => item.productItemId == productId!) ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  set_is_loading(true);
                  if (is_loading === false)
                    remove_from_Cart({
                      productItemId: String(productId),
                      customerId: String(customer.M01_CustomerID),
                    }).then((res) => set_is_loading(false));
                }}
                className={`align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full ${is_loading
                  ? "bg-blue-gray-800/10 text-blue-gray-400 cursor-default"
                  : "bg-blue-gray-900/10 text-blue-gray-900"
                  }  shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100`}
                type="button"
              >
                Remove from Cart
              </button>
            ) : isCustomizationAvailable === 1 ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onclick();
                }}
                className={`align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full ${is_loading
                  ? "bg-blue-gray-800/10 text-blue-gray-400 cursor-default"
                  : "bg-blue-gray-900/10 text-blue-gray-900"
                  }  shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100`}
                type="button"
              >
                view product
              </button>
            ) : (
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  set_is_loading(true);
                  if (!is_loading) {
                    await add_to_Cart({
                      customerId: String(customer.M01_CustomerID), // Replace with actual customer ID
                      productItemId: String(productId),
                      quantity: 1,
                      isActive: 1,
                      isCustomizationAvailable: 0,
                    });
                    set_is_loading(false);
                  }
                }}
                disabled={quantity === undefined || quantity === null || quantity === 0 || is_loading}
                className={`align-middle select-none font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] block w-full ${is_loading || quantity === undefined || quantity === null || quantity === 0
                  ? "bg-blue-gray-800/10 text-blue-gray-400 cursor-not-allowed"
                  : "bg-blue-gray-900/10 text-blue-gray-900 hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                  }`}
                type="button"
              >
                {is_loading
                  ? "Loading..."
                  : quantity === 0 || quantity === undefined || quantity === null || quantity === 0
                    ? "Out of Stock"
                    : "Add to Cart"}
              </button>
            )}
      </div>
    </div>
  );



}
export function ServiceCard({
  MRPPrice,
  description,
  imagePath,
  listPrice,
  productId,
  title,
  isCustomizationAvailable,
  quantity,
  onclick = () => {
    console.log("clicked");
  },
}: ProductCardProps) {
  const { add_to_Cart, remove_from_Cart } = useCartApi();
  const [is_loading, set_is_loading] = useState(false);
  const cart = useSelector((state: RootState) => state.cart);
  const customer = useSelector(
    (state: RootState) => state.customer.customerData
  );
  const currency = useSelector(
    (state: RootState) => state.configuration.currencyDetails
  );
  // Calculate the discount percentage
  const discountPercentage = ((MRPPrice - listPrice) / MRPPrice) * 100;

  return (
    <div
      key={productId}
      onClick={onclick}
      className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl min-w-60 w-60  cursor-pointer p-4 gap-3 h-[300px]"
    >
      <div className="relative  z-10 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl w-full aspect-1 ">
        {imagePath ? (
          <img
            src={imagePath}
            className="object-cover hover:object-contain w-full h-full"
            alt="card"
          />
        ) : (
          <img src={``} alt="card" className="object-cover w-full h-full" />
        )}
      </div>
      <div className=" w-full z-10 px-1">
        <div className="flex grow justify-between  mb-2 h-12 gap-2">
          <p className="block font-sans text-sm grow  antialiased font-semibold leading-4 text-blue-gray-900 break-all line-clamp-3">
            {title}
            {/* NEW PRODUCT  */}
            {/* {product.newproduct && (
                  <span className='bg-[#c0292938] p-[2px] font-bold rounded-[4px] text-[#c62626] text-[12px] pl-[5px] pr-[6px] ml-1'>New</span>
              )} */}
          </p>
          <div className="text-right leading-4">
            <p className="block font-sans  antialiased text-sm font-medium  text-blue-gray-900">
              <span className=" font-thin text-xs text-gray-700">
                {currency.M05_CurrencySymbol}
              </span>
              {listPrice}
            </p>
            {MRPPrice && (
              <p className="text-xs line-through text-gray-500">
                <span className=" text-[10px]">
                  {currency.M05_CurrencySymbol}
                </span>
                {MRPPrice}
              </p>
            )}
            {discountPercentage > 0 && (
              <p className="text-xs font-bold text-green-500 whitespace-nowrap">
                {discountPercentage.toFixed(0)}% OFF
              </p>
            )}
          </div>
        </div>
        {/* DESCRIPTION */}
        <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75 h-10 line-clamp-2">
          {description}
        </p>
      </div>
      <div className=" px-1 z-50">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onclick();
          }}
          className={`align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full ${is_loading
            ? "bg-blue-gray-800/10 text-blue-gray-400 cursor-default"
            : "bg-blue-gray-900/10 text-blue-gray-900"
            }  shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100`}
          type="button"
        >
          Book Service
        </button>
      </div>
    </div>
  );



}

export const ProductCardSkeleton: React.FC = () => {
  return (
    <div className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-64 animate-pulse">
      <div className="relative mx-4 mt-4 z-10 overflow-hidden text-gray-700 bg-gray-300 bg-clip-border rounded-xl h-56 min-w-56 max-w-56"></div>
      <div className="p-6 w-full z-10">
        <div className="flex grow justify-between mb-2 h-12 gap-2">
          <div className="block font-sans text-sm grow antialiased font-semibold leading-4 text-blue-gray-900 break-all line-clamp-3 bg-gray-300 h-6 w-3/4"></div>
          <div className="text-right leading-4">
            <div className="block font-sans antialiased text-sm font-medium text-blue-gray-900 bg-gray-300 h-4 w-1/4 mb-1"></div>
            <div className="text-xs line-through text-gray-500 bg-gray-300 h-4 w-1/4"></div>
          </div>
        </div>
        <div className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75 h-10 line-clamp-2 bg-gray-300 h-6 w-full"></div>
      </div>
      <div className="p-6 pt-0 z-50">
        <div className="align-middle select-none font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg shadow-gray-900/10 bg-gray-300 h-10 w-full"></div>
      </div>
    </div>
  );
};
