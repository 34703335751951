
import { customizationOptionType, ProductCustomizationOption } from '@/src/types';
import React, { useEffect, useRef, useState } from 'react'
import AddOptionModal from './components/modal/add_variation_modal';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/src/components/ui/tabs';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/src/components/ui/card';
import { Label } from '@/src/components/ui/label';
import { Input } from '@/src/components/ui/input';
import { Button } from '@/src/components/ui/button';
import { AddVariationOptionModal } from './components/modal/add_variation_option_modal';
import { v4 as uuidv4 } from 'uuid';
import { Step2Component } from './components/step2component';
import { ProductCustomizationOptionClient, productItemOverview } from '../addmodal';
import { IconX } from '@tabler/icons-react';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle } from '@/src/pages/admin/components/modal';
import { ArrowDownToDot, EditIcon, Trash } from 'lucide-react';
import { useToast } from '@/src/components/ui/use-toast';
import { ButtonIcon } from '@/src/components/buttons/buttonIcon';
import { EditVariationModal, } from './components/modal/editVariation_modal';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { EditVariationOptionModal } from './components/modal/edit_variation_option_modal';
import { useRESTapi } from '@/src/hooks/rest_API';
import { SubmitConfirmationModal } from './components/modal/submit_confirmation_modal';



export type customizationVariation = {
    index: number,
    variationName: string,
    selectedValue: string,
    variationOptions: ProductCustomizationOptionClient[],
    isDefault: 1 | 0
}

export type customizationVariationLevels = customizationVariation[]
export type step = 'variationStep' | 'optionStep' | 'completed'



function generateId() {
    return uuidv4().substring(0, 32); // Truncate to 32 characters
}

type Props = {
    customizationObjs: ProductCustomizationOptionClient[],
    setCustomizationObjs: (options: ProductCustomizationOptionClient[]) => any,
    show: boolean,
    setShow: (show: boolean) => any,
    setCustomizationName: (name: string) => any,
    customizationId: string | number | undefined,
    customizationName: string
    step: step,
    setStep: (step: step) => any,
    updateVariable: boolean,         // if we change the state of this variable, the component restore its value
    mode: 'add' | 'edit',
    updateSkuDetails: () => void,
    productSku:productItemOverview,
    disabled?:boolean
}

export function CustomizationPage(
    {
        customizationObjs,
        setCustomizationObjs,
        setShow,
        show,
        customizationName,
        customizationId,
        setCustomizationName,
        step,
        setStep,
        updateVariable,
        mode,
        updateSkuDetails,
        productSku,
        disabled
    }: Props
) {


    const [variatonLevels, setVariationLevels] = useState<customizationVariationLevels>(
        [

        ]
    )
    const [variation_modal, setvariation_modal] = useState(false)
    const [Variation_option_modal, setVariation_option_modal] = useState(false)
    const [selectedVarientID, setSelectedVarientId] = useState<string>()
    const api = useRESTapi()
    // for edit
    const [show_edit_variation_modal, set_show_edit_variation_modal] = useState<boolean>(false)
    const [show_edit_variation_option_modal, set_show_edit_variation_option_modal] = useState<boolean>(false)
    const [selected_variation_index, set_selected_variation_index] = useState<number>()
    const [selected_variation_option_index, set_selected_variation_option_index] = useState<number>()
    //submit
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showSubmitConfirmationModal, setShowSubmitConformationModal] = useState(false)
    const step2ref = useRef<HTMLDivElement>(null)
    const { toast } = useToast()
    const alertModal = useAlertModal()

    function addOption(opt: ProductCustomizationOptionClient) {
        const option = { ...opt, uniqueId: generateId() }
        switch (option.type) {
            case 'dropDown':
                customizationObjs.push(option)
                setCustomizationObjs([...customizationObjs])
                break;

            case 'normal':
                // const newArray = customizationObjs.filter(item=>item.varientID==option.varientID)
                const index = customizationObjs.findIndex(item => item.customization_name == option.customization_name && item.varientID == option.varientID)
                if (index >= 0) {
                    return 'duplication options'
                }
                const defaultIndex = customizationObjs.findIndex(item => item.varientID && item.type === 'dropDown')
                if (defaultIndex >= 0) {
                    option.isDefault = customizationObjs[defaultIndex].isDefault
                }
                customizationObjs.push(option)
                setCustomizationObjs([...customizationObjs])
                break;

            default:
                break;
        }
        setStep('optionStep')
        return true
    }

    function editOption(option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation', oldName?: string) {

        switch (mode) {
            case 'forAllVariation':
                if (!oldName)
                    throw new Error('need oldName parameter in forAllVariation')
                customizationObjs.forEach(
                    (opt, index) => {
                        if (opt.customization_name === oldName && opt.type === 'normal') {
                            const optionNew = { ...option }
                            optionNew.varientID = opt.varientID
                            customizationObjs[index] = { ...optionNew }
                        }
                    }
                )

                break;
            case 'forThisVariation': {
                const index = customizationObjs.findIndex(item => (item.uniqueId == option.uniqueId && item.varientID == option.varientID))
                if (index >= 0) {
                    customizationObjs[index] = { ...option }
                    setCustomizationObjs([...customizationObjs])
                    console.log('updated for this variation')
                }
                break;
            }

            default:
                break;
        }

    }

    function deleteVariation(name: string, index: number) {
        variatonLevels.splice(index, 1)
        setVariationLevels([...variatonLevels])
        alertModal.close_alert_modal()
    }
    function deleteVariationOption(variationIndex: number, variationOptionindex: number) {

        if (variatonLevels[variationIndex].variationOptions.length === 1) {
            variatonLevels.splice(variationIndex, 1)
        } else {
            variatonLevels[variationIndex].variationOptions.splice(variationOptionindex, 1)
            variatonLevels[variationIndex].selectedValue = variatonLevels[variationIndex].variationOptions.length ? variatonLevels[variationIndex].variationOptions[0].value! : ''
        }

        setVariationLevels([...variatonLevels])
        alertModal.close_alert_modal()
    }

    function deleteOption(option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation') {
        switch (option.type) {
            case 'dropDown': {
                const newObjs = customizationObjs.filter(
                    item => item.varientID != option.varientID
                )
                setCustomizationObjs(newObjs)
                break;
            }

            case 'normal':
                // const newArray = customizationObjs.filter(item=>item.varientID==option.varientID)
                switch (mode) {
                    case 'forAllVariation': {
                        let newObjs2: ProductCustomizationOptionClient[] = [...customizationObjs]
                        console.log(newObjs2)

                        const uniqueVariantIDs = new Set<string>();
                        customizationObjs.forEach(option => {
                            uniqueVariantIDs.add(option.varientID);
                        });
                        const varientIDs: string[] = Array.from(uniqueVariantIDs);
                        varientIDs.forEach(
                            (varientId) => {
                                const optionNew: ProductCustomizationOptionClient = { ...option }
                                optionNew.varientID = varientId
                                const index = newObjs2.findIndex(
                                    item => (item.customization_name == optionNew.customization_name && item.varientID == optionNew.varientID)
                                )
                                console.log(index)
                                if (index >= 0) {
                                    newObjs2.splice(index, 1)
                                }
                            }
                        )


                        setCustomizationObjs(newObjs2)

                        break;
                    }
                    case 'forThisVariation': {
                        const newObjs2 = customizationObjs.filter(item => {
                            if (item.customization_name == option.customization_name && item.varientID == option.varientID) {
                                return false
                            }
                            return true
                        })

                        setCustomizationObjs(newObjs2)
                        break;
                    }
                    default:
                        break;
                }

                break;

            default:
                break;
        }
        setStep('optionStep')
        return true
    }

    function addVariation(option: ProductCustomizationOptionClient) {
        const name = option.customization_name
        // check is already present

        const dublicateIndex = variatonLevels.findIndex(
            (variation) => {
                if (variation.variationName === name) {
                    return true
                }
            }
        )

        if (dublicateIndex >= 0) {

            return 'variation already present'
        }

        const variationLevel: customizationVariation = {
            index: variatonLevels.length + 1,
            variationName: option.customization_name,
            selectedValue: option.value!,
            variationOptions: [option],
            isDefault: 0
        }



        variatonLevels.push(variationLevel)
        setVariationLevels([...variatonLevels])
    }

    function addVariationOption(option: ProductCustomizationOptionClient) {
        const varientId = option.varientID

        // finding index
        const index = variatonLevels.findIndex(
            (variation) => {
                if (variation.variationName === option.customization_name) {
                    return true
                }
            }
        )

        // finding duplicate value
        const variationOption = variatonLevels[index]
        const duplicateIndex = variationOption.variationOptions.findIndex(
            optionV => {
                if (optionV.value === option.value) {
                    return true
                }
            }
        )

        if (duplicateIndex >= 0) {
            return 'variation option is duplicated'
        }

        variationOption.selectedValue = option.value!

        variationOption.variationOptions.push(option)
        console.log(variatonLevels)

        setVariationLevels([...variatonLevels])
    }

    // creating the combination of created variations
    function findCombinations(levels: customizationVariationLevels): ProductCustomizationOptionClient[][] {

        if (levels.length === 0) {
            const id = generateId()
            setSelectedVarientId(id)
            return [];
        }

        // Define the function
        function getVariantIdByCombination(combination: string[], customizationObjs: ProductCustomizationOptionClient[]): string | null {
            // Step 1: Filter the data to include only items of type "dropDown"
            const dropDownItems = customizationObjs.filter(item => item.type === "dropDown");

            // Step 2: Group items by varientID
            const groupedByVariantId = dropDownItems.reduce((acc, item) => {
                if (!acc[item.varientID]) {
                    acc[item.varientID] = [];
                }
                acc[item.varientID].push(item.value!);
                return acc;
            }, {} as { [key: string]: string[] });

            // Step 3: Check if the combination matches any group
            for (const varientID in groupedByVariantId) {
                const values = groupedByVariantId[varientID];
                if (combination.every(value => values.includes(value))) {
                    return varientID;
                }
            }

            // Return null if no match is found
            return null;
        }

        const result: ProductCustomizationOptionClient[][] = [];

        function combine(prefix: ProductCustomizationOptionClient[], remainingLevels: customizationVariationLevels) {
            if (remainingLevels.length === 0) {
                result.push(prefix);
                return;
            }

            const [currentLevel, ...restLevels] = remainingLevels;
            const options = currentLevel.variationOptions;

            for (const option of options) {
                combine([...prefix, option], restLevels);
            }
        }

        combine([], levels);
        // creating variationId and storing
        let Newoptions: ProductCustomizationOptionClient[] = []

        result.forEach(
            combination => {
                const variationId: string = generateId()
                combination.forEach(
                    options => {
                        Newoptions.push(
                            {
                                ...options,
                                varientID: variationId,
                            }
                        )
                    }
                )

            }
        )

        console.log(Newoptions)
        const selectedValues: string[] = variatonLevels.map(
            variation => variation.selectedValue
        )
        const id = getVariantIdByCombination(selectedValues, Newoptions)
        if (id)
            setSelectedVarientId(id)
        Newoptions.forEach(
            option => {
                if (option.varientID === id) {
                    option.isDefault = 1
                }
                option.uniqueId = generateId();
            }
        )
        setCustomizationObjs(Newoptions)
        return result;
    }



    function handleChangeVariatonOptionTab(variationName: string, selectedValue: string) {

        // Define the function
        function getVariantIdByCombination(combination: string[]): string | null {
            // Step 1: Filter the data to include only items of type "dropDown"
            const dropDownItems = customizationObjs.filter(item => item.type === "dropDown");

            // Step 2: Group items by varientID
            const groupedByVariantId = dropDownItems.reduce((acc, item) => {
                if (!acc[item.varientID]) {
                    acc[item.varientID] = [];
                }
                acc[item.varientID].push(item.value!);
                return acc;
            }, {} as { [key: string]: string[] });

            // Step 3: Check if the combination matches any group
            for (const varientID in groupedByVariantId) {
                const values = groupedByVariantId[varientID];
                if (combination.every(value => values.includes(value))) {
                    return varientID;
                }
            }

            // Return null if no match is found
            return null;
        }

        const index = variatonLevels.findIndex(
            variationLevel => {
                if (variationLevel.variationName === variationName) {
                    return true
                }
            }
        )

        if (index < 0) {
            console.log('can not find customization level')
            return 'can not find customization level'
        }

        variatonLevels[index].selectedValue = selectedValue

        // change getting variation id of the combination
        if (step === 'optionStep') {

            const selectedValues: string[] = variatonLevels.map(
                variation => variation.selectedValue
            )
            const id = getVariantIdByCombination(selectedValues)
            if (id)
                setSelectedVarientId(id)



        }


        setVariationLevels([...variatonLevels])


    }

    function gotoStep2() {
        // checking validation
        let isValid: boolean = true
        let validationError = ''
        if (customizationName.length < 3) {
            isValid = false
            validationError = 'Please Enter Valid customization name'
        }
        if (isValid === true) {
            if (variatonLevels.length === 0) {
                validationError = 'Create atlease one variation'
                isValid = false
            }
        }

        if (isValid === false) {
            toast({
                title: 'Validation Error',
                description: validationError,
            })

            return;
        }



        setStep('optionStep');

        findCombinations(variatonLevels)


        setTimeout(
            () => {
                if (step2ref.current) {
                    // Use scrollIntoView with options
                    step2ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center', // Aligns the top of the target element to the top of the scrollable ancestor
                    });

                    // Adjust for offset above the target element
                    // window.scrollBy(0, -50); // Adjust this value to scroll 50 pixels above the target element
                }
            }, 100
        )
    }

    function clearDatas() {
        setCustomizationObjs([])
        setCustomizationName('')
        setVariationLevels([])
        setStep('variationStep')
        setShow(false)
        console.log('clearing data')
    }

    function handleChangeModal(showModal: boolean) {

        if (showModal === false && step !== 'completed') {
            clearDatas()
        }
        if (showModal === false && mode === 'edit') {
            updateSkuDetails()
        }
        setShow(showModal)
    }

    // update customization in cloude
    async function updateCustomizationCloude() {

        setIsSubmitting(true)
        // SETTING DEFUALT OPTION
        const newCustomizationObj = [...customizationObjs]
        const defaultOption = newCustomizationObj.filter(item => item.isDefault === 1 && item.type === 'dropDown')
        if (defaultOption.length) {
            const defualtVarientId = defaultOption[0].varientID
            newCustomizationObj.forEach(
                item => {
                    if (item.varientID === defualtVarientId) {
                        item.isDefault = 1
                    } else {
                        item.isDefault = 0
                    }
                }
            )
        }

        const body: ProductCustomizationOption[] = newCustomizationObj.map(
            option => {
                return ({
                    M22_baseRate: option.baseRate,
                    M22_customization_name: option.customization_name,
                    M22_hikePerc: option.hikePerc?String(option.hikePerc):null,
                    M22_hikePercInRate: option.hikePercInRate?String(option.hikePercInRate):null,
                    M22_isDefault: option.isDefault,
                    M22_isSlab: option.isSlab,
                    M22_M21_product_customization_id: customizationId!,
                    M22_maxVal: option.maxVal?String(option.maxVal):null,
                    M22_minVal: option.minVal?String(option.minVal):null,
                    M22_order: option.order!,
                    M22_rate: option.rate?String(option.rate):null,
                    M22_type: option.type,
                    M22_value: option.value!,
                    M22_varientID: option.varientID,
                } satisfies ProductCustomizationOption)
            }
        )


        // UPDATION OF VARIATION
        if (customizationId) {
            const res = await api.put<{ success: boolean, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/customization_list/${customizationId}`,
                body: {
                    customizations: body
                },
                mode: 'admin',
            })

            if (res.data.success === true) {

                alert(res.data?.message || 'updated Customization')
                setShow(false)
                setShowSubmitConformationModal(false)
                updateSkuDetails()

            } else {

                alert(res.data?.message || 'something went wrong')

            }
        }else{
            // CREATE CUSTOMIZATION FOR THIS PRODUCT SKU
            const res = await api.post<{ success: boolean, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/customization_create/${productSku.M08_ProductItemID}`,
                body: {
                    customizationName:customizationName,
                    customizations: body
                },
                mode: 'admin',
            })
    
            if (res.data.success === true) {
    
                alert(res.data?.message || 'updated Customization')
                setShow(false)
                setShowSubmitConformationModal(false)
                updateSkuDetails()
    
            } else {
    
                alert(res.data?.message || 'something went wrong')
    
            }
        }
        setIsSubmitting(false)

    }


    async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        console.log(customizationObjs)
        if (step !== 'optionStep' && step !== 'completed') {
            toast({
                title: 'Not completed',
                description: 'Please complete the Steps before submit',
            })
            return;
        }


        const optionArray = customizationObjs.filter(item => item.type === 'normal')
        if (optionArray.length === 0) {
            toast({
                title: 'Options not availble',
                description: 'Please create atlease one option',
            })
            return;
        }
        setStep('completed')

        // if edit mode then update also in cloude
        console.log(customizationId)
        if (mode === 'edit') {

            setShowSubmitConformationModal(true)


        } else {
            setShow(false)

        }




    }

    function convertToCustomizationVariationLevels(data: ProductCustomizationOptionClient[]): customizationVariationLevels {
        // Step 1: Filter the data
        const dropDownData = data.filter(item => item.type === 'dropDown');

        // Step 2: Group by customization_name
        const groupedData = dropDownData.reduce((acc: { [key: string]: ProductCustomizationOptionClient[] }, item) => {
            if (!acc[item.customization_name]) {
                acc[item.customization_name] = [];
            }
            acc[item.customization_name].push(item);
            return acc;
        }, {});

        // Step 3: Create customizationVariation objects
        const customizationVariations: customizationVariation[] = Object.keys(groupedData).map((customizationName, index) => {
            const items = groupedData[customizationName];
            const selectedObj = (items.find(item => item.isDefault === 1))!;
            const selectedValue = selectedObj?.value
            if (selectedValue) {
                setSelectedVarientId(selectedObj.varientID)
            }
            const isDefault = items.some(item => item.isDefault === 1) ? 1 : 0;



            return {
                index: index,
                variationName: customizationName,
                selectedValue: selectedValue!,
                variationOptions: items,
                isDefault: isDefault
            };
        });
        setVariationLevels(customizationVariations)
        return customizationVariations;
    }


    useEffect(
        () => {
            if (customizationObjs?.length) {
                convertToCustomizationVariationLevels(customizationObjs)
            }
        }, [updateVariable]
    )


    return (
        <AdminModal show={show} setShow={handleChangeModal} >
            <AdminModalContent className='flex flex-col p-2 !max-h-[100vh] sm:!max-w-[100vw] '>

                <AdminModalHeader>
                    <p className=' font-black text-xl text-center '>Customization Creation</p>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-none w-[90vw]'>

                    {/* SUBMIT MODAL */}
                    <SubmitConfirmationModal
                        isSubmitting={isSubmitting}
                        onSubmit={updateCustomizationCloude}
                        setShow={setShowSubmitConformationModal}
                        show={showSubmitConfirmationModal}
                    />

                    <Label>Customization Name</Label>
                    <div className="pt-2"></div>
                    <Input
                        id="name"
                        name={'customizationName'}
                        value={customizationName}
                        placeholder='customization name'
                        onChange={(e) => setCustomizationName(e.target.value)}
                        className=' max-w-52'
                        disabled={step !== 'variationStep'}
                    />
                    <div className=' my-2 text-xl'><span className=' font-bold mr-1'>Step 1:</span>Variations creation</div>
                    {/* ADD VARIATION MODAL */}
                    <div className='' >
                        <AddOptionModal
                            onCreateVariation={addVariation}
                            variantId='1'
                            open={variation_modal}
                            setOpen={setvariation_modal}
                            disabled={step != 'variationStep'}
                        />
                    </div>
                    {/* EDIT VARIATION MODAL */}
                    <EditVariationModal
                        open={show_edit_variation_modal}
                        setOpen={set_show_edit_variation_modal}
                        variationIndex={selected_variation_index}
                        onEditVariation={(number) => { }}
                        variationLevels={variatonLevels}
                        setVariationLevels={setVariationLevels}
                    />
                    {/* EDIT VARIATION OPTION MODAL */}
                    <EditVariationOptionModal
                        open={show_edit_variation_option_modal}
                        setOpen={set_show_edit_variation_option_modal}
                        variationIndex={selected_variation_index}
                        onEditVariation={(number) => { }}
                        variationLevels={variatonLevels}
                        optionIndex={selected_variation_option_index}
                        setVariationLevels={setVariationLevels}
                    />
                    {/* CANVAS */}
                    <div className=' max-w-[1200px] md:px-6 px-1'>
                        {
                            variatonLevels.map(
                                (variation, index) => (
                                    <div key={variation.index} className='flex flex-col py-3'>
                                        <div className=' flex p-2 gap-2'>
                                            <div className=' font-bold'>{variation.variationName}</div>

                                            <ButtonIcon onClick={() => {
                                                set_selected_variation_index(index);
                                                set_show_edit_variation_modal(true);
                                            }} disabled={step != 'variationStep'} toolTipText='Edit' className='w-6 h-6' icon={<EditIcon className=' h-5 w-5' />} />

                                            {/* DELETE VARIATION */}
                                            <ButtonIcon onClick={() => {
                                                alertModal.open_alert_modal({
                                                    content: `Do you want to delete ${variation.variationName} ?`,
                                                    footer: <div className=' flex gap-1'>
                                                        <Button variant={'outline'} onClick={alertModal.close_alert_modal} >Cancel</Button>
                                                        <Button variant={'destructive'} onClick={() => deleteVariation(variation.variationName, index)} >Yes</Button>
                                                    </div>,
                                                    title: 'Delete?'
                                                })
                                            }} disabled={step != 'variationStep'} toolTipText='Delete' className='w-6 h-6' icon={<Trash className=' h-5 w-5' />} />

                                        </div>
                                        <Tabs
                                            value={variation.selectedValue}
                                            onValueChange={(value) => { handleChangeVariatonOptionTab(variation.variationName, value) }}
                                            className="w-full "
                                        >
                                            <div className=' flex gap-1'>
                                                <TabsList className="flex justify-start w-min">
                                                    {
                                                        variation.variationOptions.map(
                                                            (variationOption, Optionindex) => (
                                                                <TabsTrigger key={Optionindex} value={variationOption.value!}>
                                                                    <div className=' flex gap-1'>
                                                                        {variationOption.value}
                                                                        <ButtonIcon onClick={() => {
                                                                            set_selected_variation_index(index);
                                                                            set_selected_variation_option_index(Optionindex)
                                                                            set_show_edit_variation_option_modal(true);
                                                                        }} disabled={step != 'variationStep'} toolTipText='Edit' className='w-6 h-6' icon={<EditIcon className=' h-5 w-5' />} />

                                                                        {/* DELETE VARIATION OPTION */}
                                                                        <ButtonIcon onClick={() => {
                                                                            alertModal.open_alert_modal({
                                                                                content: `Do you want to delete ${variation.variationName} ?`,
                                                                                footer: <div className=' flex gap-1'>
                                                                                    <Button variant={'outline'} onClick={alertModal.close_alert_modal} >Cancel</Button>
                                                                                    <Button variant={'destructive'} onClick={() => deleteVariationOption(index, Optionindex)} >Yes</Button>
                                                                                </div>,
                                                                                title: 'Delete?'
                                                                            })
                                                                        }} disabled={step != 'variationStep'} toolTipText='Delete' className='w-6 h-6' icon={<Trash className=' h-5 w-5' />} />
                                                                    </div>
                                                                </TabsTrigger>
                                                            )
                                                        )
                                                    }
                                                </TabsList>
                                                <AddVariationOptionModal
                                                    onCreateVariation={addVariationOption}
                                                    variantName={variation.variationName}
                                                    open={Variation_option_modal}
                                                    setOpen={setVariation_option_modal}
                                                    variantId=''
                                                    disabled={step != 'variationStep'}
                                                />
                                            </div>
                                        </Tabs>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className='text-center'><Button disabled={step != 'variationStep'} onClick={gotoStep2} ><ArrowDownToDot /> Go To Step 2</Button></div>
                    <div ref={step2ref} className=' my-2 text-xl'><span className=' font-bold mr-1'>Step 2:</span>Options creation</div>
                    <Step2Component
                        isStepActive={step === 'optionStep'}
                        customizationOptions={customizationObjs}
                        varientID={selectedVarientID}
                        setCustomizationOptions={setCustomizationObjs}
                        addOption={addOption}
                        deleteOption={deleteOption}
                        setStep={setStep}
                        editOption={editOption}
                    />
                </AdminModalMiddle>

                <AdminModalFooter>
                    <Button variant={'outline'} onClick={() => handleChangeModal(false)} >Cancel</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </AdminModalFooter>

            </AdminModalContent>
        </AdminModal>
    );
}
