import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet-async';

export function ShippingPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className=" px-2  sm:container py-8">
                <Helmet>
                    <link rel="canonical" href={window.location.href} />

                </Helmet>
                <h1 className="text-3xl font-bold mb-6 ">Shipping Policy</h1>
                <p className="text-sm ">Last updated: October 9, 2024</p>

                <section className="mt-8">
                    <p className="">
                        At <strong>ChefSouq</strong>, we strive to deliver your orders in a timely and efficient manner. This Shipping Policy outlines the terms and conditions related to the shipment of products purchased from our website.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Order Processing</h2>
                    <p className="">
                        Orders are typically processed within 1-2 business days (Monday-Friday) after payment is confirmed. You will receive a confirmation email once your order has been shipped, along with tracking details if applicable.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Shipping Methods and Delivery Times</h2>
                    <p className="">
                        We offer various shipping options to meet your needs. Delivery times are calculated from the date of dispatch:
                    </p>
                    <ul className="list-disc list-inside mt-2 ">
                        <li>Standard Shipping: Estimated delivery within 3-7 business days.</li>
                        <li>Express Shipping: Estimated delivery within 1-3 business days.</li>
                        <li>Same-Day Delivery: Available for select locations on orders placed before 12:00 PM local time.</li>
                    </ul>
                    <p className=" mt-4">
                        Please note that delivery times may vary based on the destination, carrier delays, or other unforeseen factors.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Shipping Charges</h2>
                    <p className="">
                        Shipping costs will be calculated at checkout based on the weight, dimensions, and destination of the package. Free shipping is available on orders over AED 250 within the UAE.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">International Shipping</h2>
                    <p className="">
                        Currently, we only offer shipping within the United Arab Emirates. We are working to expand our services to international customers and will update this policy once available.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Order Tracking</h2>
                    <p className="">
                        Once your order has been shipped, you will receive a tracking number via email. You can use this tracking number to monitor your order's delivery status on the courier’s website.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Shipping Restrictions</h2>
                    <ul className="list-disc list-inside mt-2 ">
                        <li>We do not ship to P.O. Boxes or military addresses.</li>
                        <li>Orders cannot be shipped to multiple addresses. If you need to ship to multiple locations, please place separate orders.</li>
                    </ul>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Delivery Issues</h2>
                    <p className="">
                        If your package is delayed, lost, or damaged during transit, please contact our support team at <a href="mailto:support@chefssouq.com" className="text-blue-600">support@chefssouq.com</a> within 7 days of the expected delivery date. We will work with the carrier to resolve the issue and ensure you receive your items as quickly as possible.
                    </p>
                </section>

                <section className="mt-6">
                    <h2 className="text-2xl font-semibold  mb-4">Changes to this Shipping Policy</h2>
                    <p className="">
                        We may update our Shipping Policy from time to time. We will notify you of any changes by posting the updated policy on this page.
                    </p>
                    <p className=" mt-4">
                        If you have any questions about this Shipping Policy, please contact us at <a href="mailto:support@chefssouq.com" className="text-blue-600">support@chefssouq.com</a>.
                    </p>
                </section>

            </div>
            <Footer />
        </>
    )
}
