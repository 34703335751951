import { IconDeviceMobileFilled, IconMailFilled, IconMapPinFilled } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="bg-[#263238] text-gray-300 pt-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Main footer section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-8 pb-10 border-b border-gray-600">
                    {/* Company Information */}
                    <div>
                        <h2 className="text-2xl font-bold text-white mb-4">About ChefSouq</h2>
                        <p className="text-gray-400 leading-relaxed">
                            ChefSouq is your go-to online marketplace for premium kitchenware and fresh ingredients, delivering high-quality products straight to your doorstep.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div>
                        <h2 className="text-2xl font-bold text-white mb-4">Quick Links</h2>
                        <ul className="space-y-3">
                            <li>
                                <Link to={"/aboutus"} className="hover:text-white transition duration-300">About Us</Link>
                            </li>
                            <li>
                                <Link to="/return-refund-policy" className="hover:text-white transition duration-300">Return-refund Policy</Link>
                            </li>
                            <li>
                                <Link to="/shippingPolicy" className="hover:text-white transition duration-300">Shipping Policy</Link>
                            </li>
                            <li>
                                <Link to="/PrivacyPolicy" className="hover:text-white transition duration-300">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    {/* Social Media */}
                    <div>
                        <h2 className="text-2xl font-bold text-white mb-4">Contact Us</h2>
                        <ul className="space-y-3">
                            <li>
                                <a href="mailto:support@chefssouq.com" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
                                    support@chefssouq.com
                                </a>
                            </li>
                            <li>
                                <a href="tel:+971569186717" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
                                    +971 56 918 6717
                                </a>
                            </li>
                            <li>
                                <a href="https://www.google.com/maps/dir//Musaffah+-+Abu+Dhabi+-+United+Arab+Emirates/@24.3492387,54.4451913,10173m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x3e5e40e7483d11d5:0x7672bbc1a32c03c2!2m2!1d54.4919161!2d24.35894?entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
                                    United Arab Emirates
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Bottom footer section */}

            </div>
            <div className="flex flex-col md:flex-row justify-between items-center py-5 mt-5 px-3 bg-[#ffffff]">
                <p className="text-sm text-[#263238]">&copy; 2024 ChefSouq. All rights reserved.</p>
                <div className="mt-5 md:mt-0">
                    <img className="h-8" src="/assets/payment-methods.png" alt="Payment Methods" />
                </div>
            </div>
        </footer>
    )
}
