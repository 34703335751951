import React, { useEffect } from 'react';
import { VideoSection } from './components/top_video';
import { Link } from 'react-router-dom';
import SolutionSection from './components/SolutionSection';
import gsap from "gsap";

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import HeaderSection from './components/headerSection';
import Footer from '../../components/Footer/Footer';
import ServiceSection from './components/serviceSection';
import ProcessSection from './components/processSection';
import { Helmet } from 'react-helmet-async';

gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-50">
            <Helmet>
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            {/* Header Section */}
            <HeaderSection />

            {/* Main Image Section */}
            <VideoSection videoSrc='/assets/aboutusPage/aboutusvideo.mp4' />

            {/* Kitchenware Solutions Section */}

            <SolutionSection />


            {/* Services Section */}
            <ServiceSection />

            {/* Team Section */}
            {/* <section className="container mx-auto py-16">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold">Meet Our Kitchenware Experts</h2>
                </div>
                <div className="grid md:grid-cols-3 gap-8">
                    <div className="bg-gray-200 h-64 rounded"></div> 
                    <div className="bg-gray-200 h-64 rounded"></div> 
                    <div className="bg-gray-200 h-64 rounded"></div> 
                </div>
            </section> */}

            {/* Case Study Section */}
            <section className="container mx-auto py-16">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold">Our Success Stories</h2>
                    <p className="mt-4 max-w-2xl mx-auto">
                        Discover how our kitchenware solutions have transformed the culinary operations of top hotels and homes alike.
                    </p>
                </div>

                {/* Chef Souq Store Description */}
                <div className="bg-white p-8 shadow-lg rounded-lg max-w-[1000px] mx-auto" style={{
                    backgroundImage: 'url(https://i0.wp.com/backgroundabstract.com/wp-content/uploads/edd/2024/07/566-01.jpg?resize=600%2C450&ssl=1)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'

                }}>
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">ChefSouq Store: Unmatched Kitchen Equipment Quality</h3>
                    <p className="text-gray-700 mb-6">
                        The ChefSouq store is an online platform that provides customers with an opportunity to purchase kitchen equipment and tools used in the restaurant industry with ease and convenience. It aims to meet the needs of the food sector and offers a reliable shopping experience.
                    </p>
                    <p className="text-gray-700 mb-6">
                        Our store features a wide variety of products, including refrigerators, ovens, grills, food processors, utensils, and more, all showcased with detailed descriptions and high-quality photos. Customers can conveniently browse and explore equipment tailored to the needs of professionals in the food industry.
                    </p>
                    <p className="text-gray-700 mb-6">
                        ChefSouq ensures a smooth and secure purchasing process, with safe payment options and reliable worldwide delivery. In addition, we provide product information, customer reviews, and promotions to help you make informed decisions before purchasing.
                    </p>
                    <p className="text-gray-700">
                        Whether you're looking to upgrade your kitchen or outfit a new restaurant, ChefSouq is here to provide an unparalleled selection of premium kitchen tools, delivering convenience and reliability right to your doorstep.
                    </p>
                </div>
            </section>

            {/* Process Section */}
            <ProcessSection />
            <Footer />
        </div>
    );
};

export { AboutPage };
