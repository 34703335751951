import React, { useEffect, useRef, useState } from 'react';
// import UserAddress from '../../components/userAddress/userAddress';
import { AddressComponent } from './components/address';
import { useProductSKU } from '@/src/hooks/product_sku';
import { Link, Location, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomizationOptionStored } from '@/src/types/local_storage';
import { CustomerAddress, OrderType, ProductVariation, ProductVariationOption } from '@/src/types';
import { RootState } from '@/src/redux/store';
import { useSelector } from 'react-redux';
import { IconLoader } from '@tabler/icons-react';
import { StandardCartCard } from '../../components/cartCard';
import axios from 'axios';
import { useRESTapi } from '@/src/hooks/rest_API';
import { Button } from '@/src/components/ui/button';
import { Loader2 } from 'lucide-react';
import { ConformedModal } from './components/conformedModal';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { useCustomizationApi } from '@/src/hooks/customizaton_api';
import { paymentTypeId } from '@/src/types/databaseFeeds';
import { useTaxCalculation } from '@/src/hooks/tax_ calculation';
import { useDeliveryChargeCalculation } from '@/src/hooks/delivery_charge_ calculation';
import { WhatsappButtonList, WhatsappHoverList } from '@/src/components/buttons/buttonIcon';
import { Helmet } from 'react-helmet-async';
// import AddressComponent from './components/address'; 

export type Step = 'address' | 'orderSummery' | 'paymentOption' | 'payment' | 'ordered';

type selectedProduct = {
    productItemId: string,
    priceMRP: number,
    priceOg: number,
    quantity: number,
    name: string,
    availableQuantity: number,
    customizationOption?: CustomizationOptionStored[],
    thumbnailImage: string,
    variations: ({
        variation_option: ProductVariationOption
    } & ProductVariation)[],
    customizationAvailable: boolean,
    customizationId: string | null,
    sku: string
}

type totals = {
    totalPrice: number;
    discount: number;
    deliveryCharge: number,
    tax: number,
    totalMRP: number,
}


export default function Checkout() {

    const [quantity, setQuantity] = useState(1);
    const [orderDetails, setOrderDetails] = useState<OrderType>()
    const customerData = useSelector((state: RootState) => state.customer.customerData)
    const [step, setStep] = useState<Step>('address')
    const { productId } = useParams()
    const { findTotalValue } = useCustomizationApi()
    const [isLoading, setIsLoading] = useState(false)
    const [addressLoading, setAddressLoading] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<selectedProduct>()
    const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>()
    const alertModal = useAlertModal()
    const [customizationObjs, setCustomizationObjs] = useState<CustomizationOptionStored[]>([])
    const [orderConformingLoading, setOrderConformingLoading] = useState<boolean>()
    const taxConfig = useTaxCalculation()
    const deliveryConfig = useDeliveryChargeCalculation()
    const [totalPrices, setTotalPrices] = useState<totals>({
        deliveryCharge: NaN,
        discount: NaN,
        totalPrice: NaN,
        tax: NaN,
        totalMRP: NaN
    })
    const paymentMethodRef = useRef<HTMLDivElement>(null)
    const locationRouter = useLocation()
    // console.log(locationRouter.pathname);
    const {
        close_alert_modal,
        open_alert_modal
    } = useAlertModal()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | number>(paymentTypeId.CashOnDelivery)
    const navigate = useNavigate()
    const api = useRESTapi();
    const currency = useSelector((state: RootState) => state.configuration.currencyDetails)
    const {
        get_skus_by_ids
    } = useProductSKU()

    const paymentMethods = [
        {
            P15_PaymentTypeID: 1,
            P15_Value: "cash on delivery",
            P15_provider_key: ""
        },
        // {
        //     P15_PaymentTypeID: 2,
        //     P15_Value: "apple pay",
        //     P15_provider_key: "apple_pay"
        // },
        // {
        //     P15_PaymentTypeID: 3,
        //     P15_Value: "google pay",
        //     P15_provider_key: "google_pay"
        // },
        {
            P15_PaymentTypeID: 4,
            P15_Value: "Online payment",
            P15_provider_key: "card"
        }
    ]

    async function addQuantity() {
        if (selectedProduct && (selectedProduct.quantity < selectedProduct.availableQuantity || selectedProduct.customizationAvailable)) {
            const product: selectedProduct = {
                ...selectedProduct!,
                quantity: selectedProduct!.quantity + 1
            }
            setSelectedProduct(product);
            await calculateTotal(product)
        }
    }

    async function lessQuantity() {
        if (selectedProduct!.quantity > 1) {
            const product: selectedProduct = {
                ...selectedProduct!,
                quantity: selectedProduct!.quantity - 1
            }
            setSelectedProduct(product);
            await calculateTotal(product)

        }
    }



    function changeStep(step: Step) {
        setStep(step);
    }

    const calculateTotal = async (productDetails: selectedProduct) => {

        setIsLoading(true)
        const totals: totals = {
            deliveryCharge: NaN,
            discount: (productDetails.priceMRP - productDetails.priceOg) * productDetails.quantity,
            totalPrice: productDetails.priceOg * productDetails.quantity,
            totalMRP: NaN,
            tax: NaN
        }
        totals.totalMRP = totals.totalPrice + totals.discount
        // DELIVERY CHARGE CALCULATION
        const res2 = await deliveryConfig.calculateDeliveryCharge({
            grossAmount: totals.totalPrice,
        })

        if (res2.success === true) {
            totals.deliveryCharge = res2.data!.delivery_charge
        } else {
            alertModal.open_alert_modal({
                content: res2.message || 'Error calculating delivery charge',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }

        // TAX CHARGE CALCULATION
        const res = await taxConfig.calculateTax({
            grossAmount: totals.totalPrice,
            netAmount: totals.deliveryCharge + totals.totalPrice
        })

        if (res.success === true) {
            totals.tax = res.data!.tax_amount
        } else {
            alertModal.open_alert_modal({
                content: res.message || 'Error loading tax',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }



        setIsLoading(false)
        setTotalPrices(totals)

    }

    const loadProductSelected = async () => {
        if (productId) {

            get_skus_by_ids({
                ids: [productId]
            }).then(
                async (products) => {
                    if (products && products.length) {
                        if ((products[0].customer_stock_quantity <= 0 && products[0].M08_is_customization_available == 0)) {
                            open_alert_modal({
                                content: 'product not availble now',
                                footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
                                title: 'product not availble now'
                            })
                            navigate('/')

                            return
                        }
                        const product: selectedProduct = {
                            availableQuantity: products[0].customer_stock_quantity,
                            priceOg: Number(products[0].price.M10_List_price),
                            priceMRP: Number(products[0].price.M10_MRP_price),
                            quantity: 1,
                            thumbnailImage: `${process.env.REACT_APP_BACKEND_URL}/${products[0].M08_ProductImage! as string}`,
                            variations: products[0].variations,
                            customizationOption: [],
                            name: products[0].M08_ProductItemName,
                            productItemId: String(products[0].M08_ProductItemID),
                            customizationId: products[0].M08_M21_product_customizationId ? String(products[0].M08_M21_product_customizationId) : null,
                            customizationAvailable: products[0].M08_is_customization_available == 1 ? true : false,
                            sku: products[0].M08_SKU as string
                        }



                        if (product.customizationAvailable) {
                            if (locationRouter.state?.customizationObjs) {
                                product.customizationOption = [...locationRouter.state.customizationObjs]
                                const totalVal = findTotalValue(product.priceOg, product.customizationOption!)
                                product.priceMRP = totalVal + product.priceMRP - product.priceOg
                                product.priceOg = totalVal
                            } else {
                                // check if payment is succesfull in prepaid
                                if (!locationRouter.pathname.includes('/paymentSuccessful'))
                                    open_alert_modal({
                                        content: 'customization not selected for this product',
                                        footer: <div><Button onClick={() => {
                                            close_alert_modal()
                                            navigate('/')
                                        }} >Ok</Button></div>,
                                        title: 'No customization found'
                                    })
                            }
                        }
                        console.log(product)

                        await calculateTotal(product)
                        setSelectedProduct(
                            product
                        )
                    } else {
                        console.log('no quantity or no product found')

                        open_alert_modal({
                            content: 'product not availble now',
                            footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
                            title: 'product not availble now'
                        })
                        navigate('/')
                    }

                }
            )
        }
    }

    const reviewPurchase = async () => {
        setOrderConformingLoading(true)
        try {
            if (selectedProduct && selectedAddress) {
                let requiestData: any
                if (selectedPaymentMethod == paymentTypeId.CashOnDelivery) {
                    requiestData = {
                        customerAddress: String(selectedAddress.M02_CustomerAddressID),
                        shippingMethod: 1,
                        order_source: 'e-commerce website',
                        shipvia: 1,
                        finalPrice: totalPrices.totalPrice,
                        discountPrice: totalPrices.discount,
                        deliveryCharge: totalPrices.deliveryCharge,
                        taxAmount: totalPrices.tax,
                        products: [
                            {
                                productItemId: selectedProduct.productItemId,
                                quantity: selectedProduct.quantity,
                            }
                        ],
                        userId: customerData.M01_CustomerID
                    }
                } else {
                    requiestData = {
                        customerAddress: String(selectedAddress.M02_CustomerAddressID),
                        shippingMethod: 1,
                        paymentMethod: selectedPaymentMethod,
                        taxAmount: totalPrices.tax,
                        order_source: 'e-commerce website',
                        shipvia: 1,
                        finalPrice: totalPrices.totalPrice,
                        discountPrice: totalPrices.discount,
                        deliveryCharge: totalPrices.deliveryCharge,
                        cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}${locationRouter.pathname}`,
                        successUrl: `${process.env.REACT_APP_FRONTEND_URL}${locationRouter.pathname}/paymentSuccessfull`,
                        products: [
                            {
                                productItemId: selectedProduct.productItemId,
                                quantity: selectedProduct.quantity,
                            }
                        ],
                        userId: customerData.M01_CustomerID

                    }
                }
                console.log(selectedProduct);
                if (selectedProduct.customizationAvailable) {
                    requiestData.products[0].customizationID = selectedProduct.customizationId;
                    requiestData.products[0].varientID = selectedProduct.customizationOption && selectedProduct.customizationAvailable ? selectedProduct.customizationOption[0].varientID : null;
                    requiestData.products[0].customizationOption = selectedProduct.customizationOption ? selectedProduct.customizationOption.map(
                        (item) => ({
                            customization_option_id: item.id,
                            inputValue: item.Inputvalue,
                            customization_name: item.name,
                            value: item.value,
                            type: item.type,
                            varientID: item.varientID,
                            isSlab: item.isSlab ? 1 : 0,
                            rate: item.rate,
                            hikePerc: item.hikePerc,
                            hikePercInRate: item.hikePercInRate,
                            minVal: item.minVal,
                            maxVal: item.maxVal,
                            order: item.order,
                            baseRate: item.baseRate
                        })
                    ) : null
                }

                console.log(requiestData)


                if (selectedPaymentMethod == paymentTypeId.CashOnDelivery) {
                    const res = await api.post<{ success: boolean, message: string, data: OrderType }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/perform_buy`,
                        mode: 'customerPrivate',
                        body: requiestData
                    })
                    if (res.data.success === true) {
                        navigate(`${locationRouter.pathname}/paymentSuccessfull`, { relative: 'path' })
                        setStep('ordered')
                        setOrderDetails(res.data.data)
                    } else {
                        open_alert_modal({
                            title: 'Error Placing Orders',
                            content: res.data.message,
                            footer: <Button onClick={close_alert_modal}>Ok</Button>
                        })
                    }
                } else {
                    const res = await api.post<{ success: boolean, message: string, sessionUrl: string, sessionId: string, data: OrderType }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/perform_buy_prepaid`,
                        mode: 'customerPrivate',
                        body: requiestData
                    })
                    if (res.data.success === true) {
                        window.location.href = res.data.sessionUrl
                        setStep('ordered')
                        setOrderDetails(res.data.data)
                    } else {
                        open_alert_modal({
                            title: 'Error Placing Orders',
                            content: res.data.message,
                            footer: <Button onClick={close_alert_modal}>Ok</Button>
                        })
                    }
                }

            }
        } catch (error) {
            console.log(error)
            alert('server error')
        }
        setOrderConformingLoading(false)
    }

    const on_proceed_click = () => {
        if (selectedAddress && selectedProduct && paymentMethodRef.current) {
            paymentMethodRef.current.scrollIntoView({ behavior: 'smooth' })
            setStep('paymentOption')
        }
    }

    const onChangePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedPaymentMethod(e.target.value)


    }
    // console.log(selectedPaymentMethod);  


    useEffect(
        () => {

            loadProductSelected()


        }, [productId]
    )


    return (
        <div className=' relative py-[46px] bg-[#cfd8dc] w-full min-h-[100vh] h-auto px-6 xl:px-32'>

            <Helmet>
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            {selectedProduct ? <WhatsappHoverList
                products={[
                    {
                        link: `${process.env.REACT_APP_FRONTEND_URL}/${selectedProduct.name}/product/${selectedProduct.productItemId}`,
                        sku: selectedProduct.sku
                    }
                ]}
            /> : null}
            {(isLoading || addressLoading === true) && <div className=' absolute inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                <div className=' animate-spin '><IconLoader /></div>
            </div>}
            <div className='text-lg font-bold pt-3 pb-6'>Secure Checkout</div>
            <div className='md2:flex-row flex flex-col  gap-6'>
                <div className='md2:w-[60%] w-[100%] gap-2 flex flex-col'>

                    {/* Address section  */}
                    <AddressComponent
                        isFold={step !== 'address'}
                        changeStep={changeStep}
                        setAddressLoading={setAddressLoading}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                    />
                    {/* product overview with fold container */}
                    <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                        <div className="p-5">
                            <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                                <span className='font-bold'>ORDER SUMMERY</span>
                                {step !== 'orderSummery' && selectedProduct !== undefined && selectedAddress !== undefined && <button
                                    type="button"
                                    onClick={() => changeStep('orderSummery')}
                                    className="text-blue-500"
                                >
                                    Show
                                </button>}
                            </h5>
                        </div>
                        <div className={`overflow-hidden transition-all duration-1000 ease-in-out ${step === 'orderSummery' ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            <div className="p-5 gap-2 flex-col flex">


                                {selectedProduct
                                    ? <StandardCartCard
                                        checked
                                        image={selectedProduct?.thumbnailImage}
                                        onDicreaseCount={lessQuantity}
                                        onIncreaseCount={addQuantity}
                                        onSelect={() => { }}
                                        price={selectedProduct.priceOg}
                                        productName={selectedProduct.name}
                                        product_id={selectedProduct.productItemId}
                                        quantity={selectedProduct.quantity}
                                        removeFromCart={() => { }}
                                        savePrice={selectedProduct.priceMRP - selectedProduct.priceOg}
                                        variations={selectedProduct.variations.map(
                                            variation => ({
                                                option: {
                                                    option_id: String(variation.variation_option.M07_VariationOptionID),
                                                    option_name: variation.variation_option.M07_Value
                                                },
                                                variation_id: String(variation.M06_VariationID),
                                                variation_name: variation.M06_Name,

                                            })
                                        )}
                                        customizationOption={selectedProduct.customizationOption}
                                        mode='RemoveBtnRemove'
                                        availableQuantity={selectedProduct.availableQuantity ? selectedProduct.availableQuantity : 0}
                                        isCustomizationAvailable={selectedProduct.customizationAvailable ? 1 : 0}
                                    />
                                    : null}
                                <div>
                                    <button onClick={() => changeStep('paymentOption')} className='px-4 py-2 bg-[orange] rounded-md'>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* payment method  */}


                    <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                        <div className="p-5">
                            <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                                <span className='font-bold'>PAYMENT METHODS</span>
                                {step !== 'paymentOption' && selectedAddress !== undefined && <button
                                    type="button"
                                    onClick={() => changeStep('paymentOption')}
                                    className="text-blue-500"
                                >
                                    Show
                                </button>}
                            </h5>
                        </div>
                        <div ref={paymentMethodRef} className={`overflow-hidden transition-all duration-1000 ease-in-out ${step === 'paymentOption' ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            <div className="p-5 flex flex-col gap-2">
                                {selectedProduct ? <WhatsappButtonList
                                    products={[
                                        {
                                            link: `${process.env.REACT_APP_FRONTEND_URL}/${selectedProduct.name}/product/${selectedProduct.productItemId}`,
                                            sku: selectedProduct.sku
                                        }
                                    ]}
                                /> : null}
                                {
                                    paymentMethods.map(
                                        method => (
                                            <div className="block rounded-lg p-5 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                                                <div className='flex h-full w-full gap-2'>

                                                    <input type="radio" value={method.P15_PaymentTypeID} onChange={onChangePaymentMethod} checked={selectedPaymentMethod == String(method.P15_PaymentTypeID)} />
                                                    {method.P15_Value}
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                <button disabled={orderConformingLoading} onClick={reviewPurchase} className='px-4 py-2 bg-[orange] rounded-md flex justify-center'>{orderConformingLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Confirm Order</button>

                            </div>

                        </div>

                    </div>

                </div>


                {/* OrderSummery div right  */}
                <div className="mx-auto  w-[100%] md2:max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                    <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                        <p className="text-xl font-semibold text-gray-900 dark:text-white">Order summary</p>
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Original price</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.totalMRP}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Savings</dt>
                                    <dd className="text-base font-medium text-green-600">{totalPrices.discount}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Store Pickup</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.deliveryCharge}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Tax</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.tax}</dd>
                                </dl>
                            </div>
                            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                                <dt className="text-base font-bold text-gray-900 dark:text-white">Total</dt>
                                <dd className="text-base font-bold text-gray-900 dark:text-white"><span className=' text-xs font-normal pr-1'>{currency.M05_CurrencySymbol}</span>{selectedProduct ? totalPrices.deliveryCharge + totalPrices.totalMRP - totalPrices.discount + totalPrices.tax : NaN}</dd>
                            </dl>
                        </div>
                        {
                            step !== 'paymentOption' && step !== 'payment' && (
                                <>
                                    <Button
                                        disabled={selectedProduct === undefined || selectedAddress === undefined}
                                        className=" flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 disabled:bg-primary-300 disabled:cursor-not-allowed dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                        onClick={on_proceed_click}
                                    >Proceed to Buy</Button>
                                    <div className="flex items-center justify-center gap-2">
                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400"> or </span>
                                        <Link to={'/'} className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
                                            Continue Shopping
                                            <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 12H5m14 0-4 4m4-4-4-4" />
                                            </svg>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                {/* <ConformedModal
                    isOpen={step === 'ordered'}
                /> */}
                <Routes>
                    <Route path='/paymentSuccessfull' element={<ConformedModal orderDetails={orderDetails} isOpen />} />
                </Routes>
            </div>
        </div>
    );
}