import React, { useEffect, useState } from "react";
import { IconSearch, IconChevronDown, IconLoader } from "@tabler/icons-react";
import {
  OrderStatus,
  ProductCustomizationOptionOrder,
  ProductItem,
  ProductVariation,
  ProductVariationOption,
  SalesOrder,
  SalesOrderLine,
} from "@/src/types";
import { ColorChange } from "./colorChange";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useRESTapi } from "@/src/hooks/rest_API";
import { useSelector } from "react-redux";
import { RootState } from "@/src/redux/store";
import BottomModal from "./components/BottomModal/bottomModal";
import { useDebounce } from "@/src/hooks/debounce_hook";
import { orderStatusesIds } from "@/src/types/databaseFeeds";
import { Helmet } from "react-helmet-async";

type variationDetails = ProductVariation & {
  variation_option: ProductVariationOption;
};

type OrderDetails = SalesOrder & {
  order_status: OrderStatus;
  orderLines: (SalesOrderLine & {
    productItem: ProductItem & {
      variations: variationDetails[];
    };
    customizations: ProductCustomizationOptionOrder[];
  })[];
};

export enum filterObjectTypeKeys {
  orderId = 'orderId',
  OrderStatus = 'OrderStatus',
}

export type filterObjectType = {
  orderId?: string,
  OrderStatus?: string,
}

export function ServicePage() {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails[]>([]);
  const navigate = useNavigate();
  const currency = useSelector(
    (state: RootState) => state.configuration.currencyDetails
  );
  const [update, setUpdate] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterObject, setfilterObject] = useState<filterObjectType>({
    orderId: searchParams.get(filterObjectTypeKeys.orderId) ? searchParams.get(filterObjectTypeKeys.orderId)! : '',
    OrderStatus: searchParams.get(filterObjectTypeKeys.OrderStatus) ? searchParams.get(filterObjectTypeKeys.OrderStatus)! : ''
  })
  const debouncedSearchTerm = useDebounce<string>(filterObject.orderId ? filterObject.orderId : '', 600);


  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };



  const [isLoading, setIsLoading] = useState(false);
  const api = useRESTapi();

  const onchangeOrderStatusFilter: React.ChangeEventHandler<HTMLInputElement> = (e) => {

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(filterObjectTypeKeys.OrderStatus, e.target.value);
    navigate(`?${newSearchParams.toString()}`, { replace: true });
    console.log(e.target.value)
    setfilterObject(
      {
        ...filterObject,
        OrderStatus: e.target.value
      }
    )
    setUpdate(!update)

  }

  //get sku details full
  const get_orders = async () => {
    try {
      setIsLoading(true);
      const res = await api.get<{
        success: boolean;
        data: OrderDetails[];
        message: string;
      }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/get_service_orders`,
        mode: "customerPrivate",
        params: {
          searchTerm: searchParams.get(filterObjectTypeKeys.orderId),
          orderStatus: searchParams.get(filterObjectTypeKeys.OrderStatus) ? searchParams.get(filterObjectTypeKeys.OrderStatus) : undefined,
        },
      });

      if (res.data.success === true) {
        setOrderDetails(res.data.data);
        console.log("api data", orderDetails);
      } else if (res.data.success === false) {
        alert(res.data.message || "something went wrong");
      }
    } catch (error) {
      console.log(error);
      alert("server error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    get_orders();
  }, [debouncedSearchTerm, update]);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={window.location.href} />

      </Helmet>
      {isLoading === true && (
        <div className=" absolute inset-0 flex justify-center items-center z-[500] bg-gray-800/20">
          <div className=" animate-spin ">
            <IconLoader />
          </div>
        </div>
      )}
      <div className="flex justify-center bg-blue-gray-100 px-2 py-8 gap-4 min-h-[100vh] pt-[55px]">
        <div className="cursor-pointer  bg-white pb-2 w-[230px] h-max hidden lg:flex-col lg:flex rounded-md">
          <div>
            <div className=" px-2 py-2 font-bold text-lg">FILTERS</div>
            <div className=" px-2 py-2 font-bold">SERVICE STATUS</div>
            <div className=" px-2">
              <form action="" className="flex flex-col gap-2 ">
                <div className="flex gap-2">
                  <input onChange={onchangeOrderStatusFilter} value={''} checked={filterObject.OrderStatus === ''} type="checkbox" />
                  <label htmlFor="">All</label>
                </div>
                <div className="flex gap-2">
                  <input onChange={onchangeOrderStatusFilter} value={orderStatusesIds.bookedService} checked={filterObject.OrderStatus == orderStatusesIds.bookedService} type="checkbox" />
                  <label htmlFor="">Booked</label>
                </div>
                <div className="flex gap-2">
                  <input onChange={onchangeOrderStatusFilter} value={orderStatusesIds.confirmedService} checked={filterObject.OrderStatus == orderStatusesIds.confirmedService} type="checkbox" />
                  <label htmlFor="">Confirmed</label>
                </div>
                <div className="flex gap-2">
                  <input onChange={onchangeOrderStatusFilter} value={orderStatusesIds.completedService} checked={filterObject.OrderStatus == orderStatusesIds.completedService} type="checkbox" />
                  <label htmlFor="">Completed</label>
                </div>
                <div className="flex gap-2">
                  <input onChange={onchangeOrderStatusFilter} value={orderStatusesIds.cancelled} checked={filterObject.OrderStatus == orderStatusesIds.cancelled} type="checkbox" />
                  <label htmlFor="">Cancelled</label>
                </div>

              </form>
            </div>

            {/* <div className=" px-2 py-2 font-bold">ORDER TIME</div>
            <div className=" px-2">
              <form action="" className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">Last 1 weak</label>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">Last 30 days</label>
                </div>
                <div className="flex gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">Last 1 year</label>
                </div>
              </form>
            </div> */}
          </div>
        </div>

        {/* searchBar */}
        <div className="  gap-24 max-w-[1400px] ">
          <div className="flex justify-between">
            <div className="relative  h-min w-[80%] xl:w-[60%]">
              <input
                type="text"
                placeholder="Search your Services"
                className="bg-[#f0f5ff] w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                value={filterObject.orderId}
                onChange={
                  (e) => {
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.set(filterObjectTypeKeys.orderId, e.target.value);
                    navigate(`?${newSearchParams.toString()}`, { replace: true });
                    setfilterObject({
                      ...filterObject,
                      orderId: e.target.value
                    })
                  }
                }
              />
              <span className="absolute items-center inset-y-0 right-0 pr-3 flex text-gray-400">
                <IconSearch size={22} />
              </span>
            </div>
            <div className="lg:hidden flex">
              <BottomModal />
            </div>
          </div>

          {/* order cards */}
          {orderDetails.length === 0 ? (
            <div className="flex flex-col items-center lg:items-start justify-center h-full text-center text-gray-700 mt-[-150px] lg:ms-[200px]">
              <div className="flex-col justify-center items-center">
                <div className="mb-4 flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h18M9 3v18m6-18v18m6-6H3"
                    />
                  </svg>
                </div>
                <h1 className="text-2xl font-semibold mb-2">No Service Found</h1>
                <p className="text-gray-500">We couldn't find any Services. <Link to="/all-products/*">
                  <span className="text-blue-900 underline">go to Productpage</span>
                </Link></p>
              </div>
            </div>
          ) : (
            orderDetails.map((item, index) => {
              // const totalPrice = item.orderLines.reduce(
              //   (acc, currentItem) => acc + Number(currentItem.I21_Price),
              //   0
              // );

              return (
                <Link to={`/singleServiceDetails/${item.I19_order_id}`} key={index}>
                  <div className=" bg-white pl-5 flex justify-between mt-5 p-5 shadow-md hover:shadow-xl cursor-pointer rounded-lg flex-col md:flex-row gap-6">
                    {item.orderLines.map((lineItem, lineIndex) => (
                      <div key={lineIndex} className="flex flex-row w-full gap-3">
                        <div className="h-[80px] aspect-[1.9]">
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/${item.orderLines[0].productItem.M08_ProductImage}`}
                            alt=""
                            className="w-full h-full rounded-md"
                          />
                        </div>
                        <div className="flex flex-col lg:ml-0 lg:flex-row justify-normal lg:justify-between w-full">
                          <div>
                            <p className="font-semibold text-[13px] md:text-xl">
                              {lineItem.productItem.M08_ProductItemName}
                            </p>
                            {lineItem.productItem.variations.map((variationitem, variationIndex) => (
                              <div key={variationIndex}>
                                <p className="hidden lg:flex w-full">
                                  {variationitem?.M06_Name} : {variationitem?.variation_option?.M07_Value}
                                </p>
                              </div>
                            ))}
                            {/* <p>Quantity : {lineItem.I21_Quantity}</p> */}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="flex justify-center items-start flex-col gap-2 text-nowrap">
                      <div className="text-blue-gray-600">
                        Order ID : <span className="font-bold">{item.I19_order_id}</span>
                      </div>
                      <div>
                        Order Date :{" "}
                        <span className="font-bold text-green-500">
                          {formatDate(item.I19_sale_date)}
                        </span>
                      </div>
                      <div className="flex">
                        Order Status :{" "}
                        <span className="ml-2 font-bold">
                          <ColorChange Data={item.order_status.I030_order_name} />
                        </span>
                      </div>
                      <p className="text-lg text-black font-bold">
                        Total Price :{" "}
                        <span className="me-1">{currency.M05_CurrencySymbol}</span>
                        {item.I19_total_price}
                      </p>
                    </div>

                    <div className="flex gap-1 items-center w-fit">
                      {/* View More Details */}
                      <IconChevronDown className="w-8 h-8" />
                    </div>
                  </div>
                </Link>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
