import React, { useEffect, useState } from 'react'
import { useRESTapi } from '../../../../hooks/rest_API';
import { ProductCategory } from '../../../../types';
import { Link } from 'react-router-dom';


type CategoriesOverview = ProductCategory


export default function Topcatagories() {


    const api = useRESTapi()
    const [top_selling_categories, set_top_selling_categories] = useState<CategoriesOverview[]>([]);
    const [is_loading, set_is_loading] = useState(false)

    const loadTopProducts = async () => {
        set_is_loading(true)
        try {
            const res = await api.get<{ success: boolean, data: CategoriesOverview[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/topsellingproduct_categories`,
                mode: 'customer',
                params: {
                    count: 8
                }
            })

            if (res.data.success === true) {
                console.log(res.data.data)
                let newArray: CategoriesOverview[] = []
                const limit = 5;
                let i = 0;
                res.data.data.forEach(
                    item => {
                        if (item.M04_ProductCategoryId != '2' && item.M04_ProductCategoryId != '123' && item.M04_ParentCategoryId != '2' && item.M04_ParentCategoryId != '123') {
                            if (i < limit) {
                                newArray.push({ ...item })
                                i++;
                            }
                        }
                    }
                )
                set_top_selling_categories(newArray)
            }
        } catch (error) {
            alert('something went wrong')
        }
        set_is_loading(false)


    }


    useEffect(
        () => {
            loadTopProducts()
        }, []
    )

    return (
        <div className='relative flex flex-wrap justify-center gap-7 mt-8 cursor-pointer'>
            {
                is_loading
                    ?
                    Array(5).fill(5).map(e => (
                        <div className="h-24 w-28 bg-gray-700 text-center rounded-[7px] relative overflow-hidden flex items-center justify-center animate-pulse">
                            <div className="absolute inset-0 bg-gray-700"></div>
                            <div className="h-4 w-16 bg-gray-500 rounded"></div>
                        </div>
                    ))
                    :
                    top_selling_categories.map((brand, index) => (
                        <Link to={`${brand.M04_ProductCategoryName?.replace(/ /g, '-').toLowerCase()}/categery-page?categoryId=${brand.M04_ProductCategoryId}`} key={index} className="h-24 w-28 bg-[#263238] text-center rounded-[7px] relative overflow-hidden flex items-center justify-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${brand.M04_ProductCategoryImage})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                        >
                            <div className=' bg-black/30  absolute inset-0 '></div>
                            <h3 className='font-semibold text-[16px] text-white z-40'>{brand.M04_ProductCategoryName}</h3>

                        </Link>
                    ))
            }
        </div>
    )
}


