import { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Product, ProductItem, ProductItemKeys, ProductKeys, pagination } from '../../../../../types'
import { AddProductSKUModal } from './component/addmodal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@/src/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Search, Trash } from 'lucide-react'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { DeleteModal } from './component/deleteModal'
import { FilterComponent } from './component/filterComponent'
import { Input, InputIcon } from '@/src/components/ui/input'
import { useDebounce } from '@/src/hooks/debounce_hook'
import { SortComponent } from './component/sortComponent'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'
import { useCheckUserType } from '@/src/hooks/check_user'

interface productSKUList extends ProductItem {
  pricing: any,
  delete: any,
  edit: any,
  page_number: number,
  image: any
}

type Props = {
  setSelectedId: (id: string) => any
}

export type filterObjectType = {
  productSkuName?: string,
  productId?: string,
  categoryId?: string,
  brandId?: string,
  isActive: string | undefined
}


export enum filterObjectTypeKeys {
  productSkuName = 'productSkuName',
  productId = 'productId',
  categoryId = 'categoryId',
  brandId = 'brandId',
  pageNo = 'pageNo',
  isActive = 'isActive'
}

export type sortDirections = 'asc' | 'desc'
export type sortBy = 'name' | 'created_date' | 'updated_date'
export type sortObjectType = {
  sortBy: sortBy,
  sortDirection: sortDirections
}
export enum sortObjectTypeKeys {
  sortBy = 'sortBy',
  sortDirection = 'sortDirection'
}

export function AddProductSKU(props: Props) {

  const [products, set_products] = useState<ProductItem[]>([])
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSEOs } = useCheckUserType()

  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(searchParams.get(filterObjectTypeKeys.pageNo) ? Number(searchParams.get(filterObjectTypeKeys.pageNo)) : 1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const [loading_sku, set_loading_sku] = useState(false)
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)
  const employee = useSelector((state: RootState) => state.employee)

  const [showFilterPopover, setShowFilterPopover] = useState(false)
  const [showSortPopover, setShowSortPopover] = useState(false)

  const [filterObject, setfilterObject] = useState<filterObjectType>({
    brandId: '',
    categoryId: searchParams.get(filterObjectTypeKeys.categoryId) ? searchParams.get(filterObjectTypeKeys.categoryId)! : '',
    productId: searchParams.get(filterObjectTypeKeys.productId) ? searchParams.get(filterObjectTypeKeys.productId)! : '',
    productSkuName: searchParams.get(filterObjectTypeKeys.productSkuName) ? searchParams.get(filterObjectTypeKeys.productSkuName)! : '',
    isActive: searchParams.get(filterObjectTypeKeys.isActive) ? searchParams.get(filterObjectTypeKeys.isActive)! : '1'
  })
  const debouncedSearchTerm = useDebounce<string>(filterObject.productSkuName ? filterObject.productSkuName : '', 600);
  const [sortObject, setSortObject] = useState<sortObjectType>({
    sortBy: searchParams.get(sortObjectTypeKeys.sortBy) as sortBy ? searchParams.get(sortObjectTypeKeys.sortBy)! as sortBy : 'created_date',
    sortDirection: searchParams.get(sortObjectTypeKeys.sortDirection) as sortDirections ? searchParams.get(sortObjectTypeKeys.sortDirection)! as sortDirections : 'asc'
  })
  const alertModal = useAlertModal()
  const navigate = useNavigate();


  const header: {
    label: ProductItemKeys | string,
    name: string,
    minWidth?: number
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'image',
        name: "Image"
      },
      {
        label: ProductItemKeys.M08_ProductItemName,
        name: "name",
        minWidth: 200
      },
      {
        label: ProductItemKeys.M08_SKU,
        name: "SKU"
      },
      // {
      //   label: ProductItemKeys.M08_M11_ProductID,
      //   name: "product_id"
      // },
      // {
      //   label: ProductItemKeys.M08_M12_Supplier_id,
      //   name: "supplier"
      // },
      {
        label: 'pricing',
        name: ''
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function navigate_to_pricing(id: number | string) {

    navigate(`pricing/${id}`)

  }

  async function delete_product(id: number | string) {

    setIsDeleteLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: productSKUList[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${id}`,
        mode: 'admin',
      })
      updateUi();
      if (res.data.success) {
        alert(res.data.message)
        setshowDeleteModal(false)
      } else {
        alert('somthing went wrong...')
      }


    } catch (error) {
      console.log(error)
    }
    setIsDeleteLoading(false)


  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {
      props.setSelectedId('A4')

      const initData = async () => {
        set_page_selected(searchParams.get(filterObjectTypeKeys.pageNo) ? Number(searchParams.get(filterObjectTypeKeys.pageNo)) : 1)

        try {
          set_loading_sku(true)
          const res = await api.get<{ success: boolean, message: string, data: { data: productSKUList[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items`,
            params: {
              perPage: per_page,
              page: searchParams.get(filterObjectTypeKeys.pageNo)||1,
              productId: searchParams.get(filterObjectTypeKeys.productId),
              searchTerm: searchParams.get(filterObjectTypeKeys.productSkuName),
              categoryId: searchParams.get(filterObjectTypeKeys.categoryId),
              isActive: filterObject.isActive,
              sortBy:sortObject.sortBy,
              sortOrder:sortObject.sortDirection,
            },
            mode: 'admin',

          })

          if (res.data.success === true) {
            // console.log(res.data)
            set_products(res.data.data.data.map(
              (item, index) => {
                item.page_number = ((page_selected - 1) * per_page) + (index + 1)
                item.pricing = <Button disabled={isSEOs(employee?.employeeData?.M15_Employee_id!)} variant={'destructive'} onClick={() => navigate_to_pricing(String(item.M08_ProductItemID))}>View Pricing</Button>
                item.image = <img className=' w-11 aspect-[1.8]' src={`${process.env.REACT_APP_BACKEND_URL}/${item?.M08_ProductImage || ''}`} alt="" />
                item.delete = <ButtonIcon disabled={isSEOs(employee?.employeeData?.M15_Employee_id!)} icon={<Trash className="h-4 w-4" />} onClick={() => { set_selected_product(String(item.M08_ProductItemID!)); setshowDeleteModal(true) }}></ButtonIcon>
                item.edit = <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={() => clickEditButton(String(item.M08_ProductItemID))}></ButtonIcon>
                return item;
              }
            ))
            set_loading_sku(false)
            set_products_total(res.data.data.total)
          } else {
            alertModal.open_alert_modal({
              content: res.data.message,
              footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
              title: 'Error'
            })
          }

        } catch (error) {

        }

      }

      initData()

    }, [update, debouncedSearchTerm]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddProductSKUModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selectedEmployeeId!);
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' p-2 text-2xl font-bold'>Product SKU list</div>
      <div className=' w-full flex justify-between gap-1' >
        <InputIcon
          value={filterObject.productSkuName}
          onChange={(e) => {
            // searchParams.set(filterObjectTypeKeys.productSkuName, e.target.value)
            const newSearchParams = new URLSearchParams();
            newSearchParams.set(filterObjectTypeKeys.productSkuName, e.target.value);
            navigate(`?${newSearchParams.toString()}`, { replace: true });
            setfilterObject({
              ...filterObject,
              productSkuName: e.target.value
            })
          }} classOuter=' md:min-w-[400px]' icon={<Search />}
          placeholder='Search'
        />
        <div className=' flex gap-2'>
          <SortComponent
            filterObj={sortObject}
            setFilterObj={setSortObject}
            updateUi={updateUi}
            show={showSortPopover}
            setshow={setShowSortPopover}
          />
          <FilterComponent
            filterObj={filterObject}
            setFilterObj={setfilterObject}
            updateUi={updateUi}
            show={showFilterPopover}
            setshow={setShowFilterPopover}
          />
          {!isSEOs(employee?.employeeData?.M15_Employee_id!)&&<Button onClick={open_add_modal} className=' '>Add</Button>}
        </div>
      </div>
      <div className=' grow overflow-auto w-full border rounded-md'>
        {
          loading_sku ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead style={{ minWidth: header.minWidth }} key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => {
          set_page_selected(e);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(filterObjectTypeKeys.pageNo, e ? String(e) : '');
          navigate(`?${newSearchParams.toString()}`, { replace: true });
          updateUi()
        }} selectedPage={page_selected} />
      </div>
    </div>
  )
}

